import { Injectable } from '@angular/core';
import { AndCondition, Condition, ContainsCondition, DateAfterCondition, DateBeforeCondition, DateIsCondition, DateIsNotCondition, EndsWithCondition, EqualsCondition, InCondition, NotContainsCondition, NotEqualsCondition, NotInCondition, NotNullOrEmptyCondition, NullOrEmptyCondition, OrCondition, StartsWithCondition } from 'projects/api/src/api';

type ConditionWithSubConditions = Condition & {conditions: Condition[]}
type ConditionWithDateValue = Condition & {value: string}
type ConditionWithStringValue = Condition & {value: string}
type ConditionWithStringValues = Condition & {values: string[]}
type ConditionWithPath = Condition & {path: string}

@Injectable({
  providedIn: 'root'
})
export class ConditionService {

  match(condition: Condition, obj: {[key: string]: string}): boolean {
    if (this.isAndCondition(condition)) return this.testAndCondition(condition, obj)
    if (this.isContainsCondition(condition)) return this.testContainsCondition(condition, obj)
    if (this.isDateAfterCondition(condition)) return this.testDateAfterCondition(condition, obj)
    if (this.isDateBeforeCondition(condition)) return this.testDateBeforeCondition(condition, obj)
    if (this.isDateIsCondition(condition)) return this.testDateIsCondition(condition, obj)
    if (this.isDateIsNotCondition(condition)) return this.testDateIsNotCondition(condition, obj)
    if (this.isEndsWithCondition(condition)) return this.testEndsWithCondition(condition, obj)
    if (this.isEqualsCondition(condition)) return this.testEqualsCondition(condition, obj)
    if (this.isInCondition(condition)) return this.testInCondition(condition, obj)
    if (this.isNotContainsCondition(condition)) return this.testNotContainsCondition(condition, obj)
    if (this.isNotEqualsCondition(condition)) return this.testNotEqualsCondition(condition, obj)
    if (this.isNotInCondition(condition)) return this.testNotInCondition(condition, obj)
    if (this.isOrCondition(condition)) return this.testOrCondition(condition, obj)
    if (this.isStartsWithCondition(condition)) return this.testStartsWithCondition(condition, obj)
    if (this.isNullOrEmptyCondition(condition)) return this.testNullOrEmptyCondition(condition, obj)
    if (this.isNotNullOrEmptyCondition(condition)) return this.testNotNullOrEmptyCondition(condition, obj)
    
    return false
  }

  isConditionWithStringValue(condition: Condition): condition is ConditionWithStringValue {
    return condition.type === 'Contains' || condition.type === 'Equals' || condition.type === 'NotContains' || condition.type === 'NotEquals' || condition.type === 'StartsWith' || condition.type === 'EndsWith'
  }

  isConditionWithStringValues(condition: Condition): condition is ConditionWithStringValues {
    return condition.type === 'In' || condition.type === 'NotIn'
  }

  isConditionWithDateValue(condition: Condition): condition is ConditionWithDateValue {
    return condition.type === 'DateIs' || condition.type === 'DateIsNot' || condition.type === 'DateBefore' || condition.type === 'DateAfter';
  }

  isConditionWithPath(condition?: Condition): condition is ConditionWithPath { 
    return condition && !['And', 'Or'].includes(condition.type) || false 
  }

  isConditionWithSubConditions(condition?: Condition): condition is ConditionWithSubConditions { 
    return this.isAndCondition(condition) || this.isOrCondition(condition)
  }

  isAndCondition(condition?: Condition): condition is AndCondition { return condition?.type === 'And' }
  isContainsCondition(condition?: Condition): condition is ContainsCondition { return condition?.type === 'Contains' }
  isDateAfterCondition(condition?: Condition): condition is DateAfterCondition { return condition?.type === 'DateAfter' }
  isDateBeforeCondition(condition?: Condition): condition is DateBeforeCondition { return condition?.type === 'DateBefore' }
  isDateIsCondition(condition?: Condition): condition is DateIsCondition { return condition?.type === 'DateIs' }
  isDateIsNotCondition(condition?: Condition): condition is DateIsNotCondition { return condition?.type === 'DateIsNot' }
  isEndsWithCondition(condition?: Condition): condition is EndsWithCondition { return condition?.type === 'EndsWith' }
  isEqualsCondition(condition?: Condition): condition is EqualsCondition { return condition?.type === 'Equals' }
  isInCondition(condition?: Condition): condition is InCondition { return condition?.type === 'In' }
  isNotContainsCondition(condition?: Condition): condition is NotContainsCondition { return condition?.type === 'NotContains' }
  isNotEqualsCondition(condition?: Condition): condition is NotEqualsCondition { return condition?.type === 'NotEquals' }
  isNotInCondition(condition?: Condition): condition is NotInCondition { return condition?.type === 'NotIn' }
  isOrCondition(condition?: Condition): condition is OrCondition { return condition?.type === 'Or' }
  isStartsWithCondition(condition?: Condition): condition is StartsWithCondition { return condition?.type === 'StartsWith' }
  isNullOrEmptyCondition(condition?: Condition): condition is NullOrEmptyCondition { return condition?.type === 'NullOrEmpty' }
  isNotNullOrEmptyCondition(condition?: Condition): condition is NotNullOrEmptyCondition { return condition?.type === 'NotNullOrEmpty' }

  testAndCondition(condition: AndCondition, obj: {[key: string]: string}) { return condition.conditions.every(c => this.match(c, obj)) }
  testContainsCondition(condition: ContainsCondition, obj: {[key: string]: string}) { return (obj[condition.path] || '').includes(condition.value) }
  testDateAfterCondition(condition: DateAfterCondition, obj: {[key: string]: string}) { return condition.value.includes(obj[condition.path] || '') }
  testDateBeforeCondition(condition: DateBeforeCondition, obj: {[key: string]: string}) { return condition.value.includes(obj[condition.path] || '') }
  testDateIsCondition(condition: DateIsCondition, obj: {[key: string]: string}) { return condition.value.includes(obj[condition.path] || '') }
  testDateIsNotCondition(condition: DateIsNotCondition, obj: {[key: string]: string}) { return condition.value.includes(obj[condition.path] || '') }
  testEndsWithCondition(condition: EndsWithCondition, obj: {[key: string]: string}) { return (obj[condition.path] || '').endsWith(condition.value) }
  testEqualsCondition(condition: EqualsCondition, obj: {[key: string]: string}) { return (obj[condition.path] || '') === condition.value }
  testInCondition(condition: InCondition, obj: {[key: string]: string}) { return condition.values.includes(obj[condition.path] || '') }
  testNotContainsCondition(condition: NotContainsCondition, obj: {[key: string]: string}) { return !(obj[condition.path] || '').includes(condition.value) }
  testNotEqualsCondition(condition: NotEqualsCondition, obj: {[key: string]: string}) { return (obj[condition.path] || '') !== condition.value }
  testNotInCondition(condition: NotInCondition, obj: {[key: string]: string}) { return !condition.values.includes(obj[condition.path] || '') }
  testOrCondition(condition: OrCondition, obj: {[key: string]: string}) { return condition.conditions.some(c => this.match(c, obj)) }
  testStartsWithCondition(condition: StartsWithCondition, obj: {[key: string]: string}) { return (obj[condition.path] || '').startsWith(condition.value) }
  testNullOrEmptyCondition(condition: NullOrEmptyCondition, obj: {[key: string]: string}) { return (obj[condition.path] || '') === '' }
  testNotNullOrEmptyCondition(condition: NotNullOrEmptyCondition, obj: {[key: string]: string}) { return (obj[condition.path] || '') !== '' }
  

}


