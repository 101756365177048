<div class="background"></div>
<div class="container">
    

  <div class="info">
    <h1 class="user">Herzlich Willkommen<span *ngIf="authUser | async as user">, {{ user.displayName }}</span></h1>
    <div class="text">
      ImageJet is an <span class="yellow">ecosystem</span> for <span class="yellow">workflow-based</span><br />
      3D-Content <span class="yellow">production</span>, <span class="yellow">colaboration</span>, and <span class="yellow">provisioning</span>.
    </div>
  </div>

  <div class="assets">
    <!-- <c-asset-tilesview></c-asset-tilesview> -->
  </div>

</div>