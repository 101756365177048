import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { PurchaseComponent } from './pages/purchase/purchase.component';

const routes: Routes = [

  { path: '',                      loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },

  { path: 'registertwofactor',     loadChildren: () => import('./pages/twofactor/register/register.module').then(m => m.TwoFactorRegisterModule) },
  { path: 'verifytwofactor',       loadChildren: () => import('./pages/twofactor/verify/verify.module').then(m => m.TwoFactorVerifyModule) },
  { path: 'agencies',              loadChildren: () => import('./pages/agencies/agencies.module').then(m => m.AgenciesModule) },
  { path: 'docs',                  loadChildren: () => import('./pages/docs/docs.module').then(m => m.DocsModule) },

  { path: 'manage/agencies',       canActivate: [AuthGuard], loadChildren: () => import('./pages/manage-agencies/manage-agencies.module').then(m => m.ManageAgenciesModule) },
  { path: 'dashboard',             canActivate: [AuthGuard], loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'assets',                canActivate: [AuthGuard], loadChildren: () => import('./pages/assets/assets.module').then(m => m.AssetsModule) },
  { path: 'orders',                canActivate: [AuthGuard], loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersModule) },
  { path: 'profile',               canActivate: [AuthGuard], loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule) },
  { path: 'tasks',                 canActivate: [AuthGuard], loadChildren: () => import('./pages/tasks/tasks.module').then(m => m.TasksModule) },
  { path: 'jobs',                  canActivate: [AuthGuard], loadChildren: () => import('./pages/jobs/jobs.module').then(m => m.JobsModule) },  
  { path: 'editor',                canActivate: [AuthGuard], loadChildren: () => import('./pages/editor/editor.module').then(m => m.EditorModule) },

  { path: ':baseName',             component: PurchaseComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  providers: [
    AuthGuard
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
