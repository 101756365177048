import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { AndCondition, Condition, EqualsCondition, OrCondition } from 'projects/api/src/api';
import { UtilsService } from '../../services/utils.service';
import { ConditionType, defaultConditionTypes } from '../table/table.interfaces';
import { ConditionService } from '../../services/condition.service';
import { MenuItem } from 'primeng/api';

export type PathSettings = {
  label?: string,
  suggestions?: { label: string, value: string }[],
  conditionTypes?: ConditionType[],
}

@Component({
  selector: 'c-condition',
  templateUrl: './condition.component.html',
  styleUrls: ['./condition.component.scss']
})
export class ConditionComponent implements OnInit {

  @Input() condition?: Condition;
  @Input() level = 0;
  @Input() disabled = false;
  @Input() viewMode: 'Text' | 'Editor' = 'Text'
  @Input() pathSettings?: {[path: string]: PathSettings} | null
  @Input() showNoConditionLabel: boolean = true

  @Output() conditionChange: EventEmitter<Condition> = new EventEmitter<Condition>()

  @HostBinding('class.text')
  get text(): boolean {
    return this.viewMode === 'Text'
  }

  parenthesisColor: string = 'rgb(0, 0, 0)';
  keywordColor: string = 'rgb(153, 153, 153)';
  opColor: string = 'rgb(51, 158, 216)';
  valueColor: string = 'rgb(214, 122, 36)';

  settings?: PathSettings | null;
  label: string = ''
  conditionTypes: ConditionType[] = defaultConditionTypes['string']

  addMenuItems: MenuItem[] = []

  get pathWidth(): string {
    return `${400 - this.level * 33}px`
  }

  get paths(): string [] {
    return Object.keys(this.pathSettings || {})
  }

  constructor(
    private utilsService: UtilsService,
    private conditionService: ConditionService,
  ) { }

  ngOnInit(): void {
    if (this.conditionService.isConditionWithPath(this.condition)) {
      this.parenthesisColor = this.utilsService.pastelColor((this.level - 1).toString(), 'string');
      this.settings = this.pathSettings && this.pathSettings[this.condition.path]

      this.label = this.settings?.label || this.utilsService.capitalizeFirstLetter(this.condition.path.split('.').pop() || '')
      this.conditionTypes = this.settings?.conditionTypes || this.conditionTypes
    } else {
      this.parenthesisColor = this.utilsService.pastelColor(this.level.toString(), 'string');
    }

    this.addMenuItems = [
      {label: 'Field Condition', command: () => this.addCondition('Field')},
      {label: 'And Condition', command: () => this.addCondition('And')},
      {label: 'Or Condition', command: () => this.addCondition('Or')},
    ]
  }

  conditionTypeChange(newConditionType: ConditionType) {
    if (this.conditionService.isConditionWithPath(this.condition)) {
      const newCondition = {
        type: newConditionType,
      } as Condition

      const condition: any = this.condition
  
      if (this.conditionService.isConditionWithStringValue(newCondition) && this.conditionService.isConditionWithStringValue(this.condition)) {
        condition.value = condition.value || ''
      } else if (this.conditionService.isConditionWithStringValue(newCondition) && this.conditionService.isConditionWithStringValues(this.condition)) {
        condition.value = condition.values[0] || ''
        delete condition.values
      } else if (this.conditionService.isConditionWithStringValues(newCondition) && this.conditionService.isConditionWithStringValue(this.condition)) {
        condition.values = condition.value ? [condition.value] : []
        delete condition.value
      } else if (this.conditionService.isConditionWithStringValues(newCondition) && this.conditionService.isConditionWithStringValues(this.condition)) {
        condition.values = condition.values || []
      }      
  
      condition.type = newConditionType
      
    } else if (this.conditionService.isConditionWithSubConditions(this.condition) && ['Or', 'And'].includes(newConditionType)) {
      this.condition.type = newConditionType
    }

    this.conditionChange.emit(this.condition)
  }

  pathChange(path: string) {
    if (this.conditionService.isConditionWithPath(this.condition)) {
      this.condition.path = path

      if (this.pathSettings && this.pathSettings[path]) {
        this.settings = this.pathSettings[path]

        if (this.settings?.conditionTypes && !this.settings.conditionTypes.includes(this.condition.type)) {
          this.conditionTypeChange(this.settings.conditionTypes[0])
        }
      }

      this.conditionChange.emit(this.condition)
    }
  }

  valueChange(value: string) {
    if (this.condition && this.conditionService.isConditionWithStringValue(this.condition)) {
      this.condition.value = value
      this.conditionChange.emit(this.condition)
    }
  }
  
  valuesChange(values: string[]) {
    if (this.condition && this.conditionService.isConditionWithStringValues(this.condition)) {
      this.condition.values = values
      this.conditionChange.emit(this.condition)
    }
  }

  addCondition(type: 'And' | 'Or' | 'Field') {
    let newCondition: Condition | null = null

    if (type === 'And') {
      newCondition = { type: 'And', conditions: [] } as AndCondition
    } else if (type === 'Or') {
      newCondition = { type: 'Or', conditions: [] } as OrCondition
    } else {      
      const path = Object.keys(this.pathSettings || {})[0]

      if (this.pathSettings && path) {
        newCondition = {
          type: (this.pathSettings[path].conditionTypes || defaultConditionTypes['string'])[0],
          path: path,
        } as Condition

      } else {
        newCondition = {
          type: 'Equals',
          path: '',
          value: '',
        } as Condition
      }
    }

    if (newCondition) {
      if (!this.condition) {
        this.condition = newCondition
        this.conditionChange.emit(this.condition)
      } else if (this.conditionService.isConditionWithSubConditions(this.condition)) {
        this.condition.conditions.push(newCondition)
        this.conditionChange.emit(this.condition)
      }
    }
  }

  deleteSubcondition(index: number) {
    if (this.conditionService.isConditionWithSubConditions(this.condition)) {
      this.condition.conditions.splice(index, 1)

      this.conditionChange.emit(this.condition)
    }
  }

  subconditionChange() {
    
  }
}
