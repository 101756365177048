
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'projects/web/src/app/auth/auth.service';

export const BASE_PATH = new InjectionToken<string>('basePath');

export interface Agency {
  _id: (string);
  company?: (string);
  name: (string);
  displayName: (string);
  nextJobNumber: (string);
  createdAt: (string);
  createdBy: (string);
  deletedAt?: (string);
  deletedBy?: (string);
}

export interface FileReference {
  type: (('AssemblingInput' | 'StepOutput'));
}

export interface FileReferenceAssemblingInput extends FileReference {
  type: (('AssemblingInput'));
  assemblingInput: (string);
}

export interface FileReferenceStepOutput extends FileReference {
  type: (('StepOutput'));
  step: (string);
  output: (string);
  bundle?: (string);
}

export interface AssemblingOutput {
  id: (string);
  name: (string);
  file: (FileReference);
  fileType: (('Exr' | 'Jpeg' | 'Json' | 'Png' | 'Usd' | 'Webp' | 'SafeTensors' | 'Txt'));
  thumbnailFile?: (FileReference);
  thumbnailFileType?: (('Exr' | 'Jpeg' | 'Png' | 'Webp'));
}

export interface AssemblingUsedAsset {
  asset: (string);
  assetName: (string);
  assetVersion?: (string);
  hash?: (string);
}

export interface AssemblingError {
  type: (('AssetNotFound' | 'AssetVersionNotFound' | 'CameraNotFound' | 'InvalidAsset' | 'InvalidAssetType' | 'InvalidAssetVersionNumber' | 'InvalidCondition' | 'InvalidDefinitionType' | 'InvalidMainFile' | 'InvalidVariantSet' | 'InvalidVariantValue' | 'MissingCamera' | 'MissingScene' | 'PathNotFound' | 'VariantSetNotFound' | 'VariantValueNotFound' | 'InvalidEnvironmentVariableName' | 'InvalidEnvironmentVariableValue' | 'DuplicatedStepId' | 'InvalidFinalStep' | 'InvalidNumberOfInputs' | 'StepOutputNotFound' | 'AssemblingInputNotFound' | 'InvalidInputString' | 'BlockedVariantSelection' | 'InvalidInputCondition' | 'InvalidGraphVersion' | 'GraphPathNotFound' | 'OrderNotFound' | 'VariableNotFound' | 'ValueRequired'));
  severity: (('warning' | 'error'));
  resolvedAt?: (string);
  resolvedBy?: (string);
  hash?: (string);
  count?: (number);
}

export interface SubExecution {
  originalId: (string);
  parents: (string)[];
}

export interface StepInput {
  name: (string);
  type: (('String' | 'Number' | 'Asset' | 'Map' | 'Switch' | 'File' | 'FileBundle' | 'FileType' | 'Order' | 'GraphPath' | 'Group' | 'GroupEntry'));
  assemblingErrors: (string)[];
  assemblingUsedAssets: (string)[];
  assemblingUsedOrders: (string)[];
}

export interface StepInputFile extends StepInput {
  type: (('File'));
  fileType: (('Exr' | 'Jpeg' | 'Json' | 'Png' | 'Usd' | 'Webp' | 'SafeTensors' | 'Txt'));
  files: (FileReferenceStepOutput)[];
}

export interface StepInputGroupEntry {
  inputs: (StepInput)[];
  assemblingErrors: (string)[];
  assemblingUsedAssets: (string)[];
  assemblingUsedOrders: (string)[];
}

export interface StepInputGroup extends StepInput {
  type: (('Group'));
  groups: (StepInputGroupEntry)[];
}

export interface StepInputString extends StepInput {
  type: (('String'));
  values: (string)[];
  options?: ({[key: string]: string});
}

export interface StepOutput {
  type: (('File' | 'FileBundle'));
  name: (string);
  title: (string);
  assemblingErrors: (string)[];
}

export interface StepOutputFile extends StepOutput {
  type: (('File'));
  fileType: (('Exr' | 'Jpeg' | 'Json' | 'Png' | 'Usd' | 'Webp' | 'SafeTensors' | 'Txt'));
}

export interface StepOutputFileBundle extends StepOutput {
  type: (('FileBundle'));
  files: (StepOutputFile)[];
}

export interface Step {
  id: (string);
  type: (('Basic_Or_v1' | 'Basic_Graph_v1' | 'Basic_GraphFiles_v1' | 'Prefab_Houdini_19_5_534_Hython_v1' | 'Prefab_Houdini_20_0_590_Hython_v1' | 'Assembling_Default_v1' | 'Rendering_Houdini_19_5_534_Arnold_v1' | 'Rendering_Houdini_20_0_590_Arnold_v1' | 'Compositing_Houdini_19_5_534_Hython_v1' | 'Compositing_Houdini_20_0_590_Hython_v1' | 'Compositing_Houdini_20_0_590_Hoiiotool_v1' | 'Utils_DownloadFile_v1' | 'Utils_ConvertImage_v1' | 'Utils_MontageImage_v1' | 'Utils_UsdAnalyzer_v1' | 'Order_Outputs_v1' | 'GenAI_LoRATraining_v1' | 'GenAI_Comfy_v1'));
  title: (string);
  subtitle: (string);
  hash?: (string);
  subExecution?: (SubExecution);
  assemblingErrors: (string)[];
  assemblingUsedAssets: (string)[];
  inputs: (StepInput)[];
  outputs: (StepOutput)[];
}

export interface StepBasic extends Step {
  type: (('Basic_Or_v1' | 'Basic_Graph_v1' | 'Basic_GraphFiles_v1'));
}

export interface StepBasic_Graph_v1 extends StepBasic {
  type: (('Basic_Graph_v1'));
  graph: (string);
  graphVersion: (string);
}

export interface Assembling {
  hash: (string);
  steps: (Step)[];
  outputs: (AssemblingOutput)[];
  graph: (string);
  graphVersion: (string);
  usedAssets: (AssemblingUsedAsset)[];
  errors: (AssemblingError)[];
}

export interface AssemblingTemplateInput {
  type: (('String' | 'Number' | 'ObjectAsset' | 'MaterialAsset' | 'SceneAsset' | 'File'));
  id: (string);
  name: (string);
  description: (string);
}

export interface AssemblingTemplateInputString extends AssemblingTemplateInput {
  type: (('String'));
  default?: (string);
}

export interface AssemblingTemplateInputNumber extends AssemblingTemplateInput {
  type: (('Number'));
  default?: (number);
}

export interface AssemblingTemplateInputObjectAsset extends AssemblingTemplateInput {
  type: (('ObjectAsset'));
  default?: (string);
}

export interface AssemblingTemplateInputMaterialAsset extends AssemblingTemplateInput {
  type: (('MaterialAsset'));
  default?: (string);
}

export interface AssemblingTemplateInputSceneAsset extends AssemblingTemplateInput {
  type: (('SceneAsset'));
  default?: (string);
}

export interface AssemblingTemplateInputFile extends AssemblingTemplateInput {
  type: (('File'));
  fileType: (('Exr' | 'Jpeg' | 'Json' | 'Png' | 'Usd' | 'Webp' | 'SafeTensors' | 'Txt'));
  x: (number);
  y: (number);
}

export interface Condition {
  type: (('And' | 'Or' | 'In' | 'NotIn' | 'StartsWith' | 'EndsWith' | 'Equals' | 'NotEquals' | 'Contains' | 'NotContains' | 'DateIs' | 'DateIsNot' | 'DateBefore' | 'DateAfter' | 'NullOrEmpty' | 'NotNullOrEmpty'));
}

export interface AssemblingTemplateOutput {
  type: (('File'));
  id: (string);
  name: (string);
  description: (string);
  condition?: (Condition);
}

export interface AssemblingTemplateOutputFile extends AssemblingTemplateOutput {
  type: (('File'));
  fileType: (('Exr' | 'Jpeg' | 'Json' | 'Png' | 'Usd' | 'Webp' | 'SafeTensors' | 'Txt'));
  file: (FileReference | null);
  fileThumbnail: (FileReference | null);
  x: (number);
  y: (number);
}

export interface InvalidFinalStepAssemblingError extends AssemblingError {
  type: (('InvalidFinalStep'));
  step?: (string);
  output?: (string);
  bundle?: (string);
}

export interface StepInputTemplate {
  name: (string);
  type: (('String' | 'Number' | 'Asset' | 'Map' | 'Switch' | 'File' | 'FileBundle' | 'FileType' | 'Order' | 'GraphPath' | 'Group' | 'GroupEntry'));
  title: (string);
  category?: (string);
  minValues: (number);
  maxValues: (number);
  condition?: (Condition);
}

export interface StepOutputTemplate {
  type: (('File' | 'FileBundle'));
  name: (string);
  title: (string);
  list: (boolean);
  condition?: (Condition);
}

export interface AssemblyReference {
  type: (('Asset' | 'File'));
  path: (string)[];
  fullName: (string);
}

export interface AssemblyReferenceAsset extends AssemblyReference {
  type: (('Asset'));
  mainFile: (string);
  asset: (string);
  assetVersion: (string);
}

export interface AssemblyReferenceFile extends AssemblyReference {
  type: (('File'));
  file: (string);
}

export interface AssemblyVariant {
  asset: (string);
  assetVersion: (string);
  variantSet: (string);
  variantValue: (string);
  fullName: (string);
}

export interface AssemblyDefinition {
  name: (string);
  type: (('over' | 'xform' | 'def'));
  reference?: (AssemblyReference);
  variants?: (AssemblyVariant)[];
  definitions?: (AssemblyDefinition)[];
}

export interface AssemblySublayer {
  type: (('Asset' | 'File'));
  fullName: (string);
}

export interface AssemblySublayerAsset extends AssemblySublayer {
  type: (('Asset'));
  mainFile: (string);
  asset: (string);
  assetVersion: (string);
}

export interface AssemblySublayerFile extends AssemblySublayer {
  type: (('File'));
  file: (string);
}

export interface Assembly {
  sublayers: (AssemblySublayer)[];
  definitions: (AssemblyDefinition)[];
}

export interface StepAssembling extends Step {
  type: (('Assembling_Default_v1'));
}

export interface StepAssembling_Default_v1 extends StepAssembling {
  type: (('Assembling_Default_v1'));
  assembly: (Assembly);
}

export interface StepBasic_GraphFiles_v1 extends StepBasic {
  type: (('Basic_GraphFiles_v1'));
}

export interface StepBasic_Or_v1 extends StepBasic {
  type: (('Basic_Or_v1'));
}

export interface StepCompositing extends Step {
  type: (('Compositing_Houdini_19_5_534_Hython_v1' | 'Compositing_Houdini_20_0_590_Hython_v1' | 'Compositing_Houdini_20_0_590_Hoiiotool_v1'));
  group: (('Compositing'));
}

export interface StepCompositing_Houdini_19_5_534_Hython_v1 extends StepCompositing {
  type: (('Compositing_Houdini_19_5_534_Hython_v1'));
}

export interface StepCompositing_Houdini_20_0_590_Hoiiotool_v1 extends StepCompositing {
  type: (('Compositing_Houdini_20_0_590_Hoiiotool_v1'));
}

export interface StepCompositing_Houdini_20_0_590_Hython_v1 extends StepCompositing {
  type: (('Compositing_Houdini_20_0_590_Hython_v1'));
}

export interface StepGenAI extends Step {
  type: (('GenAI_LoRATraining_v1' | 'GenAI_Comfy_v1'));
}

export interface StepGenAI_Comfy_v1 extends StepGenAI {
  type: (('GenAI_Comfy_v1'));
  agency: (string);
  asset: (string);
  assetVersion: (string);
  workflow: (number);
}

export interface StepGenAI_LoRATraining_v1 extends StepGenAI {
  type: (('GenAI_LoRATraining_v1'));
}

export interface StepOrder extends Step {
  type: (('Order_Outputs_v1'));
}

export interface StepOrder_Outputs_v1 extends StepOrder {
  type: (('Order_Outputs_v1'));
  order: (string);
}

export interface StepPrefab extends Step {
  type: (('Prefab_Houdini_19_5_534_Hython_v1' | 'Prefab_Houdini_20_0_590_Hython_v1'));
  group: (('Prefab'));
  agency: (string);
  asset: (string);
  assetVersion: (string);
  action: (number);
}

export interface StepPrefab_Houdini_19_5_534_Hython_v1 extends StepPrefab {
  type: (('Prefab_Houdini_19_5_534_Hython_v1'));
}

export interface StepPrefab_Houdini_20_0_590_Hython_v1 extends StepPrefab {
  type: (('Prefab_Houdini_20_0_590_Hython_v1'));
}

export interface StepRendering extends Step {
  type: (('Rendering_Houdini_19_5_534_Arnold_v1' | 'Rendering_Houdini_20_0_590_Arnold_v1'));
  group: (('Rendering'));
}

export interface StepRendering_Houdini_19_5_534_Arnold_v1 extends StepRendering {
  type: (('Rendering_Houdini_19_5_534_Arnold_v1'));
}

export interface StepRendering_Houdini_20_0_590_Arnold_v1 extends StepRendering {
  type: (('Rendering_Houdini_20_0_590_Arnold_v1'));
}

export interface StepUtils extends Step {
  type: (('Utils_DownloadFile_v1' | 'Utils_ConvertImage_v1' | 'Utils_MontageImage_v1' | 'Utils_UsdAnalyzer_v1'));
}

export interface StepUtils_ConvertImage_v1 extends StepUtils {
  type: (('Utils_ConvertImage_v1'));
}

export interface StepUtils_DownloadFile_v1 extends StepUtils {
  type: (('Utils_DownloadFile_v1'));
}

export interface StepUtils_MontageImage_v1 extends StepUtils {
  type: (('Utils_MontageImage_v1'));
}

export interface StepUtils_UsdAnalyzer_v1 extends StepUtils {
  type: (('Utils_UsdAnalyzer_v1'));
}

export interface DuplicatedStepIdAssemblingError extends AssemblingError {
  type: (('DuplicatedStepId'));
  id: (string);
}

export interface InvalidInputConditionAssemblingError extends AssemblingError {
  type: (('InvalidInputCondition'));
  inputName: (string);
}

export interface InvalidNumberOfInputsAssemblingError extends AssemblingError {
  type: (('InvalidNumberOfInputs'));
  minValues: (number);
  maxValues: (number);
  values: (number);
}

export interface StepOutputNotFoundAssemblingError extends AssemblingError {
  type: (('StepOutputNotFound'));
  step: (string);
  output: (string);
  bundle?: (string | null);
}

export interface StepInputTemplateFile extends StepInputTemplate {
  type: (('File'));
  fileType: (('Exr' | 'Jpeg' | 'Json' | 'Png' | 'Usd' | 'Webp' | 'SafeTensors' | 'Txt'));
  allowedFileTypes: (('Exr' | 'Jpeg' | 'Json' | 'Png' | 'Usd' | 'Webp' | 'SafeTensors' | 'Txt'))[];
  files: (FileReference)[];
}

export interface InvalidInputStringAssemblingError extends AssemblingError {
  type: (('InvalidInputString'));
  value: (string);
  index?: (number);
}

export interface ValueRequiredAssemblingError extends AssemblingError {
  type: (('ValueRequired'));
  index?: (number);
}

export interface VariableNotFoundAssemblingError extends AssemblingError {
  type: (('VariableNotFound'));
  variable: (string);
  index?: (number);
}

export interface StepInputTemplateString extends StepInputTemplate {
  type: (('String'));
  values: (string)[];
  regex?: (string);
  options: ({[key: string]: string});
}

export interface StepInputTemplateSwitch extends StepInputTemplate {
  type: (('Switch'));
  selected: (number);
  inputs: (StepInputTemplate)[];
}

export interface AssemblingUsedOrder {
  order: (string);
  queryHash: (string);
  hash?: (string);
}

export interface StepInputTemplateGroupEntry {
  inputs: (StepInputTemplate)[];
}

export interface StepInputTemplateGroup extends StepInputTemplate {
  type: (('Group'));
  inputTemplates?: (StepInputTemplate)[];
  groups: (StepInputTemplateGroupEntry)[];
  displayInput?: (string);
}

export interface StepInputFileBundleEntry {
  files: (StepInputFile)[];
}

export interface StepInputFileBundle extends StepInput {
  type: (('FileBundle'));
  bundles: (StepInputFileBundleEntry)[];
}

export interface StepInputTemplateFileBundleEntry {
  files: (StepInputTemplateFile)[];
}

export interface StepInputTemplateFileBundle extends StepInputTemplate {
  type: (('FileBundle'));
  inputTemplates: (StepInputTemplateFile)[];
  bundles: (StepInputTemplateFileBundleEntry)[];
}

export interface StepTemplate {
  id: (string);
  type: (('Basic_Or_v1' | 'Basic_Graph_v1' | 'Basic_GraphFiles_v1' | 'Prefab_Houdini_19_5_534_Hython_v1' | 'Prefab_Houdini_20_0_590_Hython_v1' | 'Assembling_Default_v1' | 'Rendering_Houdini_19_5_534_Arnold_v1' | 'Rendering_Houdini_20_0_590_Arnold_v1' | 'Compositing_Houdini_19_5_534_Hython_v1' | 'Compositing_Houdini_20_0_590_Hython_v1' | 'Compositing_Houdini_20_0_590_Hoiiotool_v1' | 'Utils_DownloadFile_v1' | 'Utils_ConvertImage_v1' | 'Utils_MontageImage_v1' | 'Utils_UsdAnalyzer_v1' | 'Order_Outputs_v1' | 'GenAI_LoRATraining_v1' | 'GenAI_Comfy_v1'));
  subExecution?: (SubExecution);
  group: (('Basic' | 'Prefab' | 'Assembling' | 'Rendering' | 'Compositing' | 'Utils' | 'Order' | 'GenAI'));
  title: (string);
  subtitle: (string);
  x: (number);
  y: (number);
  width: (number);
  height: (number);
  inputs: (StepInputTemplate)[];
  outputs: (StepOutputTemplate)[];
}

export interface StepTemplateBasic extends StepTemplate {
  type: (('Basic_Or_v1' | 'Basic_Graph_v1' | 'Basic_GraphFiles_v1'));
  group: (('Basic'));
}

export interface StepTemplateBasic_Graph_v1 extends StepTemplateBasic {
  type: (('Basic_Graph_v1'));
  title: (string);
  subtitle: (string);
  version: (string);
  inputs: (StepInputTemplate)[];
  outputs: (StepOutputTemplate)[];
  graph?: (string);
  graphVersion?: (string);
}

export interface AssemblingInputNotFoundAssemblingError extends AssemblingError {
  type: (('AssemblingInputNotFound'));
  input: (string);
}

export interface AssetNotFoundAssemblingError extends AssemblingError {
  type: (('AssetNotFound'));
  baseName: (string);
  path?: (string)[];
}

export interface AssetVersionNotFoundAssemblingError extends AssemblingError {
  type: (('AssetVersionNotFound'));
  baseName: (string);
  asset: (string);
  assetVersionName?: (string);
  path?: (string)[];
}

export interface File {
  _id: (string);
  type: (('AssetVersionFile' | 'OrderUploadFile' | 'JobOutputFile' | 'PreviewFile' | 'TaskAttachmentFile' | 'GraphFile'));
  path: (string);
  folder: (string);
  mimeType: (string);
  md5: (string);
  length: (number);
  fileName?: (string);
  extension?: (string);
  state: (('Uploading' | 'Error' | 'Finished'));
  finishedAt: (string);
  errorAt: (string);
  agency: (string);
}

export interface AssetVersionFile extends File {
  type: (('AssetVersionFile'));
  asset: (string);
  assetVersion: (string);
  visibility: (('DevOnly' | 'PublishedOnly' | 'DevAndPublished'));
  usd: (boolean);
  linkedTo?: (string);
  replacedPaths?: (string)[];
}

export interface AbstractUser {
  type: (('User' | 'ServiceUser'));
  _id: (string);
  displayName: (string);
  deletedAt?: (string);
  deletedBy?: (string);
}

export interface Role {
  type: (('SystemRole' | 'CustomRole'));
  _id: (string);
  name: (string);
  description: (string);
  rights: (string)[];
}

export interface SystemRole extends Role {
  type: (('SystemRole'));
  key: (('Owner' | 'AssetCreator'));
}

export interface CustomRole extends Role {
  type: (('CustomRole'));
  agency: (string);
  createdAt: (string);
  createdBy: (string);
  updatedAt?: (string);
  updatedBy?: (string);
  deletedAt?: (string);
  deletedBy?: (string);
}

export interface User extends AbstractUser {
  type: (('User'));
}

export interface UserWithDetails extends User {
  email: (string);
  roles: (Role)[];
}

export interface AssetVersionStateHistory {
  state: (('Empty' | 'Uploading' | 'Analyzing' | 'Error' | 'Finished' | 'Submitted' | 'Rejected' | 'Publishing' | 'Published' | 'Obsolete'));
  date: (string);
  user: (string);
  user$?: (User);
}

export interface Tag {
  value: (string);
  createdAt: (string);
  createdBy: (string);
  deletedAt?: (string);
  deletedBy?: (string);
}

export interface AssetVersionsStatistic {
  count: (number);
  calculatedAt: (string);
  empty: (number);
  uploading: (number);
  analyzing: (number);
  error: (number);
  finished: (number);
  submitted: (number);
  rejected: (number);
  publishing: (number);
  published: (number);
  obsolete: (number);
}

export interface AssetLatestDevAssetVersion {
  _id: (string);
  version: (number);
}

export interface AssetLatestAssetVersion {
  _id: (string);
  version: (number);
  publishedAt: (string);
  preview?: (string);
}

export interface AssetPreviewSetPreviews {
  _id: (string);
  title: (string);
  thumbnailUrl: (string);
  url: (string);
}

export interface AssetPreviewSet {
  _id: (string);
  url: (string);
  thumbnailUrl: (string);
  animationUrl: (string);
  previews: (AssetPreviewSetPreviews)[];
}

export interface AssetPublishedAssetVersions {
  _id: (string);
  version: (number);
  publishedAt: (string);
  preview?: (string);
}

export interface Asset {
  _id: (string);
  name: (string);
  displayName: (string);
  assetType: (('material' | 'object' | 'scene' | 'prefab' | 'comfy'));
  previewSet?: (AssetPreviewSet);
  totalVersions: (number);
  totalPublishedVersions: (number);
  latestVersion?: (number);
  assetVersionsStatistic?: (AssetVersionsStatistic);
  latestAssetVersion?: (string);
  latestAssetVersion$?: (AssetLatestAssetVersion);
  latestDevAssetVersion?: (string);
  latestDevAssetVersion$?: (AssetLatestDevAssetVersion);
  publishedAssetVersions: (AssetPublishedAssetVersions)[];
  agency: (string);
  agency$?: (Agency);
  createdAt: (string);
  createdBy: (string);
  createdBy$?: (User);
  updatedAt?: (string);
  updatedBy?: (string);
  deletedAt?: (string);
  tags: (Tag)[];
}

export interface AssetVersionAgency$ {
  _id: (string);
  name: (string);
  displayName: (string);
}

export interface AssetVersionAsset$ {
  _id: (string);
  name: (string);
  displayName: (string);
  assetType: (('material' | 'object' | 'scene' | 'prefab' | 'comfy'));
}

export interface AssetVersionPreviewSetPreviews {
  _id: (string);
  title: (string);
  thumbnailUrl: (string);
  url: (string);
}

export interface AssetVersionPreviewSet {
  _id: (string);
  url: (string);
  thumbnailUrl: (string);
  animationUrl: (string);
  previews: (AssetVersionPreviewSetPreviews)[];
}

export interface AssetVersion {
  type: (('MaterialAssetVersion' | 'ObjectAssetVersion' | 'SceneAssetVersion' | 'PrefabAssetVersion' | 'ComfyAssetVersion'));
  _id: (string);
  version: (number);
  latestDev: (boolean);
  latest: (boolean);
  agency: (string);
  agency$?: (AssetVersionAgency$);
  asset: (string);
  asset$?: (AssetVersionAsset$);
  devMd5: (string);
  publishedMd5: (string);
  state: (('Empty' | 'Uploading' | 'Analyzing' | 'Error' | 'Finished' | 'Submitted' | 'Rejected' | 'Publishing' | 'Published' | 'Obsolete'));
  dependencies: (string)[];
  devDependencies: (string)[];
  mainFile?: (string);
  mainPrimPath?: (string);
  mainFile$?: (AssetVersionFile);
  additionalFiles: (string)[];
  additionalFiles$?: (AssetVersionFile)[];
  devFiles: (string)[];
  devFiles$?: (AssetVersionFile)[];
  devFilesPattern: (string)[];
  error?: (string);
  previewSet?: (AssetVersionPreviewSet);
  stateHistory: (AssetVersionStateHistory)[];
  createdAt: (string);
  createdBy: (string);
  createdBy$?: (User);
  uploadedAt?: (string);
  uploadedBy?: (string);
  uploadedBy$?: (User);
  publishedAt?: (string);
  publishedBy?: (string);
  publishedBy$?: (User);
  deletedAt?: (string);
  deletedBy?: (string);
  deletedBy$?: (User);
  finishedAt?: (string);
  errorAt?: (string);
}

export interface AssetVersionDependency {
  _id: (string);
  assetVersion: (string);
  dependentOn: (string);
  md5: (string);
  dev: (boolean);
}

export interface VariantSelection {
  variantSet: (string);
  variantValue: (string);
  path: (string)[];
}

export interface BlockedVariantSelection {
  _id: (string);
  assetVersion: (string);
  variantSelections: (VariantSelection)[];
  reason?: (string);
  createdAt: (string);
  createdBy: (string);
  deletedAt?: (string);
  deletedBy?: (string);
}

export interface VariantSet {
  path: (string)[];
  name: (string);
  values: (string)[];
  default: (string);
  variantSelections: (VariantSelection)[];
}

export interface AssetVersionVariantSet extends VariantSet {
  type: (('Local' | 'Referenced'));
  assetVersion: (string);
}

export interface LocalAssetVersionVariantSet extends AssetVersionVariantSet {
  type: (('Local'));
}

export interface ReferencedAssetVersionVariantSet extends AssetVersionVariantSet {
  type: (('Referenced'));
  referencedPath: (string)[];
  referencedAssetVersion: (string);
  referencedAssetVersion$?: (AssetVersion);
}

export interface BlockedVariantSelectionAssemblingError extends AssemblingError {
  type: (('BlockedVariantSelection'));
  blockedVariantSelection: (BlockedVariantSelection);
  baseName: (string);
  asset: (string);
  assetVersionName: (string);
  assetVersion: (string);
}

export interface InvalidAssetTypeAssemblingError extends AssemblingError {
  type: (('InvalidAssetType'));
  baseName: (string);
  asset: (string);
  assetType: (('material' | 'object' | 'scene' | 'prefab' | 'comfy'));
  path?: (string)[];
}

export interface InvalidAssetVersionNumberAssemblingError extends AssemblingError {
  type: (('InvalidAssetVersionNumber'));
  baseName: (string);
  asset: (string);
  assetVersionName: (string);
  path?: (string)[];
}

export interface InvalidConditionAssemblingError extends AssemblingError {
  type: (('InvalidCondition'));
  condition: (Condition);
  path: (string)[];
}

export interface InvalidDefinitionTypeAssemblingError extends AssemblingError {
  type: (('InvalidDefinitionType'));
  path: (string)[];
  definitionType: (string);
}

export interface InvalidMainFileAssemblingError extends AssemblingError {
  type: (('InvalidMainFile'));
  baseName: (string);
  asset: (string);
  assetVersionName: (string);
  assetVersion: (string);
  mainFile: (string);
  path?: (string)[];
}

export interface VariantSetNotFoundAssemblingError extends AssemblingError {
  type: (('VariantSetNotFound'));
  baseName: (string);
  asset?: (string);
  assetVersionName: (string);
  assetVersion?: (string);
  variantSet: (string);
  path?: (string)[];
}

export interface VariantValueNotFoundAssemblingError extends AssemblingError {
  type: (('VariantValueNotFound'));
  baseName: (string);
  asset?: (string);
  assetVersionName: (string);
  assetVersion?: (string);
  variantSet: (string);
  variantValue: (string);
  path?: (string)[];
}

export interface AssemblyTemplateReference {
  type: (('Asset' | 'File'));
}

export interface AssemblyTemplateReferenceAsset extends AssemblyTemplateReference {
  type: (('Asset'));
  asset: (string);
  assetType: (('material' | 'object' | 'scene' | 'prefab' | 'comfy'));
  path?: (string);
}

export interface AssemblyTemplateReferenceFile extends AssemblyTemplateReference {
  type: (('File'));
  file: (string);
  path?: (string);
}

export interface AssemblyTemplateSublayerCondition {
  
}

export interface AssemblyTemplateSublayer {
  type: (('Asset' | 'File'));
  condition?: (AssemblyTemplateSublayerCondition);
}

export interface AssemblyTemplateSublayerAsset extends AssemblyTemplateSublayer {
  type: (('Asset'));
  asset: (string);
}

export interface AssemblyTemplateSublayerFile extends AssemblyTemplateSublayer {
  type: (('File'));
  file: (string);
}

export interface InvalidAssetAssemblingError extends AssemblingError {
  type: (('InvalidAsset'));
  asset: (string);
  path?: (string)[];
}

export interface InvalidVariantSetAssemblingError extends AssemblingError {
  type: (('InvalidVariantSet'));
  asset: (string);
  assetVersion: (string);
  variantSet: (string);
  path?: (string)[];
}

export interface StepInputAsset extends StepInput {
  type: (('Asset'));
  assetVersions: (string)[];
}

export interface StepInputTemplateAsset extends StepInputTemplate {
  type: (('Asset'));
  assets: (string)[];
}

export interface StepInputNumber extends StepInput {
  type: (('Number'));
  values: (number | null)[];
}

export interface StepInputTemplateNumber extends StepInputTemplate {
  type: (('Number'));
  values: (string)[];
  min?: (number);
  max?: (number);
}

export interface AssemblingTemplate {
  steps: (StepTemplate)[];
  inputs: (AssemblingTemplateInput)[];
  outputs: (AssemblingTemplateOutput)[];
}

export interface CameraNotFoundAssemblingError extends AssemblingError {
  type: (('CameraNotFound'));
  baseName: (string);
  asset: (string);
  assetVersionName: (string);
  assetVersion: (string);
  camera: (string);
}

export interface GraphPathNotFoundAssemblingError extends AssemblingError {
  type: (('GraphPathNotFound'));
  graphPath: (string);
  index?: (number);
}

export interface InvalidEnvironmentVariableNameAssemblingError extends AssemblingError {
  type: (('InvalidEnvironmentVariableName'));
  name: (string);
}

export interface InvalidEnvironmentVariableValueAssemblingError extends AssemblingError {
  type: (('InvalidEnvironmentVariableValue'));
  name: (string);
  value: (string);
}

export interface InvalidGraphVersionAssemblingError extends AssemblingError {
  type: (('InvalidGraphVersion'));
  graph?: (string);
  graphVersion?: (string);
}

export interface InvalidVariantValueAssemblingError extends AssemblingError {
  type: (('InvalidVariantValue'));
  asset: (string);
  variantSet: (string);
  variantValue: (string);
  path?: (string)[];
}

export interface MissingCameraAssemblingError extends AssemblingError {
  type: (('MissingCamera'));
  baseName: (string);
  asset: (string);
  assetVersionName: (string);
  assetVersion: (string);
}

export interface MissingSceneAssemblingError extends AssemblingError {
  type: (('MissingScene'));
}

export interface OrderNotFoundAssemblingError extends AssemblingError {
  type: (('OrderNotFound'));
  order: (string);
}

export interface PathNotFoundAssemblingError extends AssemblingError {
  type: (('PathNotFound'));
  baseName?: (string);
  asset?: (string);
  assetVersionName?: (string);
  assetVersion?: (string);
}

export interface AssemblyTemplateVariantCondition {
  
}

export interface AssemblyTemplateVariant {
  asset: (string);
  variantSet: (string);
  variantValue: (string);
  condition?: (AssemblyTemplateVariantCondition);
}

export interface AssemblyTemplateDefinitionCondition {
  
}

export interface AssemblyTemplateDefinition {
  name: (string);
  condition?: (AssemblyTemplateDefinitionCondition);
  type: (('over' | 'xform' | 'def'));
  reference?: (AssemblyTemplateReference);
  variants?: (AssemblyTemplateVariant)[];
  definitions?: (AssemblyTemplateDefinition)[];
}

export interface AssemblyTemplate {
  sublayers: (AssemblyTemplateSublayer)[];
  definitions: (AssemblyTemplateDefinition)[];
  variants: (AssemblyTemplateVariant)[];
}

export interface StepOutputFileStepFileType {
  type: (('Static' | 'FromInput'));
}

export interface StepOutputFileStepFileTypeStatic extends StepOutputFileStepFileType {
  type: (('Static'));
  fileType: (('Exr' | 'Jpeg' | 'Json' | 'Png' | 'Usd' | 'Webp' | 'SafeTensors' | 'Txt'));
}

export interface StepOutputFileStepFileTypeFromInput extends StepOutputFileStepFileType {
  type: (('FromInput'));
  name: (string);
}

export interface StepInputFileType extends StepInput {
  type: (('FileType'));
  fileTypes: (('Exr' | 'Jpeg' | 'Json' | 'Png' | 'Usd' | 'Webp' | 'SafeTensors' | 'Txt'))[];
  options?: ({[key: string]: string});
}

export interface StepOutputTemplateFile extends StepOutputTemplate {
  type: (('File'));
  fileType: (StepOutputFileStepFileType);
  path?: (string);
}

export interface StepTemplateAssembling extends StepTemplate {
  type: (('Assembling_Default_v1'));
  group: (('Assembling'));
}

export interface StepTemplateAssembling_Default_v1 extends StepTemplateAssembling {
  type: (('Assembling_Default_v1'));
  title: (string);
  subtitle: (string);
  version: (string);
  assemblyTemplate: (AssemblyTemplate);
  inputs: (StepInputTemplate)[];
  outputs: (StepOutputTemplate)[];
}

export interface StepInputTemplateFileType extends StepInputTemplate {
  type: (('FileType'));
  allowedFileTypes?: (('Exr' | 'Jpeg' | 'Json' | 'Png' | 'Usd' | 'Webp' | 'SafeTensors' | 'Txt'))[];
  fileTypes: (('Exr' | 'Jpeg' | 'Json' | 'Png' | 'Usd' | 'Webp' | 'SafeTensors' | 'Txt'))[];
}

export interface StepInputGraphPathGraphPath {
  type: (('GraphFile' | 'GraphFolder'));
  path?: (string);
  maxDate: (string | null);
}

export interface StepInputGraphPathGraphPathGraphFile extends StepInputGraphPathGraphPath {
  type: (('GraphFile'));
  graphFile: (string);
}

export interface StepInputGraphPathGraphPathGraphFolder extends StepInputGraphPathGraphPath {
  type: (('GraphFolder'));
  graphFolder: (string);
}

export interface StepInputGraphPath extends StepInput {
  type: (('GraphPath'));
  graphPaths: (StepInputGraphPathGraphPath | null)[];
}

export interface StepInputTemplateGraphPath extends StepInputTemplate {
  type: (('GraphPath'));
  graphPaths: (string)[];
}

export interface StepOutputTemplateFileBundleForeachGroupEntry {
  group: (string);
  title: (string);
  fileType: (StepOutputFileStepFileType);
}

export interface StepOutputTemplateFileBundle extends StepOutputTemplate {
  type: (('FileBundle'));
  files: (StepOutputTemplateFile)[];
  foreachGroupEntry?: (StepOutputTemplateFileBundleForeachGroupEntry);
}

export interface StepTemplateBasic_GraphFiles_v1 extends StepTemplateBasic {
  type: (('Basic_GraphFiles_v1'));
  title: (string);
  subtitle: (string);
  version: (string);
  inputs: (StepInputTemplate)[];
  outputs: (StepOutputTemplate)[];
}

export interface StepTemplateBasic_Or_v1 extends StepTemplateBasic {
  type: (('Basic_Or_v1'));
  title: (string);
  subtitle: (string);
  version: (string);
  inputs: (StepInputTemplate)[];
  outputs: (StepOutputTemplate)[];
}

export interface StepInputMap extends StepInput {
  type: (('Map'));
  values: ({[key: string]: string});
}

export interface StepInputTemplateMap extends StepInputTemplate {
  type: (('Map'));
  values: ({[key: string]: string});
}

export interface Command {
  name: (string);
  capability: (('Rendering' | 'Compositing'));
}

export interface Engine {
  _id: (string);
  type: (('Houdini' | 'Arnold'));
  version: (string);
  commands: (Command)[];
}

export interface StepTemplateCompositing extends StepTemplate {
  type: (('Compositing_Houdini_19_5_534_Hython_v1' | 'Compositing_Houdini_20_0_590_Hython_v1' | 'Compositing_Houdini_20_0_590_Hoiiotool_v1'));
  group: (('Compositing'));
  engine: (('Houdini' | 'Arnold'));
  engineVersion: (string);
}

export interface StepTemplateCompositing_Houdini_19_5_534_Hython_v1 extends StepTemplateCompositing {
  type: (('Compositing_Houdini_19_5_534_Hython_v1'));
  title: (string);
  subtitle: (string);
  version: (string);
  inputs: (StepInputTemplate)[];
  outputs: (StepOutputTemplate)[];
}

export interface EqualsCondition extends Condition {
  type: (('Equals'));
  path: (string);
  value: (string);
}

export interface NotNullOrEmptyCondition extends Condition {
  type: (('NotNullOrEmpty'));
  path: (string);
}

export interface StepTemplateCompositing_Houdini_20_0_590_Hoiiotool_v1 extends StepTemplateCompositing {
  type: (('Compositing_Houdini_20_0_590_Hoiiotool_v1'));
  title: (string);
  subtitle: (string);
  version: (string);
  inputs: (StepInputTemplate)[];
  outputs: (StepOutputTemplate)[];
}

export interface StepTemplateCompositing_Houdini_20_0_590_Hython_v1 extends StepTemplateCompositing {
  type: (('Compositing_Houdini_20_0_590_Hython_v1'));
  title: (string);
  subtitle: (string);
  version: (string);
  inputs: (StepInputTemplate)[];
  outputs: (StepOutputTemplate)[];
}

export interface StepTemplateGenAI extends StepTemplate {
  type: (('GenAI_LoRATraining_v1' | 'GenAI_Comfy_v1'));
  group: (('GenAI'));
}

export interface StepTemplateGenAI_Comfy_v1 extends StepTemplateGenAI {
  type: (('GenAI_Comfy_v1'));
  title: (string);
  subtitle: (string);
  version: (string);
  agency?: (string);
  asset?: (string);
  assetVersion?: (string);
  workflow?: (number);
  inputs: (StepInputTemplate)[];
  outputs: (StepOutputTemplate)[];
}

export interface StepTemplateGenAI_LoRATraining_v1 extends StepTemplateGenAI {
  type: (('GenAI_LoRATraining_v1'));
  title: (string);
  subtitle: (string);
  version: (string);
  inputs: (StepInputTemplate)[];
  outputs: (StepOutputTemplate)[];
}

export interface StepInputOrderOrder {
  order: (string);
  query: (Condition | null);
  maxDate: (string);
}

export interface StepInputOrder extends StepInput {
  type: (('Order'));
  orders: (StepInputOrderOrder | null)[];
}

export interface StepInputTemplateOrderOrder {
  order: (string);
  query?: (Condition | null);
}

export interface StepInputTemplateOrder extends StepInputTemplate {
  type: (('Order'));
  orders: (StepInputTemplateOrderOrder)[];
}

export interface StepTemplateOrder extends StepTemplate {
  type: (('Order_Outputs_v1'));
  group: (('Order'));
}

export interface StepTemplateOrder_Outputs_v1 extends StepTemplateOrder {
  type: (('Order_Outputs_v1'));
  title: (string);
  subtitle: (string);
  version: (string);
  inputs: (StepInputTemplate)[];
  outputs: (StepOutputTemplate)[];
}

export interface StepTemplatePrefab extends StepTemplate {
  type: (('Prefab_Houdini_19_5_534_Hython_v1' | 'Prefab_Houdini_20_0_590_Hython_v1'));
  group: (('Prefab'));
  engine: (('Houdini' | 'Arnold'));
  engineVersion: (string);
  agency?: (string);
  asset?: (string);
  assetVersion?: (string);
  action?: (number);
}

export interface StepTemplatePrefab_Houdini_19_5_534_Hython_v1 extends StepTemplatePrefab {
  type: (('Prefab_Houdini_19_5_534_Hython_v1'));
  title: (string);
  subtitle: (string);
  version: (string);
  engine: (('Houdini'));
  engineVersion: (('19.5.534'));
  inputs: (StepInputTemplate)[];
  outputs: (StepOutputTemplate)[];
}

export interface StepTemplatePrefab_Houdini_20_0_590_Hython_v1 extends StepTemplatePrefab {
  type: (('Prefab_Houdini_20_0_590_Hython_v1'));
  title: (string);
  subtitle: (string);
  version: (string);
  inputs: (StepInputTemplate)[];
  outputs: (StepOutputTemplate)[];
}

export interface StepTemplateRendering extends StepTemplate {
  type: (('Rendering_Houdini_19_5_534_Arnold_v1' | 'Rendering_Houdini_20_0_590_Arnold_v1'));
  group: (('Rendering'));
  engine: (('Houdini' | 'Arnold'));
  engineVersion: (string);
}

export interface StepTemplateRendering_Houdini_19_5_534_Arnold_v1 extends StepTemplateRendering {
  type: (('Rendering_Houdini_19_5_534_Arnold_v1'));
  title: (string);
  subtitle: (string);
  version: (string);
  inputs: (StepInputTemplate)[];
  outputs: (StepOutputTemplate)[];
}

export interface StepTemplateRendering_Houdini_20_0_590_Arnold_v1 extends StepTemplateRendering {
  type: (('Rendering_Houdini_20_0_590_Arnold_v1'));
  title: (string);
  subtitle: (string);
  version: (string);
  inputs: (StepInputTemplate)[];
  outputs: (StepOutputTemplate)[];
}

export interface StepTemplateUtils extends StepTemplate {
  type: (('Utils_DownloadFile_v1' | 'Utils_ConvertImage_v1' | 'Utils_MontageImage_v1' | 'Utils_UsdAnalyzer_v1'));
  group: (('Utils'));
}

export interface StepTemplateUtils_ConvertImage_v1 extends StepTemplateUtils {
  type: (('Utils_ConvertImage_v1'));
  title: (string);
  subtitle: (string);
  version: (string);
  inputs: (StepInputTemplate)[];
  outputs: (StepOutputTemplate)[];
}

export interface StepTemplateUtils_DownloadFile_v1 extends StepTemplateUtils {
  type: (('Utils_DownloadFile_v1'));
  title: (string);
  subtitle: (string);
  version: (string);
  inputs: (StepInputTemplate)[];
  outputs: (StepOutputTemplate)[];
}

export interface StepTemplateUtils_MontageImage_v1 extends StepTemplateUtils {
  type: (('Utils_MontageImage_v1'));
  title: (string);
  subtitle: (string);
  version: (string);
  inputs: (StepInputTemplate)[];
  outputs: (StepOutputTemplate)[];
}

export interface StepTemplateUtils_UsdAnalyzer_v1 extends StepTemplateUtils {
  type: (('Utils_UsdAnalyzer_v1'));
  title: (string);
  subtitle: (string);
  version: (string);
  inputs: (StepInputTemplate)[];
  outputs: (StepOutputTemplate)[];
}

export interface Outfiles {
  totalFiles: (number);
  chunkSize: (number);
  fileType: (('Exr' | 'Jpeg' | 'Json' | 'Png' | 'Usd' | 'Webp' | 'SafeTensors' | 'Txt'));
}

export interface StepHash {
  stepType: (('Basic_Or_v1' | 'Basic_Graph_v1' | 'Basic_GraphFiles_v1' | 'Prefab_Houdini_19_5_534_Hython_v1' | 'Prefab_Houdini_20_0_590_Hython_v1' | 'Assembling_Default_v1' | 'Rendering_Houdini_19_5_534_Arnold_v1' | 'Rendering_Houdini_20_0_590_Arnold_v1' | 'Compositing_Houdini_19_5_534_Hython_v1' | 'Compositing_Houdini_20_0_590_Hython_v1' | 'Compositing_Houdini_20_0_590_Hoiiotool_v1' | 'Utils_DownloadFile_v1' | 'Utils_ConvertImage_v1' | 'Utils_MontageImage_v1' | 'Utils_UsdAnalyzer_v1' | 'Order_Outputs_v1' | 'GenAI_LoRATraining_v1' | 'GenAI_Comfy_v1'));
  hash: (string);
}

export interface StepExecution extends StepHash {
  renderer?: (string);
  thread?: (number);
  error?: (string);
  startedAt?: (string);
  finishedAt?: (string);
  job?: (string);
}

export interface StepCache extends StepExecution {
  _id: (string);
  agency: (string);
  grep: (string);
  renderer: (string);
  thread: (number);
  createdAt?: (string);
  lastUsage?: (string);
  deletedAt?: (string);
}

export interface Definition {
  type: (string);
}

export interface VariantSelectionDefinitions {
  variantSelections: (VariantSelection)[];
  definitions: ({[key: string]: Definition});
}

export interface Outliner {
  definitions: ({[key: string]: Definition});
  variantSelectionDefinitions: (VariantSelectionDefinitions)[];
}

export interface AssetVersionOutliner extends Outliner {
  _id: (string);
  asset: (string);
  assetVersions: (string)[];
  hash: (string);
}

export interface AssetVersionReference {
  _id: (string);
  assetVersion: (string);
  path: (string)[];
  referencedPath: (string)[];
  referencedAssetVersion: (string);
}

export interface ComfyAssetVersionWorkflow {
  name?: (string);
  inputs: (StepInputTemplate)[];
  outputs: (StepOutputTemplate)[];
}

export interface ComfyAssetVersion extends AssetVersion {
  type: (('ComfyAssetVersion'));
  workflows: (ComfyAssetVersionWorkflow)[];
}

export interface AssetHistoryEntry {
  type: (('CommentAssetHistoryEntry' | 'AssetVersionStateHistoryEntry'));
  date: (string);
  user?: (string);
  user$?: (User);
}

export interface AssetVersionStateHistoryEntry extends AssetHistoryEntry {
  type: (('AssetVersionStateHistoryEntry'));
  asset: (string);
  assetVersion: (string);
  assetVersionNumber: (number);
  state: (('Empty' | 'Uploading' | 'Analyzing' | 'Error' | 'Finished' | 'Submitted' | 'Rejected' | 'Publishing' | 'Published' | 'Obsolete'));
}

export interface CommentAssetHistoryEntry extends AssetHistoryEntry {
  type: (('CommentAssetHistoryEntry'));
  message: (string);
}

export interface Material {
  path: (string)[];
}

export interface MaterialAssetVersionBoundingBox {
  min: (number)[];
  max: (number)[];
}

export interface MaterialAssetVersion extends AssetVersion {
  type: (('MaterialAssetVersion'));
  materials?: (Material)[];
  usdErrors?: (string)[];
  boundingBox?: (MaterialAssetVersionBoundingBox);
}

export interface ObjectAssetVersionBoundingBox {
  min: (number)[];
  max: (number)[];
}

export interface ObjectAssetVersion extends AssetVersion {
  type: (('ObjectAssetVersion'));
  usdErrors?: (string)[];
  boundingBox?: (ObjectAssetVersionBoundingBox);
}

export interface PrefabActionInput {
  type: (('AssetPrefabActionInput' | 'StringPrefabActionInput'));
  name: (string);
  list: (boolean);
}

export interface AssetPrefabActionInput extends PrefabActionInput {
  type: (('AssetPrefabActionInput'));
}

export interface StringPrefabActionInput extends PrefabActionInput {
  type: (('StringPrefabActionInput'));
}

export interface PrefabActionOutput {
  type: (('UsdPrefabActionOutput'));
  name: (string);
  path: (string);
}

export interface UsdPrefabActionOutput extends PrefabActionOutput {
  type: (('UsdPrefabActionOutput'));
}

export interface PrefabAction {
  name: (string);
  filePath: (string);
  executionPath: (string);
  engine: (('Houdini' | 'Arnold'));
  version: (string);
  inputs: (PrefabActionInput)[];
  outputs: (PrefabActionOutput)[];
}

export interface PrefabAssetVersion extends AssetVersion {
  type: (('PrefabAssetVersion'));
  actions?: (PrefabAction)[];
  usdErrors?: (string)[];
}

export interface Preview {
  type: (('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview'));
  _id: (string);
  title: (string);
  state: (('Creating' | 'Finished' | 'Error' | 'Deleted'));
  createdAt: (string);
  createdBy: (string);
  variantSelections: (VariantSelection)[];
  deletedAt?: (string);
  deletedBy?: (string);
  thumbnailUrl?: (string);
  url?: (string);
}

export interface Output {
  _id: (string);
  name: (string);
  assembling: (string);
  assemblingOutput: (string);
  orderParserOutputIndex: (number);
}

export interface GeneratedPreview extends Preview {
  type: (('GeneratedPreview'));
  previewTemplate: (string);
  lastJob?: (string);
  output?: (Output);
  assembling: (Assembling);
}

export interface OrderLinePreview extends Preview {
  type: (('OrderLinePreview'));
  order: (string);
  orderLine: (string);
}

export interface PreviewFile extends File {
  type: (('PreviewFile'));
  previewSet: (string);
  preview?: (string);
}

export interface JobOutput {
  type: (('Plain' | 'PreviewSet' | 'Preview' | 'OrderLine' | 'UsdAnalyzer'));
  _id: (string);
  name?: (string);
  assembling: (string);
  assemblingOutput: (string);
  url?: (string);
  thumbnailUrl?: (string);
}

export interface PreviewSetJobOutput extends JobOutput {
  type: (('PreviewSet'));
  preview: (string);
}

export interface Thread {
  index: (number);
  state: (('Idle' | 'Working'));
  job: (string);
  step: (StepHash);
  startedAt: (string);
}

export interface RendererStateHistory {
  state: (('Creating' | 'Running' | 'Updating' | 'ShuttingDown' | 'Stopped' | 'Deleting' | 'Deleted'));
  date: (string);
}

export interface RendererGpusProccesses {
  username: (string);
  command: (string);
  memoryUsage: (number);
  pid: (number);
}

export interface RendererGpus {
  index: (number);
  name: (string);
  uuid: (string);
  temperature: (number);
  utilization: (number);
  memoryUsed: (number);
  memoryTotal: (number);
  proccesses: (RendererGpusProccesses)[];
}

export interface Renderer {
  _id: (string);
  expectedState: (('Creating' | 'Running' | 'Updating' | 'ShuttingDown' | 'Stopped' | 'Deleting' | 'Deleted'));
  expectedShutdownLevel?: (number);
  state: (('Creating' | 'Running' | 'Updating' | 'ShuttingDown' | 'Stopped' | 'Deleting' | 'Deleted'));
  stateHistory: (RendererStateHistory)[];
  hostname: (string);
  maxThreads?: (number);
  socketId?: (string);
  connected?: (boolean);
  disconnectedAt?: (string);
  threads: (Thread)[];
  engines: (Engine)[];
  gpus: (RendererGpus)[];
  stepTypes: (('Basic_Or_v1' | 'Basic_Graph_v1' | 'Basic_GraphFiles_v1' | 'Prefab_Houdini_19_5_534_Hython_v1' | 'Prefab_Houdini_20_0_590_Hython_v1' | 'Assembling_Default_v1' | 'Rendering_Houdini_19_5_534_Arnold_v1' | 'Rendering_Houdini_20_0_590_Arnold_v1' | 'Compositing_Houdini_19_5_534_Hython_v1' | 'Compositing_Houdini_20_0_590_Hython_v1' | 'Compositing_Houdini_20_0_590_Hoiiotool_v1' | 'Utils_DownloadFile_v1' | 'Utils_ConvertImage_v1' | 'Utils_MontageImage_v1' | 'Utils_UsdAnalyzer_v1' | 'Order_Outputs_v1' | 'GenAI_LoRATraining_v1' | 'GenAI_Comfy_v1'));
  disabledStepTypes?: (('Basic_Or_v1' | 'Basic_Graph_v1' | 'Basic_GraphFiles_v1' | 'Prefab_Houdini_19_5_534_Hython_v1' | 'Prefab_Houdini_20_0_590_Hython_v1' | 'Assembling_Default_v1' | 'Rendering_Houdini_19_5_534_Arnold_v1' | 'Rendering_Houdini_20_0_590_Arnold_v1' | 'Compositing_Houdini_19_5_534_Hython_v1' | 'Compositing_Houdini_20_0_590_Hython_v1' | 'Compositing_Houdini_20_0_590_Hoiiotool_v1' | 'Utils_DownloadFile_v1' | 'Utils_ConvertImage_v1' | 'Utils_MontageImage_v1' | 'Utils_UsdAnalyzer_v1' | 'Order_Outputs_v1' | 'GenAI_LoRATraining_v1' | 'GenAI_Comfy_v1'));
  lastPing?: (string);
  uptime?: (number);
  startedAt?: (string);
  expectedVersion: (string);
  version?: (string);
  expectedCommit: (string);
  commit?: (string);
  environment?: ({[key: string]: string});
}

export interface JobUsedAsset {
  asset: (string);
  assetVersion: (string);
  hash: (string);
}

export interface JobStateHistory {
  state: (('Queued' | 'Running' | 'Success' | 'Error' | 'Canceled' | 'Deferred' | 'Paused'));
  date: (string);
  user?: (string);
}

export interface Job {
  type: (('Plain' | 'PreviewSet' | 'Preview' | 'OrderLine' | 'UsdAnalyzer'));
  _id: (string);
  jobNumber?: (string);
  state: (('Queued' | 'Running' | 'Success' | 'Error' | 'Canceled' | 'Deferred' | 'Paused'));
  stateHistory: (JobStateHistory)[];
  assemblings: (Assembling)[];
  jobOutputs: (JobOutput)[];
  grep?: (string);
  nextStep?: (StepHash);
  executionPlan: (StepExecution)[];
  pauseAt?: (StepHash);
  errorMessage?: (string);
  agency: (string);
  agency$?: (Agency);
  createdBy: (string);
  createdBy$?: (User);
  createdAt: (string);
  usedAssets: (JobUsedAsset)[];
  renderer?: (string);
  renderer$?: (Renderer);
  thread?: (number);
  greppedAt?: (string);
  startedAt?: (string);
  finishedAt?: (string);
  montageUrl?: (string);
  montageThumbnailUrl?: (string);
  montageAnimationUrl?: (string);
}

export interface PreviewSetJob extends Job {
  type: (('PreviewSet'));
  asset?: (string);
  assetVersion?: (string);
  previewSet?: (string);
  jobOutputs: (PreviewSetJobOutput)[];
}

export interface PreviewSet {
  _id: (string);
  asset: (string);
  assetVersion: (string);
  state: (('Creating' | 'Working' | 'Processable' | 'Processing' | 'Error' | 'Finished'));
  montageUrl?: (string);
  montageThumbnailUrl?: (string);
  montageAnimationUrl?: (string);
  previews: (Preview)[];
  lastJob?: (string);
  lastJob$?: (PreviewSetJob);
  createdAt: (string);
  createdBy: (string);
  createdBy$?: (User);
  deletedAt?: (string);
  deletedBy?: (string);
  deletedBy$?: (User);
  publishedAt?: (string);
  publishedBy?: (string);
  publishedBy$?: (User);
}

export interface Camera {
  name: (string);
  path: (string)[];
}

export interface SceneAssetVersionBoundingBox {
  min: (number)[];
  max: (number)[];
}

export interface SceneAssetVersion extends AssetVersion {
  type: (('SceneAssetVersion'));
  cameras?: (Camera)[];
  usdErrors?: (string)[];
  boundingBox?: (SceneAssetVersionBoundingBox);
}

export interface OutputTemplateVariant {
  asset?: (string);
  variantSet?: (string);
  variantValue?: (string);
  path?: (string);
}

export interface OutputTemplateTarget {
  asset?: (string);
  targetName?: (string);
  targetAsset?: (string);
  path?: (string);
}

export interface OutputTemplateVariable {
  name?: (string);
  value?: (string);
}

export interface OutputTemplateEnvironmentVariable {
  name?: (string);
  value?: (string);
}

export interface OutputTemplate {
  scene?: (string);
  camera?: (string);
  variants?: (OutputTemplateVariant)[];
  targets?: (OutputTemplateTarget)[];
  variables?: (OutputTemplateVariable)[];
  environmentVariables?: (OutputTemplateEnvironmentVariable)[];
}

export interface PreviewTemplate {
  _id: (string);
  name: (string);
  assetType: (string);
  variables: ({[key: string]: string});
  cameras?: (Camera)[];
  variantSets?: (VariantSet)[];
  assemblingTemplate: (AssemblingTemplate);
  assemblyTemplate?: (AssemblyTemplate);
  outputTemplate?: (OutputTemplate);
}

export interface UploadPreview extends Preview {
  type: (('UploadPreview'));
  uploadUrl?: (string);
}

export interface Target {
  path: (string)[];
  name: (string);
}

export interface AssetVersionTarget extends Target {
  type: (('Local' | 'Referenced'));
  assetVersion: (string);
}

export interface LocalTarget extends AssetVersionTarget {
  type: (('Local'));
}

export interface ReferencedTarget extends AssetVersionTarget {
  type: (('Referenced'));
  referencedPath: (string)[];
  referencedAssetVersion: (string);
}

export interface Comment {
  _id: (string);
  type: (('AssetComment' | 'TaskComment' | 'OrderLineComment'));
  message: (string);
  user: (string);
  user$?: (User);
  createdAt: (string);
  deletedAt?: (string);
}

export interface AssetComment extends Comment {
  _id: (string);
  type: (('AssetComment'));
  asset: (string);
  assetVersion?: (string);
}

export interface OrderLineComment extends Comment {
  _id: (string);
  type: (('OrderLineComment'));
  orderLineIdentifier: (string);
  orderLine: (string);
  order: (string);
}

export interface TaskComment extends Comment {
  _id: (string);
  type: (('TaskComment'));
  task: (string);
}

export interface Company {
  _id: (string);
  name: (string);
}

export interface AndCondition extends Condition {
  type: (('And'));
  conditions: (Condition)[];
}

export interface ContainsCondition extends Condition {
  type: (('Contains'));
  path: (string);
  value: (string);
}

export interface DateAfterCondition extends Condition {
  type: (('DateAfter'));
  path: (string);
  value: (string);
}

export interface DateBeforeCondition extends Condition {
  type: (('DateBefore'));
  path: (string);
  value: (string);
}

export interface DateIsCondition extends Condition {
  type: (('DateIs'));
  path: (string);
  value: (string);
}

export interface DateIsNotCondition extends Condition {
  type: (('DateIsNot'));
  path: (string);
  value: (string);
}

export interface EndsWithCondition extends Condition {
  type: (('EndsWith'));
  path: (string);
  value: (string);
}

export interface InCondition extends Condition {
  type: (('In'));
  path: (string);
  values: (string)[];
}

export interface NotContainsCondition extends Condition {
  type: (('NotContains'));
  path: (string);
  value: (string);
}

export interface NotEqualsCondition extends Condition {
  type: (('NotEquals'));
  path: (string);
  value: (string);
}

export interface NotInCondition extends Condition {
  type: (('NotIn'));
  path: (string);
  values: (string)[];
}

export interface NullOrEmptyCondition extends Condition {
  type: (('NullOrEmpty'));
  path: (string);
}

export interface OrCondition extends Condition {
  type: (('Or'));
  conditions: (Condition)[];
}

export interface StartsWithCondition extends Condition {
  type: (('StartsWith'));
  path: (string);
  value: (string);
}

export interface Contract {
  _id: (string);
  agency: (string);
  contractor: (string);
  name: (string);
  description: (string);
  validTo: (string);
  createdAt: (string);
  createdBy: (string);
  acceptedAt?: (string);
  acceptedBy?: (string);
  declinedAt?: (string);
  declinedBy?: (string);
  deletedAt?: (string);
  deletedBy?: (string);
}

export interface FileInfo {
  path: (string);
  md5: (string);
  length: (number);
}

export interface GraphVersionNote {
  type: (('Markdown' | 'Condition'));
  id: (string);
  x: (number);
  y: (number);
  width: (number);
  height: (number);
  color: (string);
  collapsed: (boolean);
}

export interface GraphVersionNoteMarkdown extends GraphVersionNote {
  type: (('Markdown'));
  markdown: (string);
}

export interface GraphVersionNoteCondition extends GraphVersionNote {
  type: (('Condition'));
  collapsedCondition: (boolean);
  forElement: (string);
  markdown: (string);
}

export interface GraphVersionParent {
  _id: (string);
  version: (number);
}

export interface GraphVersion {
  _id: (string);
  assemblingTemplate: (AssemblingTemplate);
  notes: (GraphVersionNote)[];
  agency: (string);
  agency$?: (Agency);
  graph: (string);
  parent?: (string);
  parent$?: (GraphVersionParent);
  usedGraphVersions: (string)[];
  saveMessage?: (string);
  change: (number);
  version: (number);
  createdAt: (string);
  createdBy: (string);
  createdBy$?: (User);
  updatedAt?: (string);
  updatedBy?: (string);
  updatedBy$?: (User);
  savedAt?: (string);
  savedBy?: (string);
  savedBy$?: (User);
  deletedAt?: (string);
  deletedBy?: (string);
  deletedBy$?: (User);
}

export interface GraphGraphVersion {
  _id: (string);
  version: (number);
  savedAt?: (string);
}

export interface GraphPath$ {
  _id: (string);
  name: (string);
  level?: (number);
}

export interface Graph {
  _id: (string);
  agency: (string);
  name: (string);
  versions: (number);
  graphFolder?: (string | null);
  createdAt: (string);
  createdBy: (string);
  createdBy$?: (User);
  updatedAt?: (string);
  updatedBy?: (string);
  updatedBy$?: (User);
  deletedAt?: (string);
  deletedBy?: (string);
  deletedBy$?: (User);
  latestGraphVersion?: (string);
  latestGraphVersion$?: (GraphVersion);
  latestVersion?: (string);
  graphVersions$?: (GraphGraphVersion)[];
  path$?: (GraphPath$)[];
}

export interface GraphFilePath$ {
  _id: (string);
  name: (string);
  level?: (number);
}

export interface GraphFile extends File {
  type: (('GraphFile'));
  graphFolder?: (string | null);
  previousGraphFileVersion?: (string | null);
  path$?: (GraphFilePath$)[];
  createdAt: (string);
  createdBy: (string);
  createdBy$?: (User);
  updatedAt?: (string);
  updatedBy?: (string);
  updatedBy$?: (User);
  deletedAt?: (string);
  deletedBy?: (string);
  deletedBy$?: (User);
}

export interface GraphFolderParent$ {
  _id: (string);
  name: (string);
  level?: (number);
}

export interface GraphFolderChildren$ {
  _id: (string);
  name: (string);
  level?: (number);
}

export interface GraphFolderPath$ {
  _id: (string);
  name: (string);
  level?: (number);
}

export interface GraphFolder {
  _id: (string);
  agency: (string);
  name: (string);
  parent?: (string | null);
  parent$?: (GraphFolderParent$ | null);
  children$?: (GraphFolderChildren$)[];
  path$?: (GraphFolderPath$)[];
  createdAt: (string);
  createdBy: (string);
  createdBy$?: (User);
  updatedAt?: (string);
  updatedBy?: (string);
  updatedBy$?: (User);
  deletedAt?: (string);
  deletedBy?: (string);
  deletedBy$?: (User);
}

export interface OrderLinesStatistic {
  count: (number);
  calculatedAt: (string);
  waitingForPrerequisites: (number);
  noOutputsDefined: (number);
  reviewing: (number);
  prerequisitesFulfilled: (number);
  waitingForProcessing: (number);
  queued: (number);
  processing: (number);
  waitingForQC: (number);
  done: (number);
  rejected: (number);
  error: (number);
  downloaded: (number);
  locked: (number);
}

export interface Order {
  _id: (string);
  name: (string);
  skipQualityCheck?: (boolean);
  lines?: (number);
  orderLinesStatistic: (OrderLinesStatistic);
  columns?: (string)[];
  agency: (string);
  agency$?: (Agency);
  createdAt: (string);
  deletedAt?: (string);
  createdBy?: (string);
  createdBy$?: (User);
  deletedBy?: (string);
  deletedBy$?: (User);
  state: (('New' | 'InProgress' | 'Finished'));
  tags: (Tag)[];
  defaultContract?: (string);
}

export interface SampleFileInput {
  graph: (string);
  graphVersion: (string);
  output: (string);
  values: ({[key: string]: string});
}

export interface Sample {
  type: (('Plain' | 'OrderLine'));
  _id: (string);
  name: (string);
  agency: (string);
  graph: (string);
  graphVersion: (string);
  lastJob?: (string);
  fileInputs: ({[key: string]: SampleFileInput});
  createdAt: (string);
  createdBy: (string);
  updatedAt?: (string);
  updatedBy?: (string);
  deletedAt?: (string);
  deletedBy?: (string);
}

export interface PlainSample extends Sample {
  type: (('Plain'));
  values: ({[key: string]: string});
}

export interface OrderLineSampleOrderLine {
  _id: (string);
  plainOrderLine?: ({[key: string]: string});
}

export interface OrderLineSample extends Sample {
  type: (('OrderLine'));
  order: (string);
  order$?: (Order);
  identifier: (string);
  output: (number);
  orderLine$?: (OrderLineSampleOrderLine);
}

export interface JobDownload {
  type: (('File' | 'Files' | 'FileBundle'));
}

export interface JobDownloadFile extends JobDownload {
  type: (('File'));
  downloadUrl: (string);
}

export interface JobDownloadFiles extends JobDownload {
  type: (('Files'));
  downloadUrls: (string)[];
}

export interface JobDownloadFileBundle extends JobDownload {
  type: (('FileBundle'));
  downloadUrls: ({[key: string]: JobDownloadFiles});
}

export interface JobOutputFile extends File {
  type: (('JobOutputFile'));
  job: (string);
  jobOutput: (string);
}

export interface JobProgress {
  job: (string);
  date: (string);
  stepType: (string);
  stepHash: (string);
  jobProgress: (number);
  jobOutputProgress?: ({[key: string]: number});
  stepProgress: (number);
  stepIndex: (number);
  totalSteps: (number);
  preProcessingProgress: (number);
  postProcessingProgress: (number);
}

export interface JobResult {
  _id: (string);
  job: (string);
  code: (number);
  state: (('Success' | 'Error' | 'Cancel'));
}

export interface OrderLineJobOutput extends JobOutput {
  type: (('OrderLine'));
  output: (string);
}

export interface OrderLineJobOrder {
  _id: (string);
  name: (string);
}

export interface OrderLineJobOrderLine {
  _id: (string);
  identifier: (string);
  orderParsing?: (string);
  orderUpload?: (string);
}

export interface OrderLineJob extends Job {
  type: (('OrderLine'));
  order: (string);
  order$?: (OrderLineJobOrder);
  orderLine: (string);
  orderLine$?: (OrderLineJobOrderLine);
  jobOutputs: (OrderLineJobOutput)[];
}

export interface PreviewJobOutput extends JobOutput {
  type: (('Preview'));
  output?: (string);
  sceneAsset?: (string);
  sceneAssetVersion?: (string);
  sceneMainFile?: (string);
  camera?: (string);
  assembly?: (Assembly);
}

export interface PreviewJob extends Job {
  type: (('Preview'));
  previewSet: (string);
  previewSet$?: (PreviewSet);
  preview: (string);
  preview$?: (Preview);
  jobOutputs: (PreviewJobOutput)[];
  usedAssets: (JobUsedAsset)[];
}

export interface UsdAnalyzerJobOutput extends JobOutput {
  type: (('UsdAnalyzer'));
}

export interface UsdAnalyzerJob extends Job {
  type: (('UsdAnalyzer'));
  asset: (string);
  assetVersion: (string);
  jobOutputs: (UsdAnalyzerJobOutput)[];
}

export interface LogEntry {
  job: (string);
  output: (string);
  line: (number);
  date: (string);
  stream: (('stdout' | 'stderr'));
  step: (string);
  log: (string);
}

export interface OrderLineHistoryEntry {
  type: (('CommentOrderLineHistoryEntry' | 'ParsingOrderLineHistoryEntry' | 'StateOrderLineHistoryEntry'));
  date: (string);
  user?: (string);
  user$?: (User);
}

export interface CommentOrderLineHistoryEntry extends OrderLineHistoryEntry {
  type: (('CommentOrderLineHistoryEntry'));
  message: (string);
}

export interface ParsingOrderLineHistoryEntry extends OrderLineHistoryEntry {
  type: (('ParsingOrderLineHistoryEntry'));
  orderUpload: (string);
  orderParsing: (string);
}

export interface OrderLineStateHistory {
  state: (('WaitingForPrerequisites' | 'NoOutputsDefined' | 'DuplicatedOutputNames' | 'Reviewing' | 'Downloaded' | 'PrerequisitesFulfilled' | 'WaitingForProcessing' | 'Queued' | 'Processing' | 'WaitingForQC' | 'Done' | 'Rejected' | 'Error' | 'Locked' | 'Deferred'));
  date: (string);
  user?: (string);
  user$?: (User);
  message?: (string);
}

export interface OrderLineOrderLineTranslations {
  newValue: (string);
  usedTranslation: (string);
}

export interface OrderLine {
  _id: (string);
  order: (string);
  order$?: (Order);
  agency: (string);
  orderUpload?: (string);
  orderParsing?: (string);
  orderParsingChunk?: (string);
  hash?: (string);
  identifier: (string);
  outputs: (Output)[];
  assemblings: (Assembling)[];
  executionPlan?: (StepHash)[];
  lastJob?: (string);
  lastJob$?: (OrderLineJob);
  state: (('WaitingForPrerequisites' | 'NoOutputsDefined' | 'DuplicatedOutputNames' | 'Reviewing' | 'Downloaded' | 'PrerequisitesFulfilled' | 'WaitingForProcessing' | 'Queued' | 'Processing' | 'WaitingForQC' | 'Done' | 'Rejected' | 'Error' | 'Locked' | 'Deferred'));
  stateHistory: (OrderLineStateHistory)[];
  assemblingUsedAssets?: (AssemblingUsedAsset)[];
  assemblingErrors?: (AssemblingError)[];
  plainOrderLine?: ({[key: string]: string});
  orderLineTranslations?: ({[key: string]: OrderLineOrderLineTranslations});
  createdBy: (string);
  createdBy$?: (User);
  createdAt: (string);
  deletedAt?: (string);
  deletedBy?: (string);
  hasDuplicate?: (boolean);
  locks?: (string)[];
}

export interface StateOrderLineHistoryEntry extends OrderLineHistoryEntry {
  type: (('StateOrderLineHistoryEntry'));
  state: (('WaitingForPrerequisites' | 'NoOutputsDefined' | 'DuplicatedOutputNames' | 'Reviewing' | 'Downloaded' | 'PrerequisitesFulfilled' | 'WaitingForProcessing' | 'Queued' | 'Processing' | 'WaitingForQC' | 'Done' | 'Rejected' | 'Error' | 'Locked' | 'Deferred'));
}

export interface OrderLineAggregation {
  state?: (('WaitingForPrerequisites' | 'NoOutputsDefined' | 'DuplicatedOutputNames' | 'Reviewing' | 'Downloaded' | 'PrerequisitesFulfilled' | 'WaitingForProcessing' | 'Queued' | 'Processing' | 'WaitingForQC' | 'Done' | 'Rejected' | 'Error' | 'Locked' | 'Deferred'));
  plainOrderLine?: ({[key: string]: string});
  statistic: (OrderLinesStatistic);
}

export interface OrderLineStateOverview {
  waitingForPrerequisites: (number);
  waitingForProcessing: (number);
  processing: (number);
  processed: (number);
  waitingForQC: (number);
  done: (number);
  error: (number);
}

export interface OrderLineUsedAsset {
  asset: (string);
  assetVersion?: (string);
}

export interface OrderLock {
  _id: (string);
  order: (string);
  name: (string);
  filters: (Condition)[];
  identifiers: (string)[];
  deletedAt?: (string);
  deletedBy?: (string);
}

export interface OrderParserGraph {
  _id: (string);
  name: (string);
}

export interface OrderParserGraphVersion {
  _id: (string);
  version: (number);
}

export interface Mapping {
  type: (('Fixed' | 'Column'));
}

export interface ColumnMapping extends Mapping {
  type: (('Column'));
  column: (string);
}

export interface FixedMapping extends Mapping {
  type: (('Fixed'));
  value: (string);
}

export interface OrderParserOutput {
  name: (string);
  condition?: (Condition);
  graph: (string);
  graph$?: (OrderParserGraph);
  graphVersion: (string | null);
  graphVersion$?: (OrderParserGraphVersion);
  mapping: ({[key: string]: Mapping});
  graphVersionOutput: (string);
}

export interface BaseOrderParser {
  name: (string);
  outputs: (OrderParserOutput)[];
}

export interface OrderParser extends BaseOrderParser {
  _id: (string);
  agency: (string);
}

export interface OrderParsingResult {
  lines: (number);
  skipped: (number);
  successful: (number);
  assetNotFound: (number);
  assetVersionNotFound: (number);
  cameraNotFound: (number);
  invalidAssetType: (number);
  invalidAssetVersionNumber: (number);
  invalidCondition: (number);
  invalidDefinitionType: (number);
  invalidMainFile: (number);
  missingCamera: (number);
  missingScene: (number);
  pathNotFound: (number);
  variantSetNotFound: (number);
  variantValueNotFound: (number);
}

export interface OrderUploadFormat {
  type: (('CSV' | 'Excel'));
}

export interface OrderParsingOrderParser {
  _id: (string);
  name: (string);
}

export interface OrderParsingOrderParserVersion {
  _id: (string);
  version: (string);
}

export interface OrderParsingStateHistory {
  state: (('Analyzing' | 'InProgress' | 'Finished' | 'Error'));
  date: (string);
}

export interface OrderParsing {
  _id: (string);
  identifierColumn: (string);
  state: (('Analyzing' | 'InProgress' | 'Finished' | 'Error'));
  stateHistory: (OrderParsingStateHistory)[];
  createdAt: (string);
  createdBy: (string);
  createdBy$?: (User);
  chunks: (number);
  finishedChunks: (number);
  finishedAt?: (string);
  error?: (('MissingColumns' | 'Unknown'));
  order: (string);
  orderParser: (string);
  orderParser$?: (OrderParsingOrderParser);
  duplicateMode: (('KeepBoth' | 'KeepNew' | 'KeepOld'));
  ignoreOrderLineStates?: (('WaitingForPrerequisites' | 'NoOutputsDefined' | 'DuplicatedOutputNames' | 'Reviewing' | 'Downloaded' | 'PrerequisitesFulfilled' | 'WaitingForProcessing' | 'Queued' | 'Processing' | 'WaitingForQC' | 'Done' | 'Rejected' | 'Error' | 'Locked' | 'Deferred'))[];
  orderUpload: (string);
  orderParsingResult: (OrderParsingResult);
  columnsRequired: (string)[];
  columnsMissing: (string)[];
  columnsRead: (string)[];
  linesRead: (number);
  identifierMissing: (number);
  duplicatedIdentifier: (number);
  table?: (string);
  orderUploadFormat: (OrderUploadFormat);
}

export interface OrderParsingChunk {
  _id: (string);
  orderParsing: (string);
  startedAt?: (string);
  finishedAt?: (string);
  error?: (('MissingColumns' | 'Unknown'));
  errorMessage?: (string);
  startingPosition: (number);
  length: (number);
  orderParsingResult: (OrderParsingResult);
}

export interface OrderSubtaskSubtaskContext {
  
}

export interface OrderSubtask {
  asset: (string);
  hash: (string);
  numberOfOrderLines: (number);
  subtask: (string);
  subtaskType: (('CreateAssetVersion' | 'CreateVariantSet' | 'CreateVariantValue' | 'CreateCamera' | 'Manual'));
  subtaskContext: (OrderSubtaskSubtaskContext);
  subtaskState: (('New' | 'InProgress' | 'Done'));
  task: (string);
  taskState: (('New' | 'InProgress' | 'Done'));
  assetType: (('material' | 'object' | 'scene' | 'prefab' | 'comfy'));
  assetName: (string);
  assetDisplayName: (string);
}

export interface OrderTranslationCondition {
  
}

export interface OrderTranslation {
  _id: (string);
  order: (string);
  condition?: (OrderTranslationCondition);
  columns: (string)[];
  searchValue: (string);
  replacementValue: (string);
}

export interface OrderUpload {
  _id: (string);
  order: (string);
  order$?: (Order);
  file?: (string);
  file$?: (File);
  createdAt: (string);
  createdBy: (string);
  createdBy$?: (User);
  finishedAt?: (string);
  state: (('InProgress' | 'Parsing' | 'Finished' | 'Uploading' | 'Error'));
  orderParsings?: (string)[];
  orderParsings$?: (OrderParsing)[];
}

export interface OrderUploadFile extends File {
  type: (('OrderUploadFile'));
  orderUpload: (string);
}

export interface OrderUploadFormatCSV extends OrderUploadFormat {
  type: (('CSV'));
  delimiter: (string);
  textQualifier: (string);
  skipRows: (number);
}

export interface PackageComfyWorkflowInput {
  type: (('image' | 'model' | 'string' | 'number'));
  node: (string);
  field: (string);
  name?: (string);
}

export interface PackageComfyWorkflowOutput {
  type: (('image' | 'model'));
  node: (string);
  name?: (string);
}

export interface PackageComfyWorkflow {
  name?: (string);
  inputs: (PackageComfyWorkflowInput)[];
  outputs: (PackageComfyWorkflowOutput)[];
  workflowFile: (string);
  workflowApiFile: (string);
}

export interface PackageComfy {
  workflows?: (PackageComfyWorkflow)[];
}

export interface Package {
  asset: (string);
  version: (string);
  dependencies: ({[key: string]: string});
  devDependencies: ({[key: string]: string});
  devFiles: (string)[];
  ignoreFiles?: (string)[];
  mainFile?: (string);
  mainPrimPath?: (string);
  compositingFile?: (string);
  actions?: (PrefabAction)[];
  comfy?: (PackageComfy);
}

export interface Patch {
  type: (('Delete' | 'Up' | 'Down' | 'Push' | 'Set' | 'Splice'));
  id?: (string);
  jsonpath: (string);
  jsonpathParams?: ({[key: string]: string});
}

export interface DeletePatch extends Patch {
  type: (('Delete'));
}

export interface UpPatch extends Patch {
  type: (('Up'));
}

export interface DownPatch extends Patch {
  type: (('Down'));
}

export interface PushPatchValue {
  
}

export interface PushPatch extends Patch {
  type: (('Push'));
  value: (PushPatchValue);
}

export interface SetPatchValue {
  
}

export interface SetPatch extends Patch {
  type: (('Set'));
  value: (SetPatchValue);
}

export interface SplicePatchValue {
  
}

export interface SplicePatch extends Patch {
  type: (('Splice'));
  value?: (SplicePatchValue);
  index: (number);
  delete?: (number);
}

export interface ArnoldCommand extends Command {
  name: (string);
}

export interface ArnoldEngine extends Engine {
  type: (('Arnold'));
  commands: (ArnoldCommand)[];
}

export interface HoudiniCommand extends Command {
  name: (string);
}

export interface HoudiniEngine extends Engine {
  type: (('Houdini'));
  commands: (HoudiniCommand)[];
}

export interface SourceTarget {
  source: (string);
  target: (string);
  length: (number);
  mimeType: (string);
  md5?: (string);
}

export interface JobsStatistic {
  count: (number);
  calculatedAt: (string);
  queued: (number);
  downloading: (number);
  running: (number);
  success: (number);
  error: (number);
  canceled: (number);
}

export interface OrdersStatistic {
  count: (number);
  calculatedAt: (string);
  new: (number);
  inProgress: (number);
  finished: (number);
}

export interface TasksStatistic {
  count: (number);
  calculatedAt: (string);
  new: (number);
  inProgress: (number);
  done: (number);
}

export interface AgencyStatistic {
  _id: (string);
  agency: (string);
  calculatedAt: (string);
  jobs: (JobsStatistic);
  orders: (OrdersStatistic);
  orderLines: (OrderLinesStatistic);
  tasks: (TasksStatistic);
  assetVersions: (AssetVersionsStatistic);
  jobStatsPerDay?: ({[key: string]: JobsStatistic});
}

export interface AgencyTags {
  agency: (string);
  assetTags?: (string)[];
  taskTags?: (string)[];
  orderTags?: (string)[];
}

export interface SubtaskStateHistory {
  state: (('New' | 'InProgress' | 'Done'));
  date: (string);
  user: (string);
}

export interface SubtaskAssigneeHistory {
  user: (string);
  assignee?: (string);
  date: (string);
}

export interface SubtaskContractHistory {
  user: (string);
  contract?: (string);
  contractor?: (string);
  date: (string);
}

export interface SubtaskContext {
  
}

export interface Subtask {
  _id: (string);
  type: (('CreateAssetVersion' | 'CreateVariantSet' | 'CreateVariantValue' | 'CreateCamera' | 'Manual'));
  state: (('New' | 'InProgress' | 'Done'));
  stateHistory: (SubtaskStateHistory)[];
  assignee?: (string | null);
  assignee$?: (User);
  assigneeHistory: (SubtaskAssigneeHistory)[];
  contract?: (string | null);
  contract$?: (Contract);
  contractHistory: (SubtaskContractHistory)[];
  contractor?: (string | null);
  contractor$?: (Agency);
  context: (SubtaskContext);
  generated: (boolean);
  createdAt: (string);
  createdBy: (string);
  createdBy$?: (User);
  deletedAt?: (string);
  deletedBy?: (string);
  task?: (string);
}

export interface AssetSubtaskOrderLine {
  order: (string);
  identifier: (string);
}

export interface AssetSubtask extends Subtask {
  type: (('CreateAssetVersion' | 'CreateVariantSet' | 'CreateVariantValue' | 'CreateCamera' | 'Manual'));
  asset: (string);
  asset$?: (Asset);
  resolvedByAssetVersion?: (string);
  numberOfOrderLines: (number);
  orderLines?: (AssetSubtaskOrderLine)[];
}

export interface TaskStateHistory {
  state: (('New' | 'InProgress' | 'Done'));
  date: (string);
  user?: (string);
}

export interface SubtasksStatistic {
  count: (number);
  calculatedAt: (string);
  new: (number);
  inProgress: (number);
  done: (number);
}

export interface TaskSubtask {
  _id: (string);
  state: (('New' | 'InProgress' | 'Done'));
  assignee?: (string | null);
  contract?: (string | null);
  contractor?: (string | null);
}

export interface Task {
  _id: (string);
  type: (('AssetTask'));
  name: (string);
  description: (string);
  state: (('New' | 'InProgress' | 'Done'));
  stateHistory: (TaskStateHistory)[];
  subtasksStatistic: (SubtasksStatistic);
  openSubtasks: (TaskSubtask)[];
  createdAt: (string);
  createdBy: (string);
  createdBy$?: (User);
  agency: (string);
  agency$?: (Agency);
  assignees: (string)[];
  assignees$?: (User)[];
  contracts: (string)[];
  contracts$?: (Contract)[];
  contractors: (string)[];
  contractors$?: (Agency)[];
  deletedAt?: (string);
  deletedBy?: (string);
  tags: (Tag)[];
}

export interface AssetTask extends Task {
  _id: (string);
  type: (('AssetTask'));
  asset: (string);
  asset$?: (Asset);
  subtasks$?: (AssetSubtask)[];
}

export interface CreateAssetVersionSubtaskContext {
  assetVersionName: (string);
}

export interface CreateAssetVersionSubtask extends AssetSubtask {
  type: (('CreateAssetVersion'));
  context: (CreateAssetVersionSubtaskContext);
}

export interface CreateCameraSubtaskContext {
  assetVersionName: (string);
  camera: (string);
}

export interface CreateCameraSubtask extends AssetSubtask {
  type: (('CreateCamera'));
  context: (CreateCameraSubtaskContext);
}

export interface CreateVariantSetSubtaskContext {
  assetVersionName: (string);
  variantSet: (string);
  path: (string);
}

export interface CreateVariantSetSubtask extends AssetSubtask {
  type: (('CreateVariantSet'));
  context: (CreateVariantSetSubtaskContext);
}

export interface CreateVariantValueSubtaskContext {
  assetVersionName: (string);
  variantSet: (string);
  variantValue: (string);
  path: (string);
}

export interface CreateVariantValueSubtask extends AssetSubtask {
  type: (('CreateVariantValue'));
  context: (CreateVariantValueSubtaskContext);
}

export interface ManualSubtaskContext {
  id: (string);
}

export interface ManualSubtask extends AssetSubtask {
  type: (('Manual'));
  context: (ManualSubtaskContext);
  name: (string);
  description: (string);
}

export interface SubtaskOrderLine {
  asset: (string);
  type: (('CreateAssetVersion' | 'CreateVariantSet' | 'CreateVariantValue' | 'CreateCamera' | 'Manual'));
  hash: (string);
  order: (string);
  agency: (string);
  identifier: (string);
  orderParsing: (string);
  createdAt: (string);
  createdBy: (string);
  deletedAt?: (string);
}

export interface TaskAttachmentFile extends File {
  type: (('TaskAttachmentFile'));
  createdAt: (string);
  createdBy: (string);
  createdBy$?: (User);
  task: (string);
  deletedAt?: (string);
  deletedBy?: (string);
}

export interface Upload {
  _id: (string);
  asset: (string);
  user: (string);
  agency: (string);
  assetVersion: (string);
  previousAssetVersion: (string);
  state: (('Uploading' | 'Error' | 'Finished'));
  package: (Package);
  uploadedAt: (string);
  errorAt: (string);
  finishedAt: (string);
  newFiles: (string)[];
  linkedFiles: (string)[];
}

export interface AbstractAuthUser {
  type: (('AuthUser' | 'ServiceAuthUser'));
  _id: (string);
  isActive: (boolean);
  createdAt: (string);
  updatedAt?: (string);
  deletedAt?: (string);
  deletedBy?: (string);
}

export interface Group {
  type: (('CustomGroup' | 'ContractorGroup' | 'AgencyGroup'));
  _id: (string);
  name: (string);
  description: (string);
  users: (string)[];
  agency: (string);
}

export interface CustomGroup extends Group {
  type: (('CustomGroup'));
  createdAt: (string);
  createdBy: (string);
  deletedAt?: (string);
  deletedBy?: (string);
}

export interface ContractorGroup extends Group {
  type: (('ContractorGroup'));
  contract: (string);
  validTo: (string);
}

export interface AgencyGroup extends Group {
  type: (('AgencyGroup'));
}

export interface Invitation {
  _id: (string);
  agency: (string);
  agency$?: (Agency);
  email: (string);
  roles: (string)[];
  roles$?: (Role)[];
  user?: (string);
  acceptedAt?: (string);
  declinedAt?: (string);
  createdAt: (string);
  createdBy: (string);
  createdBy$?: (User);
  deletedAt?: (string);
  deletedBy?: (string);
  deletedBy$?: (Agency);
}

export interface RBAC {
  _id: (string);
  resource: (string);
  user?: (string);
  group?: (string);
  roles: (string)[];
  roles$?: (Role)[];
  validTo?: (string);
  createdAt: (string);
  createdBy: (string);
  createdBy$?: (User);
  deletedAt?: (string);
  deletedBy?: (string);
  deletedBy$?: (Agency);
}

export interface Right {
  _id: (string);
  right: (string);
  name: (string);
  description: (string);
}

export interface ServiceAuthUserApiKeys {
  md5: (string);
  key: (string);
  preview: (string);
  expiresAt?: (string);
  deletedAt?: (string);
  deletedBy?: (string);
  createdAt: (string);
  createdBy: (string);
}

export interface ServiceAuthUser extends AbstractAuthUser {
  type: (('ServiceAuthUser'));
  apiKeys: (ServiceAuthUserApiKeys)[];
  createdBy: (string);
}

export interface ServiceUser extends AbstractUser {
  type: (('ServiceUser'));
  agency: (string);
  createdAt: (string);
  createdBy: (string);
  updatedAt: (string);
  updatedBy: (string);
}

export interface UserAgency {
  _id: (string);
  user: (string);
  user$?: (User);
  agency: (string);
  agency$?: (Agency);
  deletedAt?: (string);
  deletedBy?: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: (string);
  limit: (number);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: (string);
  thread: (number);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: (string);
  assetVersion: (string);
  previewSet: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: (string);
  assetVersion: (string);
  previewSet: (string);
  preview: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: (('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled'));
  renderer: (string);
  errorMessage?: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: (('downloading' | 'running'));
  percentage: (number);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: (('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled'));
  paths?: (string)[];
  code?: (number);
  errorMessage?: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: (('rendering' | 'compositing'));
  percentage: (number);
  currentJobOutputIndex: (number);
  totalJobOutputs: (number);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: (('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview'));
  title: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: (('UploadPreview'));
  length: (number);
  mimeType: (string);
  fileName: (string);
  md5: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: (('GeneratedPreview'));
  previewTemplate: (string);
  camera: (string);
  variants: (OutputTemplateVariant)[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: (string);
  assetType: (string);
  agency: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: (string)[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: (boolean);
  internal?: (boolean);
  onlyUsd?: (boolean);
  includeDependencies?: (boolean);
  existingDependencies?: (string)[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: (string);
  package: (Package);
  files: (FileInfo)[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: (string);
  user: (string);
  assetVersion?: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: (string);
  limit: (number);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: (string);
  thread: (number);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: (string);
  assetVersion: (string);
  previewSet: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: (string);
  assetVersion: (string);
  previewSet: (string);
  preview: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: (('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled'));
  renderer: (string);
  errorMessage?: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: (('downloading' | 'running'));
  percentage: (number);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: (('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled'));
  paths?: (string)[];
  code?: (number);
  errorMessage?: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: (('rendering' | 'compositing'));
  percentage: (number);
  currentJobOutputIndex: (number);
  totalJobOutputs: (number);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: (('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview'));
  title: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: (('UploadPreview'));
  length: (number);
  mimeType: (string);
  fileName: (string);
  md5: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: (('GeneratedPreview'));
  previewTemplate: (string);
  camera: (string);
  variants: (OutputTemplateVariant)[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: (string);
  limit: (number);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: (string);
  thread: (number);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: (string);
  assetVersion: (string);
  previewSet: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: (string);
  assetVersion: (string);
  previewSet: (string);
  preview: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: (('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled'));
  renderer: (string);
  errorMessage?: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: (('downloading' | 'running'));
  percentage: (number);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: (('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled'));
  paths?: (string)[];
  code?: (number);
  errorMessage?: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: (('rendering' | 'compositing'));
  percentage: (number);
  currentJobOutputIndex: (number);
  totalJobOutputs: (number);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: (('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview'));
  title: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: (('UploadPreview'));
  length: (number);
  mimeType: (string);
  fileName: (string);
  md5: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: (('GeneratedPreview'));
  previewTemplate: (string);
  camera: (string);
  variants: (OutputTemplateVariant)[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: (string);
  assetType: (string);
  agency: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: (string)[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: (boolean);
  internal?: (boolean);
  onlyUsd?: (boolean);
  includeDependencies?: (boolean);
  existingDependencies?: (string)[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: (string);
  package: (Package);
  files: (FileInfo)[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: (string);
  user: (string);
  assetVersion?: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: (AssetVersion);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetBody {
  displayName?: (string);
  user: (string);
  tags?: (string)[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: (string);
  assetType: (string);
  agency: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: (string)[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: (string)[];
  referencedPath: (string)[];
  referencedAssetVersion: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: (AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences)[];
  variantSets: (VariantSet)[];
  materials: (Material)[];
  cameras: (Camera)[];
  targets: (Target)[];
  usdErrors: (string)[];
  error?: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: (boolean);
  internal?: (boolean);
  onlyUsd?: (boolean);
  includeDependencies?: (boolean);
  existingDependencies?: (string)[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: (string);
  package: (Package);
  files: (FileInfo)[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: (string);
  user: (string);
  assetVersion?: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsPostJobBody {
  user: (string);
  tags?: (string)[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: (string);
  limit: (number);
}

export interface AgenciesJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: (string);
  thread: (number);
}

export interface AgenciesJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: (string);
  assetVersion: (string);
  previewSet: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: (string);
  assetVersion: (string);
  previewSet: (string);
  preview: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: (('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled'));
  renderer: (string);
  errorMessage?: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: (('downloading' | 'running'));
  percentage: (number);
}

export interface AgenciesJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: (('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled'));
  paths?: (string)[];
  code?: (number);
  errorMessage?: (string);
}

export interface AgenciesJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: (('rendering' | 'compositing'));
  percentage: (number);
  currentJobOutputIndex: (number);
  totalJobOutputs: (number);
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: (('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview'));
  title: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: (('UploadPreview'));
  length: (number);
  mimeType: (string);
  fileName: (string);
  md5: (string);
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: (('GeneratedPreview'));
  previewTemplate: (string);
  camera: (string);
  variants: (OutputTemplateVariant)[];
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: (string);
  limit: (number);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: (string);
  thread: (number);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: (string);
  assetVersion: (string);
  previewSet: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: (string);
  assetVersion: (string);
  previewSet: (string);
  preview: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: (('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled'));
  renderer: (string);
  errorMessage?: (string);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: (('downloading' | 'running'));
  percentage: (number);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: (('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled'));
  paths?: (string)[];
  code?: (number);
  errorMessage?: (string);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: (('rendering' | 'compositing'));
  percentage: (number);
  currentJobOutputIndex: (number);
  totalJobOutputs: (number);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: (('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview'));
  title: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: (('UploadPreview'));
  length: (number);
  mimeType: (string);
  fileName: (string);
  md5: (string);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: (('GeneratedPreview'));
  previewTemplate: (string);
  camera: (string);
  variants: (OutputTemplateVariant)[];
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: (string);
  assetType: (string);
  agency: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: (string)[];
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: (boolean);
  internal?: (boolean);
  onlyUsd?: (boolean);
  includeDependencies?: (boolean);
  existingDependencies?: (string)[];
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: (string);
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: (string);
  package: (Package);
  files: (FileInfo)[];
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: (string);
  user: (string);
  assetVersion?: (string);
}

export interface AgenciesJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: (AssetVersion);
}

export interface AgenciesJobsAssetsJobsAssetsTasksPostTaskSubtasksBody {
  user: (string);
  name: (string);
  description: (string);
  assignee?: (string | null);
  contract?: (string | null);
}

export interface AgenciesJobsAssetsJobsAssetsTasksPostTaskCommentsBody {
  message: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsTasksPostTaskBody {
  user: (string);
  tags?: (string)[];
}

export interface AgenciesJobsAssetsJobsAssetsTasksPostTaskAttachmentsBody {
  user: (string);
  length: (number);
  mimeType: (string);
  fileName: (string);
}

export interface AgenciesJobsAssetsJobsAssetsTasksPostTasksAggregateBody {
  assets: (string)[];
}

export interface AgenciesJobsAssetsJobsAssetsTasksPostTaskStateBody {
  state: (('New' | 'InProgress' | 'Done'));
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsTasksPostTaskSubtaskBody {
  user: (string);
  state?: (('New' | 'InProgress' | 'Done'));
  assignee?: (string | null);
  contract?: (string | null);
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetBody {
  displayName?: (string);
  user: (string);
  tags?: (string)[];
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetsBody {
  name: (string);
  assetType: (string);
  agency: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: (string)[];
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: (string)[];
  referencedPath: (string)[];
  referencedAssetVersion: (string);
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: (number)[];
  max: (number)[];
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: (AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences)[];
  variantSets: (VariantSet)[];
  materials: (Material)[];
  cameras: (Camera)[];
  targets: (Target)[];
  boundingBox?: (AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox);
  usdErrors: (string)[];
  error?: (string);
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: (boolean);
  internal?: (boolean);
  onlyUsd?: (boolean);
  includeDependencies?: (boolean);
  onlyDependencies?: (boolean);
  existingDependencies?: (string)[];
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: (string);
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: (string);
  package: (Package);
  files: (FileInfo)[];
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: (string);
  user: (string);
  assetVersion?: (string);
}

export interface AgenciesJobsAssetsJobsMailPostInvitationBody {
  invitation: (string);
}

export interface AgenciesJobsAssetsJobsMailPostVerificationBody {
  user: (string);
}

export interface AgenciesJobsAssetsJobsMailPostJobErrorBody {
  job: (string);
}

export interface AgenciesJobsAssetsJobsPostJobBody {
  user: (string);
  tags?: (string)[];
}

export interface AgenciesJobsAssetsJobsPostJobsGrepBody {
  renderer: (string);
  limit: (number);
}

export interface AgenciesJobsAssetsJobsPostJobStartBody {
  renderer: (string);
  thread: (number);
}

export interface AgenciesJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsPostJobsPreviewSetBody {
  asset: (string);
  assetVersion: (string);
  previewSet: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsPostJobsPreviewBody {
  asset: (string);
  assetVersion: (string);
  previewSet: (string);
  preview: (string);
  user: (string);
}

export interface AgenciesJobsAssetsJobsPostJobStateBody {
  state: (('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled'));
  renderer: (string);
  errorMessage?: (string);
}

export interface AgenciesJobsAssetsJobsPostJobProgressBody {
  step: (('downloading' | 'running'));
  percentage: (number);
}

export interface AgenciesJobsAssetsJobsPostJobJobOutputStateBody {
  state: (('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled'));
  paths?: (string)[];
  code?: (number);
  errorMessage?: (string);
}

export interface AgenciesJobsAssetsJobsPostJobJobOutputProgressBody {
  step: (('rendering' | 'compositing'));
  percentage: (number);
  currentJobOutputIndex: (number);
  totalJobOutputs: (number);
}

export interface AgenciesJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: (('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview'));
  title: (string);
  user: (string);
}

export interface AgenciesJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: (('UploadPreview'));
  length: (number);
  mimeType: (string);
  fileName: (string);
  md5: (string);
}

export interface AgenciesJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: (('GeneratedPreview'));
  previewTemplate: (string);
  camera: (string);
  variants: (OutputTemplateVariant)[];
}

export interface AgenciesJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: (string);
  limit: (number);
}

export interface AgenciesJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: (string);
  thread: (number);
}

export interface AgenciesJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: (string);
  user: (string);
}

export interface AgenciesJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: (string);
  assetVersion: (string);
  previewSet: (string);
  user: (string);
}

export interface AgenciesJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: (string);
  assetVersion: (string);
  previewSet: (string);
  preview: (string);
  user: (string);
}

export interface AgenciesJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: (('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled'));
  renderer: (string);
  errorMessage?: (string);
}

export interface AgenciesJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: (('downloading' | 'running'));
  percentage: (number);
}

export interface AgenciesJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: (('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled'));
  paths?: (string)[];
  code?: (number);
  errorMessage?: (string);
}

export interface AgenciesJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: (('rendering' | 'compositing'));
  percentage: (number);
  currentJobOutputIndex: (number);
  totalJobOutputs: (number);
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: (('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview'));
  title: (string);
  user: (string);
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: (('UploadPreview'));
  length: (number);
  mimeType: (string);
  fileName: (string);
  md5: (string);
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: (('GeneratedPreview'));
  previewTemplate: (string);
  camera: (string);
  variants: (OutputTemplateVariant)[];
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetsBody {
  name: (string);
  assetType: (string);
  agency: (string);
  user: (string);
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: (string);
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: (string);
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: (string);
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: (string);
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: (string);
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: (string)[];
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: (boolean);
  internal?: (boolean);
  onlyUsd?: (boolean);
  includeDependencies?: (boolean);
  existingDependencies?: (string)[];
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: (string);
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: (string);
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: (string);
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: (string);
  user: (string);
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: (string);
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: (string);
  package: (Package);
  files: (FileInfo)[];
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: (string);
  user: (string);
  assetVersion?: (string);
}

export interface AgenciesJobsAssetsUsdPostAnalyzeBody {
  assetVersion: (AssetVersion);
}

export interface AgenciesJobsAssetsTasksPostTaskSubtasksBody {
  user: (string);
  name: (string);
  description: (string);
  assignee?: (string | null);
  contract?: (string | null);
}

export interface AgenciesJobsAssetsTasksPostTaskCommentsBody {
  message: (string);
  user: (string);
}

export interface AgenciesJobsAssetsTasksPostTaskBody {
  user: (string);
  tags?: (string)[];
}

export interface AgenciesJobsAssetsTasksPostTaskAttachmentsBody {
  user: (string);
  length: (number);
  mimeType: (string);
  fileName: (string);
}

export interface AgenciesJobsAssetsTasksPostTasksAggregateBody {
  assets: (string)[];
}

export interface AgenciesJobsAssetsTasksPostTaskStateBody {
  state: (('New' | 'InProgress' | 'Done'));
  user: (string);
}

export interface AgenciesJobsAssetsTasksPostTaskAttachmentDeleteBody {
  user: (string);
}

export interface AgenciesJobsAssetsTasksPostTaskSubtaskBody {
  user: (string);
  state?: (('New' | 'InProgress' | 'Done'));
  assignee?: (string | null);
  contract?: (string | null);
}

export interface AgenciesJobsAssetsPostAssetBody {
  displayName?: (string);
  user: (string);
  tags?: (string)[];
}

export interface AgenciesJobsAssetsPostAssetsBody {
  name: (string);
  assetType: (string);
  agency: (string);
  user: (string);
}

export interface AgenciesJobsAssetsPostAssetAssetVersionsBody {
  user: (string);
}

export interface AgenciesJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: (string);
}

export interface AgenciesJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: (string);
}

export interface AgenciesJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: (string);
}

export interface AgenciesJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: (string);
}

export interface AgenciesJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: (string)[];
}

export interface AgenciesJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: (string)[];
  referencedPath: (string)[];
  referencedAssetVersion: (string);
}

export interface AgenciesJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: (number)[];
  max: (number)[];
}

export interface AgenciesJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: (AgenciesJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences)[];
  variantSets: (VariantSet)[];
  materials: (Material)[];
  cameras: (Camera)[];
  targets: (Target)[];
  boundingBox?: (AgenciesJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox);
  usdErrors: (string)[];
  error?: (string);
}

export interface AgenciesJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: (boolean);
  internal?: (boolean);
  onlyUsd?: (boolean);
  includeDependencies?: (boolean);
  onlyDependencies?: (boolean);
  existingDependencies?: (string)[];
}

export interface AgenciesJobsAssetsPostAssetAssetVersionPublishBody {
  user: (string);
}

export interface AgenciesJobsAssetsPostAssetAssetVersionRejectBody {
  user: (string);
}

export interface AgenciesJobsAssetsPostAssetAssetVersionSubmitBody {
  user: (string);
}

export interface AgenciesJobsAssetsPostAssetAssetVersionRenderBody {
  md5: (string);
  user: (string);
}

export interface AgenciesJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: (string);
}

export interface AgenciesJobsAssetsPostAssetAssetVersionUploadsBody {
  user: (string);
  package: (Package);
  files: (FileInfo)[];
}

export interface AgenciesJobsAssetsPostAssetCommentsBody {
  message: (string);
  user: (string);
  assetVersion?: (string);
}

export interface AgenciesJobsMailPostInvitationBody {
  invitation: (string);
}

export interface AgenciesJobsMailPostVerificationBody {
  user: (string);
}

export interface AgenciesJobsMailPostJobErrorBody {
  job: (string);
}

export interface AgenciesJobsPostJobBody {
  user: (string);
  tags?: (string)[];
}

export interface AgenciesJobsPostJobsGrepBody {
  renderer: (string);
  limit: (number);
}

export interface AgenciesJobsPostJobStartBody {
  renderer: (string);
  thread: (number);
}

export interface AgenciesJobsPostJobsAssetVersionBody {
  assetVersion: (string);
  user: (string);
}

export interface AgenciesJobsPostJobsPreviewSetBody {
  asset: (string);
  assetVersion: (string);
  previewSet: (string);
  user: (string);
}

export interface AgenciesJobsPostJobsPreviewBody {
  asset: (string);
  assetVersion: (string);
  previewSet: (string);
  preview: (string);
  user: (string);
}

export interface AgenciesJobsPostJobStateBody {
  state: (('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled'));
  renderer: (string);
  errorMessage?: (string);
}

export interface AgenciesJobsPostJobProgressBody {
  step: (('downloading' | 'running'));
  percentage: (number);
}

export interface AgenciesJobsPostJobJobOutputStateBody {
  state: (('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled'));
  paths?: (string)[];
  code?: (number);
  errorMessage?: (string);
}

export interface AgenciesJobsPostJobJobOutputProgressBody {
  step: (('rendering' | 'compositing'));
  percentage: (number);
  currentJobOutputIndex: (number);
  totalJobOutputs: (number);
}

export interface AgenciesMailPostInvitationBody {
  invitation: (string);
}

export interface AgenciesMailPostVerificationBody {
  user: (string);
}

export interface AgenciesMailPostJobErrorBody {
  job: (string);
}

export interface AgenciesPostAgenciesBody {
  user: (string);
  name: (string);
  displayName: (string);
}

export interface AgenciesPostAgencyContractsBody {
  user: (string);
  agency: (string);
  contractor: (string);
  name: (string);
  description: (string);
  validTo: (string);
}

export interface AgenciesPostAgencyGroupsBody {
  name: (string);
  description: (string);
  user: (string);
}

export interface AgenciesPostAgencyInvitationsBody {
  email: (string);
  roles: (string)[];
  user: (string);
}

export interface AgenciesPostAgencyServiceUsersBody {
  displayName: (string);
  user: (string);
}

export interface AgenciesPostAgencyServiceUserBody {
  displayName: (string);
  user: (string);
}

export interface AgenciesPostAgencyServiceUserApiKeysBody {
  user: (string);
}

export interface AgenciesPostAgencyContractAcceptBody {
  user: (string);
}

export interface AgenciesPostAgencyContractDeclineBody {
  user: (string);
}

export interface AgenciesPostAgencyGroupBody {
  name?: (string);
  description?: (string);
  roles?: (string)[];
  user: (string);
}

export interface AgenciesPostAgencyInvitationBody {
  roles?: (string)[];
  user: (string);
}

export interface AgenciesPostAgencyInvitationDeleteBody {
  user: (string);
}

export interface AgenciesPostAgencyServiceUserDeleteBody {
  user: (string);
}

export interface AgenciesPostAgencyServiceUserApiKeyDeleteBody {
  user: (string);
}

export interface GraphsPostGraphGraphVersionSampleBodyPlain {
  type: (('Plain'));
  user: (string);
  name: (string);
  values: ({[key: string]: string});
  fileInputs: ({[key: string]: SampleFileInput});
}

export interface GraphsPostGraphGraphVersionSampleBodyOrderLine {
  type: (('OrderLine'));
  user: (string);
  name: (string);
  order: (string);
  identifier: (string);
  output: (number);
}

export interface GraphsPostGraphGraphVersionSamplesBodyPlain {
  type: (('Plain'));
  user: (string);
  name: (string);
  values: ({[key: string]: string});
  fileInputs: ({[key: string]: SampleFileInput});
}

export interface GraphsPostGraphGraphVersionSamplesBodyOrderLine {
  type: (('OrderLine'));
  user: (string);
  name: (string);
  order: (string);
  identifier: (string);
  output: (number);
}

export interface GraphsPostGraphFilesBody {
  user: (string);
  agency: (string);
  graphFolder?: (string | null);
  length: (number);
  mimeType: (string);
  fileName: (string);
}

export interface GraphsPostGraphFileDeleteBody {
  user: (string);
}

export interface GraphsPostGraphFolderBody {
  user: (string);
  parent?: (string);
  name?: (string);
}

export interface GraphsPostGraphFoldersBody {
  user: (string);
  agency: (string);
  name: (string);
  parent?: (string);
}

export interface GraphsPostGraphFolderDeleteBody {
  user: (string);
}

export interface GraphsPostGraphBody {
  user: (string);
  name?: (string);
  graphFolder?: (string);
}

export interface GraphsPostGraphsBody {
  user: (string);
  agency: (string);
  name: (string);
  graphFolder?: (string);
}

export interface GraphsPostGraphGraphVersionsBody {
  user: (string);
  parent?: (string);
}

export interface GraphsPostGraphGraphVersionBody {
  user: (string);
  change: (number);
  assemblingTemplate: (AssemblingTemplate);
  notes: (GraphVersionNote)[];
}

export interface GraphsPostGraphGraphVersionSampleDebugBody {
  user: (string);
  restartJob?: (string);
  pauseAt?: (string);
  clearCache?: (boolean);
}

export interface GraphsPostGraphGraphVersionSampleDeleteBody {
  user: (string);
}

export interface GraphsPostGraphGraphVersionSaveBody {
  user: (string);
  change: (number);
}

export interface GraphsPostGraphGraphVersionExecuteBody {
  user: (string);
  values?: ({[key: string]: string});
  outputs?: (string)[];
  clearCache?: (boolean);
}

export interface PostAgencyGraphGraphVersionSampleBodyPlain {
  type: (('Plain'));
  name: (string);
  values: ({[key: string]: string});
  fileInputs: ({[key: string]: SampleFileInput});
}

export interface PostAgencyGraphGraphVersionSampleBodyOrderLine {
  type: (('OrderLine'));
  name: (string);
  order: (string);
  identifier: (string);
  output: (number);
}

export interface PostAgencyGraphGraphVersionSamplesBodyPlain {
  type: (('Plain'));
  name: (string);
  values: ({[key: string]: string});
  fileInputs: ({[key: string]: SampleFileInput});
}

export interface PostAgencyGraphGraphVersionSamplesBodyOrderLine {
  type: (('OrderLine'));
  name: (string);
  order: (string);
  identifier: (string);
  output: (number);
}

export interface AssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: (('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview'));
  title: (string);
  user: (string);
}

export interface AssetsPostAssetBody {
  displayName?: (string);
  user: (string);
  tags?: (string)[];
}

export interface AssetsPostAssetsBody {
  name: (string);
  assetType: (string);
  agency: (string);
  user: (string);
}

export interface AssetsPostAssetAssetVersionsBody {
  user: (string);
}

export interface AssetsPostAssetAssetVersionPreviewSetsBody {
  user: (string);
}

export interface AssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections {
  variantSelections: (VariantSelection)[];
  reason?: (string);
}

export interface AssetsPostAssetAssetVersionBlockedVariantSelectionsBody {
  blockedVariantSelections?: (AssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections)[];
  fromAssetVersion?: (string);
  user: (string);
}

export interface AssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: (string);
}

export interface AssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: (string);
}

export interface AssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: (string);
}

export interface AssetsPostAssetAssetVersionBlockedVariantSelectionsDeleteBody {
  blockedVariantSelections: (string)[];
  user: (string);
}

export interface AssetsPostAssetAssetVersionDependenciesBody {
  dependencies: (string)[];
}

export interface AssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: (string)[];
  referencedPath: (string)[];
  referencedAssetVersion: (string);
}

export interface AssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: (number)[];
  max: (number)[];
}

export interface AssetsPostAssetAssetVersionAnalyzationResultBody {
  references: (AssetsPostAssetAssetVersionAnalyzationResultBodyReferences)[];
  variantSets: (VariantSet)[];
  cameras: (Camera)[];
  targets: (Target)[];
  boundingBox?: (AssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox);
  outliner?: (Outliner);
  usdErrors: (string)[];
  error?: (string);
}

export interface AssetsPostAssetAssetVersionDownloadsBody {
  forDev: (boolean);
  internal?: (boolean);
  onlyUsd?: (boolean);
  includeDependencies?: (boolean);
  onlyDependencies?: (boolean);
  existingDependencies?: (string)[];
}

export interface AssetsPostAssetAssetVersionPublishBody {
  user: (string);
}

export interface AssetsPostAssetAssetVersionRejectBody {
  user: (string);
}

export interface AssetsPostAssetAssetVersionSubmitBody {
  user: (string);
}

export interface AssetsPostAssetAssetVersionRenderBody {
  md5: (string);
  user: (string);
}

export interface AssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: (string);
}

export interface AssetsPostAssetAssetVersionUploadsBody {
  user: (string);
  package: (Package);
  files: (FileInfo)[];
}

export interface AssetsPostAssetCommentsBody {
  message: (string);
  user: (string);
  assetVersion?: (string);
}

export interface PostAssetAssetVersionPreviewSetPreviewsBody {
  type: (('UploadPreview' | 'GeneratedPreview'));
  title: (string);
}

export interface PostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview extends PostAssetAssetVersionPreviewSetPreviewsBody {
  type: (('UploadPreview'));
  length: (number);
  mimeType: (string);
  fileName: (string);
  md5: (string);
}

export interface PostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview extends PostAssetAssetVersionPreviewSetPreviewsBody {
  type: (('GeneratedPreview'));
  previewTemplate: (string);
  camera: (string);
  variants: (OutputTemplateVariant)[];
}

export interface JobsPostJobsBody {
  user: (string);
  agency: (string);
  assemblings: (Assembling)[];
  pauseAt?: (StepHash);
  clearCache?: (boolean);
  assignJobNumber?: (boolean);
  executionPlan?: (StepExecution)[];
}

export interface JobsPostJobBody {
  user: (string);
  tags?: (string)[];
}

export interface JobsPostJobsGrepBodyCurrentSteps {
  stepType: (('Basic_Or_v1' | 'Basic_Graph_v1' | 'Basic_GraphFiles_v1' | 'Prefab_Houdini_19_5_534_Hython_v1' | 'Prefab_Houdini_20_0_590_Hython_v1' | 'Assembling_Default_v1' | 'Rendering_Houdini_19_5_534_Arnold_v1' | 'Rendering_Houdini_20_0_590_Arnold_v1' | 'Compositing_Houdini_19_5_534_Hython_v1' | 'Compositing_Houdini_20_0_590_Hython_v1' | 'Compositing_Houdini_20_0_590_Hoiiotool_v1' | 'Utils_DownloadFile_v1' | 'Utils_ConvertImage_v1' | 'Utils_MontageImage_v1' | 'Utils_UsdAnalyzer_v1' | 'Order_Outputs_v1' | 'GenAI_LoRATraining_v1' | 'GenAI_Comfy_v1'));
  hash: (string);
  job: (string);
}

export interface JobsPostJobsGrepBody {
  renderer: (string);
  limit: (number);
  currentJobs: (string)[];
  currentSteps: (JobsPostJobsGrepBodyCurrentSteps)[];
}

export interface JobsPostJobStartBody {
  renderer: (string);
}

export interface JobsPostJobCancelBody {
  user: (string);
}

export interface JobsPostJobPauseBody {
  stepHash?: (StepHash);
  user: (string);
}

export interface JobsPostJobContinueBody {
  user: (string);
  pauseAt?: (StepHash);
}

export interface JobsPostJobFinishedBody {
  renderer: (string);
  error?: (string);
}

export interface JobsPostJobDeferBody {
  renderer: (string);
  nextStep: (StepHash);
}

export interface JobsPostJobRestartBody {
  user: (string);
  assemblings?: (Assembling)[];
  pauseAt?: (StepHash);
  clearCache?: (boolean);
  executionPlan?: (StepExecution)[];
}

export interface JobsPostJobsStepStartBody {
  renderer: (string);
  thread: (number);
  agency: (string);
  job: (string);
}

export interface JobsPostJobsStepFinishedBody {
  renderer: (string);
  thread: (number);
  agency: (string);
  error?: (string);
}

export interface JobsPostJobStepStartBody {
  renderer: (string);
  thread: (number);
}

export interface JobsPostJobStepFinishedBody {
  renderer: (string);
  thread: (number);
  error?: (string);
  job?: (string);
  nextStep?: (StepHash);
}

export interface JobsPostJobsAssetVersionBody {
  assetVersion: (string);
  user: (string);
}

export interface JobsPostJobsPreviewSetBody {
  asset: (string);
  assetVersion: (string);
  previewSet: (string);
  user: (string);
}

export interface JobsPostJobsUsdAnalyzerBody {
  asset: (string);
  assetVersion: (string);
}

export interface JobsPostJobsPreviewBody {
  asset: (string);
  assetVersion: (string);
  previewSet: (string);
  preview: (string);
  user: (string);
}

export interface JobsPostJobProgressBody {
  stepType: (string);
  stepHash: (string);
  jobProgress: (number);
  jobOutputProgress?: ({[key: string]: number});
  stepProgress: (number);
  stepIndex: (number);
  totalSteps: (number);
  preProcessingProgress: (number);
  postProcessingProgress: (number);
}

export interface OrdersPostOrderParsersBody {
  agency: (string);
  name: (string);
  identifierColumn: (string);
  outputColumns: (string)[];
  assemblyTemplate: (AssemblyTemplate);
}

export interface OrdersPostOrderBody {
  user: (string);
  tags?: (string)[];
}

export interface OrdersPostOrdersBody {
  agency: (string);
  user: (string);
  name: (string);
}

export interface OrdersPostOrderOrderLinesCompareBody {
  orderLines: (string)[];
}

export interface OrdersPostOrderOrderLinesDownloadBody {
  orderLines: (string)[];
}

export interface OrdersPostOrderOrderLinesLockBody {
  user: (string);
  lockName: (string);
  filter?: (Condition);
  identifiers?: (string)[];
}

export interface OrdersPostOrderOrderUploadParseBody {
  user: (string);
  identifierColumn: (string);
  orderParser?: (string);
  newOrderParser?: (BaseOrderParser);
  orderUploadFormat: (OrderUploadFormat);
  duplicateMode: (('KeepBoth' | 'KeepNew' | 'KeepOld'));
  ignoreOrderLineStates?: (('WaitingForPrerequisites' | 'NoOutputsDefined' | 'DuplicatedOutputNames' | 'Reviewing' | 'Downloaded' | 'PrerequisitesFulfilled' | 'WaitingForProcessing' | 'Queued' | 'Processing' | 'WaitingForQC' | 'Done' | 'Rejected' | 'Error' | 'Locked' | 'Deferred'))[];
}

export interface OrdersPostOrderOrderUploadValidateBody {
  orderUploadFormat: (OrderUploadFormat);
  orderParser: (BaseOrderParser);
  identifierColumn: (string);
  identifier: (string);
}

export interface OrdersPostOrderProcessBody {
  user: (string);
  orderLines?: (string)[];
  filter?: (Condition);
  searchTerm?: (string);
}

export interface OrdersPostOrderReviewBody {
  user: (string);
  orderLines?: (string)[];
  filter?: (Condition);
  searchTerm?: (string);
  ignoreBlockedVariantSelections?: (boolean);
}

export interface OrdersPostOrderCancelBody {
  user: (string);
  orderLines?: (string)[];
  filter?: (Condition);
}

export interface OrdersPostOrderAcceptBody {
  user: (string);
  orderLines?: (string)[];
  filter?: (Condition);
  searchTerm?: (string);
}

export interface OrdersPostOrderRejectBody {
  user: (string);
  orderLines?: (string)[];
  filter?: (Condition);
  searchTerm?: (string);
}

export interface OrdersPostOrderLocksDeleteBody {
  user: (string);
  lockIds: (string)[];
  restore: (boolean);
}

export interface OrdersPostOrderOrderUploadPreviewBody {
  format: (OrderUploadFormat);
  lines?: (number);
}

export interface OrdersPostOrderOrderUploadsBody {
  user: (string);
  length: (number);
  mimeType: (string);
  fileName: (string);
}

export interface OrdersPostOrderOrderLineAcceptBody {
  user: (string);
  next?: (boolean);
}

export interface OrdersPostOrderOrderLineRejectBody {
  user: (string);
  next?: (boolean);
}

export interface OrdersPostOrderOrderLineReviewBody {
  user: (string);
}

export interface OrdersPostOrderOrderLineProcessBody {
  user: (string);
}

export interface OrdersPostOrderOrderLineDownloadedBody {
  user: (string);
}

export interface OrdersPostOrderOrderLineCommentsBody {
  message: (string);
  user: (string);
}

export interface OrdersPostOrderDeleteBody {
  user: (string);
}

export interface TasksPostTaskSubtasksBody {
  user: (string);
  name: (string);
  description: (string);
  assignee?: (string | null);
  contract?: (string | null);
}

export interface TasksPostTaskCommentsBody {
  message: (string);
  user: (string);
}

export interface TasksPostTaskBody {
  user: (string);
  tags?: (string)[];
}

export interface TasksPostTaskAttachmentsBody {
  user: (string);
  length: (number);
  mimeType: (string);
  fileName: (string);
}

export interface TasksPostTasksAggregateBody {
  assets: (string)[];
}

export interface TasksPostTaskStateBody {
  state: (('New' | 'InProgress' | 'Done'));
  user: (string);
}

export interface TasksPostTaskAttachmentDeleteBody {
  user: (string);
}

export interface TasksPostTaskSubtaskBody {
  user: (string);
  state?: (('New' | 'InProgress' | 'Done'));
  assignee?: (string | null);
  contract?: (string | null);
}

export interface AgenciesGetAgenciesQuery {
  assetType?: (string);
}

export interface AgenciesGetAgencyJobsCountQuery {
  assetType?: (string);
}

export interface AgenciesPostAgencyInvitationDeleteQuery {
  lookups?: (string);
}

export interface AgenciesGetAgencyStatisticQuery {
  recalculate?: (boolean);
}

export interface GetAgenciesQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
}

export interface GetAgencyContractsQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
  valid?: (boolean);
}

export interface GetAgencyInvitationsQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
}

export interface GetAgencyRolesQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
}

export interface GetAgencyUsersQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
}

export interface GraphsGetGraphFileQuery {
  lookups?: (string);
}

export interface GraphsPostGraphFilesQuery {
  lookups?: (string);
}

export interface GraphsGetGraphFolderQuery {
  lookups?: (string);
}

export interface GraphsPostGraphQuery {
  lookups?: (string);
}

export interface GraphsPostGraphsQuery {
  lookups?: (string);
}

export interface GraphsPostGraphGraphVersionsQuery {
  lookups?: (string);
}

export interface GraphsGetGraphGraphVersionQuery {
  lookups?: (string);
  projection?: (string);
}

export interface GraphsPostGraphGraphVersionQuery {
  lookups?: (string);
}

export interface GraphsGetGraphGraphVersionSamplesQuery {
  lookups?: (string);
}

export interface GraphsPostGraphGraphVersionSamplesQuery {
  lookups?: (string);
}

export interface GraphsPostGraphGraphVersionSampleQuery {
  lookups?: (string);
}

export interface GraphsPostGraphGraphVersionSaveQuery {
  lookups?: (string);
}

export interface GetAgencyGraphFilesQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
  graphFolder?: (string);
  state?: (('Uploading' | 'Error' | 'Finished'))[];
  extension?: (string)[];
}

export interface GetAgencyGraphFoldersQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
  parent?: (string);
}

export interface GetAgencyGraphGraphVersionsQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
  state?: (('saved' | 'unsaved'));
}

export interface GetAgencyGraphsQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
  searchTerm?: (string);
  graphFolder?: (string);
}

export interface GetAgencyGraphsStepTemplatesQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
}

export interface AssetsGetAssetsPreviewTemplateQuery {
  lookups?: (string);
}

export interface AssetsGetAssetQuery {
  lookups?: (string);
}

export interface AssetsGetAssetAssetVersionQuery {
  lookups?: (string);
}

export interface AssetsGetAssetAssetVersionDependenciesQuery {
  includeDevDependencies?: (boolean);
}

export interface AssetsGetAssetAssetVersionFilesQuery {
  path?: (string);
  recursive?: (boolean);
}

export interface GetAssetAssetVersionBlockedVariantSelectionsQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
}

export interface GetAssetAssetVersionHistoryQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
}

export interface GetAssetAssetVersionPreviewSetsQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
}

export interface GetAssetAssetVersionVariantSetsQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
  types?: (('Local' | 'Referenced'))[];
}

export interface GetAssetAssetVersionsQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
  state?: (('Empty' | 'Uploading' | 'Analyzing' | 'Error' | 'Finished' | 'Submitted' | 'Rejected' | 'Publishing' | 'Published' | 'Obsolete'))[];
  agency?: (string);
}

export interface GetAssetCommentsQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
}

export interface GetAssetHistoryQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
  assetVersion?: (string);
  states?: (('Empty' | 'Uploading' | 'Analyzing' | 'Error' | 'Finished' | 'Submitted' | 'Rejected' | 'Publishing' | 'Published' | 'Obsolete'))[];
}

export interface GetAssetsQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
  publishedOnly?: (boolean);
  assetType?: (('material' | 'object' | 'scene' | 'prefab' | 'comfy'))[];
  searchTerm?: (string);
  agency?: (string);
}

export interface GetAssetsPreviewTemplatesQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
  assetType: (('material' | 'object' | 'scene' | 'prefab' | 'comfy'));
}

export interface JobsGetJobJobOutputStepOuputDownloadQuery {
  skip?: (number);
  limit?: (number);
}

export interface JobsGetJobDownloadQuery {
  downloadType?: (('Output' | 'OutputZip' | 'Assembly' | 'AssemblyWithDependencies' | 'AssemblyWithUsdOnly'));
  output?: (number);
}

export interface JobsGetJobQuery {
  lookups?: (string);
}

export interface JobsGetJobStepLogsQuery {
  skip?: (number);
  limit?: (number);
}

export interface GetJobsQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
  agency: (string);
}

export interface GetJobsCountQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
  agency: (string);
}

export interface OrdersGetOrderQuery {
  lookups?: (string);
}

export interface OrdersGetOrderOrderLineQuery {
  lookups?: (string);
}

export interface OrdersGetOrderParserQuery {
  lookups?: (string);
}

export interface OrdersGetOrderOrderUploadQuery {
  lookups?: (string);
}

export interface OrdersPostOrderOrderLineAcceptQuery {
  lookups?: (string);
}

export interface OrdersPostOrderOrderLineRejectQuery {
  lookups?: (string);
}

export interface OrdersPostOrderOrderLineReviewQuery {
  lookups?: (string);
}

export interface OrdersPostOrderOrderLineProcessQuery {
  lookups?: (string);
}

export interface OrdersGetOrderOrderUploadOrderParsingQuery {
  lookups?: (string);
}

export interface GetOrderOrderLineQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
}

export interface GetOrderOrderLineCommentsQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
}

export interface GetOrderOrderLineJobsQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
}

export interface GetOrderOrderLinesQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
  searchTerm?: (string);
  states?: (('WaitingForPrerequisites' | 'NoOutputsDefined' | 'DuplicatedOutputNames' | 'Reviewing' | 'Downloaded' | 'PrerequisitesFulfilled' | 'WaitingForProcessing' | 'Queued' | 'Processing' | 'WaitingForQC' | 'Done' | 'Rejected' | 'Error' | 'Locked' | 'Deferred'))[];
}

export interface GetOrderOrderLinesAggregationQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
  searchTerm?: (string);
  groupBy: (string)[];
  states?: (('WaitingForPrerequisites' | 'NoOutputsDefined' | 'DuplicatedOutputNames' | 'Reviewing' | 'Downloaded' | 'PrerequisitesFulfilled' | 'WaitingForProcessing' | 'Queued' | 'Processing' | 'WaitingForQC' | 'Done' | 'Rejected' | 'Error' | 'Locked' | 'Deferred'))[];
}

export interface GetOrderOrderUploadOrderParsingsQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
}

export interface GetOrderOrderUploadsQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
}

export interface GetOrderParsersQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
  agency: (string);
}

export interface GetOrdersQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
  agency: (string);
  searchTerm?: (string);
}

export interface TasksPostTaskSubtasksQuery {
  lookups?: (string);
}

export interface TasksGetTaskQuery {
  lookups?: (string);
}

export interface TasksPostTaskQuery {
  lookups?: (string);
}

export interface TasksPostTaskAttachmentsQuery {
  lookups?: (string);
}

export interface TasksPostTaskSubtaskQuery {
  lookups?: (string);
}

export interface GetSubtasksQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
}

export interface GetTaskAttachmentsQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
}

export interface GetTaskCommentsQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
}

export interface GetTaskSubtasksQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
  agency: (string);
}

export interface GetTasksQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
  agency: (string);
  searchTerm?: (string);
}

export interface GetSubtasks200Response {
  items: (Subtask)[];
  totalCount: (number);
}

export interface GetTasks200Response {
  items: (Task)[];
  totalCount: (number);
}

export type GetTask200Response = Task | AssetTask

export interface GetTask404Response {
  errorTag: (('TASK_NOT_FOUND'));
}

export interface GetTaskSubtasks200Response {
  items: (Subtask)[];
  totalCount: (number);
}

export interface GetTaskComments200Response {
  items: (Comment)[];
  totalCount: (number);
}

export interface GetTaskComments404Response {
  errorTag: (('TASK_NOT_FOUND'));
}

export type PostTaskState200Response = Task | AssetTask

export interface PostTaskState404Response {
  errorTag: (('TASK_NOT_FOUND'));
}

export type PostTaskComments200Response = Comment | AssetComment | OrderLineComment | TaskComment

export interface GetTaskAttachments200Response {
  items: (TaskAttachmentFile)[];
  totalCount: (number);
}

export interface GetTaskAttachmentDownload200Response {
  downloadUrl: (string);
}

export interface PostTaskAttachments200Response {
  signedURL: (string);
  taskAttachment: (TaskAttachmentFile);
}

export interface PostTaskAttachments404Response {
  errorTag: (('TASK_NOT_FOUND'));
}

export interface PostTaskAttachmentDelete404Response {
  errorTag: (('TASKATTACHMENT_NOT_FOUND'));
}

export interface PostTaskAttachmentFinished404Response {
  errorTag: (('TASK_NOT_FOUND' | 'TASKATTACHMENT_NOT_FOUND'));
}

export interface PostTaskAttachmentError404Response {
  errorTag: (('TASK_NOT_FOUND' | 'TASKATTACHMENT_NOT_FOUND'));
}

export type PostTaskSubtask200Response = Subtask | AssetSubtask

export interface PostTaskSubtask404Response {
  errorTag: (('TASK_NOT_FOUND' | 'SUBTASK_NOT_FOUND'));
}

export interface PostTaskSubtask400Response {
  errorTag: (('INVALID_CONTRACT' | 'INVALID_ASSIGNEE'));
}

export interface PostTaskSubtasks404Response {
  errorTag: (('TASK_NOT_FOUND'));
}

export interface PostTaskSubtasks400Response {
  errorTag: (('INVALID_CONTRACT' | 'INVALID_ASSIGNEE'));
}

export type PostTask200Response = Task | AssetTask

export interface PostTask404Response {
  errorTag: (('TASK_NOT_FOUND'));
}

export interface GetAgencies200Response {
  items: (Agency)[];
  totalCount: (number);
}

export interface GetAgencyUsers200Response {
  items: (UserWithDetails)[];
  totalCount: (number);
}

export interface GetAgencyUsers404Response {
  errorTag: (('AGENCY_NOT_FOUND'));
}

export interface GetAgencyStatistic404Response {
  errorTag: (('AGENCY_NOT_FOUND'));
}

export interface GetAgencyStatistic401Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostAgencies400Response {
  errorTag: (('AGENCY_ALREADY_EXISTS'));
}

export interface GetAgencyContracts200Response {
  items: (Contract)[];
  totalCount: (number);
}

export interface GetAgencyContracts404Response {
  errorTag: (('AGENCY_NOT_FOUND'));
}

export interface GetAgencyServiceUser404Response {
  errorTag: (('AGENCY_NOT_FOUND' | 'USER_NOT_FOUND'));
}

export interface GetAgencyServiceUserApiKeys200ResponseItems {
  md5: (string);
  key: (string);
  preview: (string);
  expiresAt?: (string);
  deletedAt?: (string);
  deletedBy?: (string);
  createdAt: (string);
  createdBy: (string);
}

export interface GetAgencyServiceUserApiKeys200Response {
  items: (GetAgencyServiceUserApiKeys200ResponseItems)[];
  totalCount: (number);
}

export interface GetAgencyServiceUserApiKeys404Response {
  errorTag: (('AGENCY_NOT_FOUND' | 'USER_NOT_FOUND'));
}

export interface GetAgencyServiceUsers200Response {
  items: (ServiceUser)[];
  totalCount: (number);
}

export interface GetAgencyServiceUsers404Response {
  errorTag: (('AGENCY_NOT_FOUND'));
}

export interface GetAgencyInvitations200Response {
  items: (Invitation)[];
  totalCount: (number);
}

export interface GetAgencyInvitations403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetAgencyRoles200Response {
  items: (Role)[];
  totalCount: (number);
}

export interface GetAgencyRoles404Response {
  errorTag: (('AGENCY_NOT_FOUND'));
}

export interface PostAgencyInvitations403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostAgencyInvitationDelete403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostAgencyServiceUser404Response {
  errorTag: (('AGENCY_NOT_FOUND' | 'AUTH_USER_NOT_FOUND' | 'USER_NOT_FOUND'));
}

export interface PostAgencyServiceUserApiKeyDelete404Response {
  errorTag: (('AGENCY_NOT_FOUND' | 'USER_NOT_FOUND' | 'API_KEY_NOT_FOUND'));
}

export interface PostAgencyServiceUserApiKeys200ResponseServiceAuthUserApiKey {
  md5: (string);
  key: (string);
  preview: (string);
  expiresAt?: (string);
  deletedAt?: (string);
  deletedBy?: (string);
  createdAt: (string);
  createdBy: (string);
}

export interface PostAgencyServiceUserApiKeys200Response {
  serviceAuthUserApiKey: (PostAgencyServiceUserApiKeys200ResponseServiceAuthUserApiKey);
  apiKey: (string);
}

export interface PostAgencyServiceUserApiKeys404Response {
  errorTag: (('AGENCY_NOT_FOUND' | 'USER_NOT_FOUND' | 'API_KEY_NOT_FOUND'));
}

export interface PostAgencyServiceUserDelete404Response {
  errorTag: (('AGENCY_NOT_FOUND' | 'USER_NOT_FOUND'));
}

export interface PostAgencyServiceUsers404Response {
  errorTag: (('AGENCY_NOT_FOUND'));
}

export interface GetAgencyGraphFiles200Response {
  items: (GraphFile)[];
  totalCount: (number);
}

export interface PostAgencyGraphFiles200Response {
  signedURL: (string);
  graphFile: (GraphFile);
}

export interface GetAgencyGraphFolders200Response {
  items: (GraphFolder)[];
  totalCount: (number);
}

export interface GetAgencyGraphsStepTemplates200Response {
  items: (StepTemplate)[];
  totalCount: (number);
}

export interface GetAgencyGraphsUnsaved200Response {
  items: (Graph)[];
  totalCount: (number);
}

export interface GetAgencyGraphs200Response {
  items: (Graph)[];
  totalCount: (number);
}

export interface GetAgencyGraph404Response {
  errorTag: (('GRAPH_NOT_FOUND'));
}

export interface GetAgencyGraphGraphVersionSamples200Response {
  items: (Sample)[];
  totalCount: (number);
}

export interface GetAgencyGraphGraphVersionSamples404Response {
  errorTag: (('GRAPH_NOT_FOUND' | 'GRAPHVERSION_NOT_FOUND'));
}

export interface GetAgencyGraphGraphVersions200Response {
  items: (GraphVersion)[];
  totalCount: (number);
}

export interface GetAgencyGraphGraphVersions404Response {
  errorTag: (('GRAPH_NOT_FOUND'));
}

export interface GetAgencyGraphGraphVersion404Response {
  errorTag: (('GRAPH_NOT_FOUND' | 'GRAPHVERSION_NOT_FOUND'));
}

export interface PostAgencyGraphGraphVersion404Response {
  errorTag: (('GRAPH_NOT_FOUND' | 'GRAPHVERSION_NOT_FOUND'));
}

export type PostAgencyGraphGraphVersionSample200Response = Sample | PlainSample | OrderLineSample

export interface PostAgencyGraphGraphVersionSampleDebug200Response {
  job?: (Job);
  assemblingErrors?: (AssemblingError)[];
}

export interface PostAgencyGraphGraphVersionSampleDebug404Response {
  errorTag: (('GRAPH_NOT_FOUND' | 'GRAPHVERSION_NOT_FOUND' | 'SAMPLE_NOT_FOUND' | 'JOB_NOT_FOUND'));
}

export interface PostAgencyGraphGraphVersionSampleDebug400Response {
  errorTag: (('INVALID_OUTPUT' | 'INVALID_PAUSEAT' | 'NO_OUTPUTS'));
}

export type PostAgencyGraphGraphVersionSampleDelete200Response = Sample | PlainSample | OrderLineSample

export interface PostAgencyGraphGraphVersionSampleDelete404Response {
  errorTag: (('GRAPH_NOT_FOUND' | 'GRAPHVERSION_NOT_FOUND' | 'SAMPLE_NOT_FOUND'));
}

export type PostAgencyGraphGraphVersionSamples200Response = Sample | PlainSample | OrderLineSample

export interface PostAgencyGraphGraphVersionSave404Response {
  errorTag: (('GRAPH_NOT_FOUND' | 'GRAPHVERSION_NOT_FOUND'));
}

export interface PostAgencyGraphGraphVersionValidate200ResponseSamples {
  assembling?: (Assembling);
  assemblies: ({[key: string]: string});
  hash: (string);
}

export interface PostAgencyGraphGraphVersionValidate200Response {
  assembling?: (Assembling);
  assemblies: ({[key: string]: string});
  hash: (string);
  samples: ({[key: string]: PostAgencyGraphGraphVersionValidate200ResponseSamples});
}

export interface PostAgencyGraphGraphVersionValidate404Response {
  errorTag: (('GRAPH_NOT_FOUND' | 'GRAPHVERSION_NOT_FOUND'));
}

export interface PostAgencyGraphGraphVersions404Response {
  errorTag: (('GRAPH_NOT_FOUND' | 'GRAPHVERSION_NOT_FOUND'));
}

export interface GetJobs200Response {
  items: (Job)[];
  totalCount: (number);
}

export interface GetJobs403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetJobStepLogs200Response {
  logEntries: (LogEntry)[];
}

export interface GetJobStepLogs403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetJobDownload200Response {
  downloadUrl?: (string);
  downloads: (SourceTarget)[];
}

export interface GetJobDownload403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetJobJobOutputStepAssembly200Response {
  assembly: (string);
}

export interface GetJobJobOutputStepAssembly403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetJobJobOutputStepOuputDownload404Response {
  errorTag: (('JOB_NOT_FOUND' | 'JOBOUTPUT_NOT_FOUND' | 'STEP_NOT_FOUND' | 'OUTPUT_NOT_FOUND'));
}

export interface GetJobJobOutputStepOuputDownload400Response {
  errorTag: (('INVALID_FORMAT' | 'EXPIRED'));
}

export interface GetJobJobOutputStepOuputDownload200Response {
  items: (JobDownload)[];
  totalCount: (number);
}

export interface GetJobJobOutputStepOuputDownload403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetJobsCount200Response {
  running: (number);
  queued: (number);
}

export interface GetJobsCount403Response {
  errorTag: (('FORBIDDEN'));
}

export type GetJob200Response = Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob

export interface GetJob403Response {
  errorTag: (('FORBIDDEN'));
}

export type PostJob200Response = Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob

export interface PostJob404Response {
  errorTag: (('JOB_NOT_FOUND'));
}

export type PostJobCancel200Response = Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob

export interface PostJobCancel404Response {
  errorTag: (('JOB_NOT_FOUND'));
}

export interface PostJobCancel400Response {
  errorTag: (('INVALID_STATE'));
}

export type PostJobPause200Response = Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob

export interface PostJobPause404Response {
  errorTag: (('JOB_NOT_FOUND'));
}

export interface PostJobPause400Response {
  errorTag: (('INVALID_STATE'));
}

export type PostJobContinue200Response = Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob

export interface PostJobContinue404Response {
  errorTag: (('JOB_NOT_FOUND'));
}

export interface PostJobContinue400Response {
  errorTag: (('INVALID_STATE'));
}

export type PostJobRestart200Response = Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob

export interface PostJobRestart404Response {
  errorTag: (('JOB_NOT_FOUND'));
}

export interface PostJobRestart400Response {
  errorTag: (('INVALID_STATE'));
}

export interface GetUserHasRights200Response {
  rights: ({[key: string]: boolean});
}

export interface GetUserHasRights403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetUserAgencies200Response {
  items: (Agency)[];
  totalCount: (number);
}

export interface GetUserInvitations200Response {
  items: (Invitation)[];
  totalCount: (number);
}

export interface GetUserInvitations403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostUserInvitationAccept404Response {
  errorTag: (('INVITATION_NOT_FOUND'));
}

export interface PostUserInvitationAccept403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostUserInvitationDecline404Response {
  errorTag: (('INVITATION_NOT_FOUND'));
}

export interface PostUserInvitationDecline403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetAssetsPreviewTemplates200Response {
  items: (PreviewTemplate)[];
  totalCount: (number);
}

export interface GetAssetsPreviewTemplate404Response {
  errorTag: (('PREVIEWTEMPLATE_NOT_FOUND'));
}

export interface GetAssets200Response {
  items: (Asset)[];
  totalCount: (number);
}

export interface GetAssets403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetAsset404Response {
  errorTag: (('ASSET_NOT_FOUND'));
}

export interface GetAssetAssetVersionHistory200Response {
  items: (AssetHistoryEntry)[];
  totalCount: (number);
}

export interface GetAssetAssetVersionHistory404Response {
  errorTag: (('ASSET_NOT_FOUND'));
}

export interface GetAssetHistory200Response {
  items: (AssetHistoryEntry)[];
  totalCount: (number);
}

export interface GetAssetHistory404Response {
  errorTag: (('ASSET_NOT_FOUND'));
}

export interface GetAssetAssetVersions200Response {
  items: (AssetVersion)[];
  totalCount: (number);
}

export interface GetAssetAssetVersions404Response {
  errorTag: (('ASSET_NOT_FOUND'));
}

export interface GetAssetAssetVersions400Response {
  errorTag: (('AGENCY_MISSING'));
}

export interface GetAssetAssetVersions403Response {
  errorTag: (('FORBIDDEN'));
}

export type GetAssetAssetVersion200Response = AssetVersion | ComfyAssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion

export interface GetAssetAssetVersion404Response {
  errorTag: (('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND'));
}

export interface GetAssetAssetVersionDownload200Response {
  downloadKey: (string);
}

export interface GetAssetAssetVersionDownload400Response {
  errorTag: (('INVALID_ASSET_ID' | 'INVALID_ASSETVERSION_ID'));
}

export interface GetAssetAssetVersionDownload404Response {
  errorTag: (('ASSETVERSION_NOT_FOUND'));
}

export interface GetAssetAssetVersionDownloadKey200Response {
  errorTag: (('INVALID_ASSET_ID' | 'INVALID_ASSETVERSION_ID'));
}

export interface GetAssetAssetVersionDownloadKey400Response {
  errorTag: (('INVALID_ASSET_ID' | 'INVALID_ASSETVERSION_ID'));
}

export interface GetAssetAssetVersionDownloadKey404Response {
  errorTag: (('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND' | 'FILE_NOT_FOUND'));
}

export interface GetAssetAssetVersionVariantSets200Response {
  items: (AssetVersionVariantSet)[];
  totalCount: (number);
}

export interface GetAssetAssetVersionVariantSets404Response {
  errorTag: (('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND'));
}

export interface GetAssetAssetVersionPreviewSets200Response {
  items: (PreviewSet)[];
  totalCount: (number);
}

export interface GetAssetAssetVersionPreviewSets404Response {
  errorTag: (('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND'));
}

export interface GetAssetAssetVersionPreviewSets403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetAssetAssetVersionBlockedVariantSelections200Response {
  items: (BlockedVariantSelection)[];
  totalCount: (number);
}

export interface GetAssetAssetVersionBlockedVariantSelections404Response {
  errorTag: (('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND'));
}

export interface GetAssetAssetVersionFiles200ResponsePathFileMetadataItems {
  id: (string);
  name: (string);
  isFolder: (boolean);
  path: (string);
  size: (number);
  finishedAt: (string);
}

export interface GetAssetAssetVersionFiles200ResponsePathFileMetadata {
  items: (GetAssetAssetVersionFiles200ResponsePathFileMetadataItems)[];
}

export interface GetAssetAssetVersionFiles200Response {
  pathFileMetadata: ({[key: string]: GetAssetAssetVersionFiles200ResponsePathFileMetadata});
  totalCount: (number);
}

export interface GetAssetAssetVersionFiles400Response {
  errorTag: (('INVALID_ASSET_ID' | 'INVALID_ASSETVERSION_ID'));
}

export interface GetAssetAssetVersionFile400Response {
  errorTag: (('MULTIPLE_ASSETS_FOUND' | 'INVALID_ASSET_ID' | 'INVALID_ASSETVERSION_ID' | 'INVALID_FILE_ID'));
}

export interface GetAssetAssetVersionFile404Response {
  errorTag: (('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND'));
}

export interface GetAssetAssetVersionFileDownload200Response {
  content?: (string);
  url: (string);
}

export interface GetAssetAssetVersionFileDownload400Response {
  errorTag: (('INVALID_ASSET_ID' | 'INVALID_ASSETVERSION_ID' | 'INVALID_FILE_ID'));
}

export interface GetAssetAssetVersionFileDownload404Response {
  errorTag: (('ASSETVERSION_FILE_NOT_FOUND'));
}

export interface GetAssetAssetVersionFileHistory200ResponseItems {
  user: (string);
  finishedAt: (string);
  version: (number);
  assetVersion: (string);
  size: (number);
}

export interface GetAssetAssetVersionFileHistory200Response {
  items: (GetAssetAssetVersionFileHistory200ResponseItems)[];
  totalCount: (number);
}

export interface GetAssetAssetVersionFileHistory400Response {
  errorTag: (('MULTIPLE_ASSETS_FOUND' | 'INVALID_ASSET_ID' | 'INVALID_ASSETVERSION_ID' | 'INVALID_FILE_ID'));
}

export interface GetAssetAssetVersionFileHistory404Response {
  errorTag: (('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND' | 'ASSETVERSION_FILE_NOT_FOUND'));
}

export interface GetAssetComments200Response {
  items: (Comment)[];
  totalCount: (number);
}

export interface GetAssetComments404Response {
  errorTag: (('ASSET_NOT_FOUND'));
}

export type PostAssetAssetVersionSubmit200Response = AssetVersion | ComfyAssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion

export interface PostAssetAssetVersionSubmit404Response {
  errorTag: (('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND'));
}

export interface PostAssetAssetVersionSubmit403Response {
  errorTag: (('FORBIDDEN'));
}

export type PostAssetAssetVersionPublish200Response = AssetVersion | ComfyAssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion

export interface PostAssetAssetVersionPublish404Response {
  errorTag: (('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND'));
}

export interface PostAssetAssetVersionPublish403Response {
  errorTag: (('FORBIDDEN'));
}

export type PostAssetAssetVersionReject200Response = AssetVersion | ComfyAssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion

export interface PostAssetAssetVersionReject404Response {
  errorTag: (('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND'));
}

export interface PostAssetAssetVersionReject403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostAssetAssetVersionBlockedVariantSelections200Response {
  successful: (boolean);
}

export interface PostAssetAssetVersionBlockedVariantSelections404Response {
  errorTag: (('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND'));
}

export interface PostAssetAssetVersionBlockedVariantSelectionsDelete200Response {
  successful: (boolean);
}

export interface PostAssetAssetVersionBlockedVariantSelectionsDelete404Response {
  errorTag: (('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND'));
}

export type PostAssetAssetVersionPreviewSetPreviews200Response = GeneratedPreview | OrderLinePreview | UploadPreview

export interface PostAssetAssetVersionPreviewSetPreviews404Response {
  errorTag: (('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND' | 'PREVIEWSET_NOT_FOUND'));
}

export interface PostAssetAssetVersionPreviewSetPreviews403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostAssetAssetVersionPreviewSetPreviewError404Response {
  errorTag: (('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND' | 'PREVIEWSET_NOT_FOUND' | 'PREVIEW_NOT_FOUND'));
}

export interface PostAssetAssetVersionPreviewSetPreviewError403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostAssetAssetVersionPreviewSetPreviewFinished404Response {
  errorTag: (('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND' | 'PREVIEWSET_NOT_FOUND' | 'PREVIEW_NOT_FOUND'));
}

export interface PostAssetAssetVersionPreviewSetPreviewFinished403Response {
  errorTag: (('FORBIDDEN'));
}

export type PostAssetAssetVersionPreviewSetPreviewDelete200Response = GeneratedPreview | OrderLinePreview | UploadPreview

export interface PostAssetAssetVersionPreviewSetPreviewDelete404Response {
  errorTag: (('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND' | 'PREVIEWSET_NOT_FOUND' | 'PREVIEW_NOT_FOUND'));
}

export interface PostAssetAssetVersionPreviewSetPreviewDelete403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostAssetAssetVersionPreviewSets404Response {
  errorTag: (('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND'));
}

export interface PostAssetAssetVersionPreviewSets403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostAssetAssetVersionPreviewSetProcess404Response {
  errorTag: (('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND' | 'PREVIEWSET_NOT_FOUND'));
}

export interface PostAssetAssetVersionPreviewSetProcess403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostAssetAssetVersionPreviewSetPublish404Response {
  errorTag: (('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND' | 'PREVIEWSET_NOT_FOUND'));
}

export interface PostAssetAssetVersionPreviewSetPublish403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostAsset404Response {
  errorTag: (('ASSET_NOT_FOUND'));
}

export interface GetOrderParsers200Response {
  items: (OrderParser)[];
  totalCount: (number);
}

export interface GetOrderParsers404Response {
  errorTag: (string);
}

export interface GetOrderParsers403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetOrder404Response {
  errorTag: (string);
}

export interface GetOrder403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetOrders200Response {
  items: (Order)[];
  totalCount: (number);
}

export interface GetOrderOrderLinesLocks200Response {
  items: (OrderLock)[];
  totalCount: (number);
}

export interface GetOrderOrderLinesLocks403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetOrderOrderLinesDownloadKey200Response {
  errorTag: (('ORDER_NOT_FOUND' | 'FILE_NOT_FOUND'));
}

export interface GetOrderOrderLinesAggregation200Response {
  items: (OrderLineAggregation)[];
  totalCount: (number);
}

export interface GetOrderOrderLinesAggregation403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetOrderOrderLines200Response {
  items: (OrderLine)[];
  totalCount: (number);
}

export interface GetOrderOrderLines404Response {
  errorTag: (string);
}

export interface GetOrderOrderLines403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetOrderSubtasks200Response {
  items: (OrderSubtask)[];
  totalCount: (number);
}

export interface GetOrderSubtasks404Response {
  errorTag: (string);
}

export interface GetOrderSubtasks403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetOrderOrderLine404Response {
  errorTag: (string);
}

export interface GetOrderOrderLine403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetOrderOrderLineJobs200Response {
  items: (OrderLineJob)[];
  totalCount: (number);
}

export interface GetOrderOrderLineJobs404Response {
  errorTag: (string);
}

export interface GetOrderOrderLineJobs403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetOrderOrderUpload404Response {
  errorTag: (string);
}

export interface GetOrderOrderUpload403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetOrderOrderUploads200Response {
  items: (OrderUpload)[];
  totalCount: (number);
}

export interface GetOrderOrderUploads404Response {
  errorTag: (string);
}

export interface GetOrderOrderUploads403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetOrderParser404Response {
  errorTag: (string);
}

export interface GetOrderParser403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetOrderOrderUploadOrderParsings200Response {
  items: (OrderParsing)[];
  totalCount: (number);
}

export interface GetOrderOrderUploadOrderParsings404Response {
  errorTag: (string);
}

export interface GetOrderOrderUploadOrderParsings403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetOrderOrderUploadOrderParsing404Response {
  errorTag: (string);
}

export interface GetOrderOrderUploadOrderParsing403Response {
  errorTag: (('FORBIDDEN'));
}

export interface GetOrderOrderLineComments200Response {
  items: (Comment)[];
  totalCount: (number);
}

export interface GetOrderOrderLineComments404Response {
  errorTag: (('ORDERLINE_NOT_FOUND'));
}

export interface GetOrderOrderLineHistory200Response {
  items: (OrderLineHistoryEntry)[];
  totalCount: (number);
}

export interface GetOrderOrderLineHistory404Response {
  errorTag: (('ORDER_NOT_FOUND' | 'ORDERLINE_NOT_FOUND'));
}

export interface PostOrders400Response {
  errorTag: (('NAME_ALREADY_EXISTS' | 'INVALID_AGENCY'));
}

export interface PostOrders403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostOrderOrderLinesDownload200Response {
  downloadKey: (string);
}

export interface PostOrderOrderLinesDownload404Response {
  errorTag: (('ORDER_NOT_FOUND'));
}

export interface PostOrderOrderLinesCompare200ResponsePropertiesValues {
  sum: (number);
}

export interface PostOrderOrderLinesCompare200ResponseProperties {
  values: ({[key: string]: PostOrderOrderLinesCompare200ResponsePropertiesValues});
}

export interface PostOrderOrderLinesCompare200Response {
  properties: ({[key: string]: PostOrderOrderLinesCompare200ResponseProperties});
}

export interface PostOrderOrderLinesCompare404Response {
  errorTag: (('ORDER_NOT_FOUND'));
}

export interface PostOrderOrderLinesLock404Response {
  errorTag: (('ORDER_NOT_FOUND'));
}

export interface PostOrderOrderUploads200Response {
  signedURL: (string);
  orderUploadState: (string);
  orderUploadId: (string);
}

export interface PostOrderOrderUploads400Response {
  errorTag: (('INVALID_ORDERUPLOAD'));
}

export interface PostOrderOrderUploads404Response {
  errorTag: (('ORDER_NOT_FOUND'));
}

export interface PostOrderOrderUploads403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostOrderOrderUploadValidate200ResponseOutputs {
  assembling: (Assembling);
  output: (Output);
  assemblies: ({[key: string]: string});
}

export interface PostOrderOrderUploadValidate200Response {
  outputs: (PostOrderOrderUploadValidate200ResponseOutputs)[];
}

export interface PostOrderOrderUploadValidate404Response {
  errorTag: (('ORDER_NOT_FOUND' | 'ORDERUPLOAD_NOT_FOUND'));
}

export interface PostOrderOrderUploadPreview200Response {
  plainOrderLines: ({[key: string]: string})[];
  formatError?: (string);
  delimiter?: (string);
  textQualifier?: (string);
}

export interface PostOrderOrderUploadPreview404Response {
  errorTag: (('ORDER_NOT_FOUND' | 'ORDERUPLOAD_NOT_FOUND'));
}

export interface PostOrderOrderUploadPreview403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostOrderDelete404Response {
  errorTag: (('ORDER_NOT_FOUND'));
}

export interface PostOrderDelete400Response {
  errorTag: (('USER_NOT_FOUND'));
}

export interface PostOrderDelete403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostOrderOrderUploadError400Response {
  errorTag: (('ORDER_NOT_FOUND' | 'ORDERUPLOAD_NOT_FOUND'));
}

export interface PostOrderOrderUploadError403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostOrderOrderUploadFinished400Response {
  errorTag: (('ORDER_NOT_FOUND' | 'ORDERUPLOAD_NOT_FOUND'));
}

export interface PostOrderOrderUploadFinished403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostOrderOrderUploadParse404Response {
  errorTag: (('ORDER_NOT_FOUND' | 'ORDERUPLOAD_NOT_FOUND' | 'ORDERPARSER_NOT_FOUND'));
}

export interface PostOrderOrderUploadParse403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostOrderProcess200Response {
  affectedOrderLines: (number);
}

export interface PostOrderProcess403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostOrderAccept200Response {
  affectedOrderLines: (number);
}

export interface PostOrderAccept403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostOrderReject200Response {
  affectedOrderLines: (number);
}

export interface PostOrderReject403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostOrderReview200Response {
  affectedOrderLines: (number);
}

export interface PostOrderReview403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostOrderLocksDelete404Response {
  errorTag: (('USER_NOT_FOUND'));
}

export type PostOrderOrderLineComments200Response = Comment | AssetComment | OrderLineComment | TaskComment

export interface PostOrderOrderLineComments403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostOrderOrderLineAccept404Response {
  errorTag: (('ORDER_NOT_FOUND' | 'ORDERLINE_NOT_FOUND'));
}

export interface PostOrderOrderLineAccept400Response {
  errorTag: (('INVALID_STATE'));
}

export interface PostOrderOrderLineReject404Response {
  errorTag: (('ORDER_NOT_FOUND' | 'ORDERLINE_NOT_FOUND'));
}

export interface PostOrderOrderLineReject400Response {
  errorTag: (('INVALID_STATE'));
}

export interface PostOrderOrderLineReview404Response {
  errorTag: (('ORDER_NOT_FOUND' | 'ORDERLINE_NOT_FOUND'));
}

export interface PostOrderOrderLineReview400Response {
  errorTag: (('INVALID_STATE'));
}

export interface PostOrderOrderLineProcess404Response {
  errorTag: (('ORDER_NOT_FOUND' | 'ORDERLINE_NOT_FOUND'));
}

export interface PostOrderOrderLineProcess400Response {
  errorTag: (('INVALID_STATE'));
}

export interface PostOrderOrderLineDownloaded404Response {
  errorTag: (('ORDER_NOT_FOUND' | 'ORDERLINE_NOT_FOUND'));
}

export interface PostOrderOrderLineDownloaded403Response {
  errorTag: (('FORBIDDEN'));
}

export interface PostOrder404Response {
  errorTag: (('ORDER_NOT_FOUND'));
}

export interface GetTaskQuery {
  lookups?: (string);
}

export interface PostTaskBody {
  tags?: (string)[];
}

export interface PostTaskSubtasksBody {
  name: (string);
  description: (string);
  assignee?: (string | null);
  contract?: (string | null);
}

export interface PostTaskSubtasksQuery {
  lookups?: (string);
}

export interface PostTaskCommentsBody {
  message: (string);
}

export interface PostTaskStateBody {
  state: (string);
}

export interface PostTaskAttachmentsBody {
  length: (number);
  mimeType: (string);
  fileName: (string);
}

export interface PostTaskAttachmentsQuery {
  lookups?: (string);
}

export interface PostTaskSubtaskBody {
  state?: (('New' | 'InProgress' | 'Done'));
  assignee?: (string | null);
  contract?: (string | null);
}

export interface PostTaskSubtaskQuery {
  lookups?: (string);
}

export interface PostAgenciesBody {
  name: (string);
  displayName: (string);
}

export interface PostAgencyServiceUserBody {
  displayName: (string);
}

export interface PostAgencyServiceUsersBody {
  displayName: (string);
}

export interface PostAgencyInvitationsBody {
  email: (string);
  roles: (string)[];
}

export interface PostAgencyInvitationDeleteQuery {
  lookups?: (string);
}

export interface PostAgencyGraphFilesBody {
  graphFolder?: (string | null);
  length: (number);
  mimeType: (string);
  fileName: (string);
}

export interface PostAgencyGraphFoldersBody {
  parent?: (string);
  name: (string);
}

export interface PostAgencyGraphFolderBody {
  parent?: (string);
  name?: (string);
}

export interface GetAgencyGraphsUnsavedQuery {
  lookups?: (string);
}

export interface PostAgencyGraphsBody {
  name: (string);
  graphFolder?: (string);
}

export interface GetAgencyGraphQuery {
  lookups?: (string);
  projection?: (string);
}

export interface PostAgencyGraphBody {
  name: (string);
}

export interface PostAgencyGraphGraphVersionSamplesBody0 {
  type: (('Plain' | 'OrderLine'));
  name: (string);
}

export type PostAgencyGraphGraphVersionSamplesBody = PostAgencyGraphGraphVersionSamplesBody0 | PostAgencyGraphGraphVersionSamplesBodyPlain | PostAgencyGraphGraphVersionSamplesBodyOrderLine

export interface PostAgencyGraphGraphVersionsBody {
  parent?: (string);
}

export interface PostAgencyGraphGraphVersionBody {
  change: (number);
  assemblingTemplate: (AssemblingTemplate);
  notes: (GraphVersionNote)[];
}

export interface PostAgencyGraphGraphVersionSampleBody0 {
  type: (('Plain' | 'OrderLine'));
  name: (string);
}

export type PostAgencyGraphGraphVersionSampleBody = PostAgencyGraphGraphVersionSampleBody0 | PostAgencyGraphGraphVersionSampleBodyPlain | PostAgencyGraphGraphVersionSampleBodyOrderLine

export interface PostAgencyGraphGraphVersionSampleDebugBody {
  restartJob?: (string);
  pauseAt?: (string);
  clearCache?: (boolean);
}

export interface PostAgencyGraphGraphVersionSaveBody {
  change: (number);
}

export interface GetJobStepLogsQuery {
  skip?: (number);
  limit?: (number);
}

export interface PostJobBody {
  tags?: (string)[];
}

export interface PostJobPauseBody {
  stepHash?: (StepHash);
}

export interface PostJobContinueBody {
  pauseAt?: (StepHash);
}

export interface PostJobRestartBody {
  assemblings?: (Assembling)[];
  pauseAt?: (StepHash);
  clearCache?: (boolean);
}

export interface GetUserHasRightsQuery {
  resource: (string);
  rights: (string)[];
}

export interface GetAssetQuery {
  lookups?: (string);
}

export interface PostAssetBody {
  displayName?: (string);
  tags?: (string)[];
}

export interface GetAssetAssetVersionQuery {
  lookups?: (string);
}

export interface PostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections {
  variantSelections: (VariantSelection)[];
  reason?: (string);
}

export interface PostAssetAssetVersionBlockedVariantSelectionsBody {
  blockedVariantSelections?: (PostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections)[];
  fromAssetVersion?: (string);
}

export interface GetAssetAssetVersionFilesQuery {
  path?: (string);
  recursive?: (boolean);
}

export interface PostAssetCommentsBody {
  message: (string);
  assetVersion?: (string);
}

export interface PostAssetAssetVersionBlockedVariantSelectionsDeleteBody {
  blockedVariantSelections: (string)[];
}

export interface PostOrderParsersBody {
  name: (string);
  agency: (string);
  identifierColumn: (string);
  outputColumns: (string)[];
  assemblyTemplate: (AssemblyTemplate);
}

export interface GetOrderQuery {
  lookups?: (string);
}

export interface PostOrderBody {
  tags?: (string)[];
}

export interface PostOrdersBody {
  agency: (string);
  name: (string);
}

export interface GetOrderSubtasksQuery {
  skip?: (number);
  limit?: (number);
  lookups?: (string);
  orderBy?: (string);
  orderDirection?: (number);
  filter?: (Condition);
  projection?: (string);
}

export interface GetOrderOrderUploadQuery {
  lookups?: (string);
}

export interface PostOrderOrderUploadsBody {
  length: (number);
  mimeType: (string);
  fileName: (string);
}

export interface GetOrderParserQuery {
  lookups?: (string);
}

export interface GetOrderOrderUploadOrderParsingQuery {
  lookups?: (string);
}

export interface PostOrderOrderLineCommentsBody {
  message: (string);
}

export interface PostOrderOrderLinesDownloadBody {
  orderLines: (string)[];
}

export interface PostOrderOrderLinesCompareBody {
  orderLines: (string)[];
}

export interface PostOrderOrderLinesLockBody {
  lockName: (string);
  filter?: (Condition);
  identifiers?: (string)[];
}

export interface PostOrderOrderUploadValidateBody {
  orderUploadFormat: (OrderUploadFormat);
  orderParser: (BaseOrderParser);
  identifierColumn: (string);
  identifier: (string);
}

export interface PostOrderOrderUploadPreviewBody {
  format: (OrderUploadFormat);
  lines?: (number);
}

export interface PostOrderOrderUploadParseBody {
  orderParser?: (string);
  identifierColumn: (string);
  newOrderParser?: (BaseOrderParser);
  orderUploadFormat: (OrderUploadFormat);
  duplicateMode: (('KeepBoth' | 'KeepNew' | 'KeepOld'));
  ignoreOrderLineStates?: (('WaitingForPrerequisites' | 'NoOutputsDefined' | 'DuplicatedOutputNames' | 'Reviewing' | 'Downloaded' | 'PrerequisitesFulfilled' | 'WaitingForProcessing' | 'Queued' | 'Processing' | 'WaitingForQC' | 'Done' | 'Rejected' | 'Error' | 'Locked' | 'Deferred'))[];
}

export interface PostOrderProcessBody {
  orderLines?: (string)[];
  filter?: (Condition);
  searchTerm?: (string);
}

export interface PostOrderAcceptBody {
  orderLines?: (string)[];
  filter?: (Condition);
  searchTerm?: (string);
}

export interface PostOrderRejectBody {
  orderLines?: (string)[];
  filter?: (Condition);
  searchTerm?: (string);
}

export interface PostOrderReviewBody {
  orderLines?: (string)[];
  filter?: (Condition);
  searchTerm?: (string);
  ignoreBlockedVariantSelections?: (boolean);
}

export interface PostOrderLocksDeleteBody {
  lockIds: (string)[];
  restore: (boolean);
}

export interface PostOrderOrderLineAcceptBody {
  next?: (boolean);
}

export interface PostOrderOrderLineAcceptQuery {
  lookups?: (string);
}

export interface PostOrderOrderLineRejectBody {
  next?: (boolean);
}

export interface PostOrderOrderLineRejectQuery {
  lookups?: (string);
}

export interface PostOrderOrderLineReviewQuery {
  lookups?: (string);
}

export interface PostOrderOrderLineProcessQuery {
  lookups?: (string);
}

abstract class AbstractService {

  protected queryParams(query?: any) : string {
    if (!query) return '';
    return '?' + Object.keys(query).filter(q => typeof query[q] !== 'undefined').map(q => {
      if (Array.isArray(query[q])) {
        return query[q].map((t: any) => `${q}=${encodeURIComponent(this.queryParam(t as any))}`).join('&')
      } else {
        return `${q}=${encodeURIComponent(this.queryParam(query[q] as any))}`
      }
    }).join('&');
  }

  protected queryParam(val: any) : string {
    if (typeof val === 'string') return val;
    if (typeof val === 'number') return val.toString();
    if (typeof val === 'boolean') return val ? 'true' : 'false';
    if (typeof val === 'object') return JSON.stringify(val);
    return '';
  }

}

@Injectable({
  providedIn: 'root'
})
export class TaskApiService extends AbstractService {

  constructor(
    @Inject(BASE_PATH) private basePath: string,
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super()
  }


  public async getSubtasks(query?: GetSubtasksQuery): Promise<[GetSubtasks200Response | null, number, any]> {
    const url = `${this.basePath}/tasks/subtasks`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetSubtasks200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetSubtasks200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getTasks(query?: GetTasksQuery): Promise<[GetTasks200Response | null, number, any]> {
    const url = `${this.basePath}/tasks`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetTasks200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetTasks200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getTask(taskId: string, query?: GetTaskQuery): Promise<[Task | AssetTask | null, number, GetTask404Response | null]> {
    const url = `${this.basePath}/tasks/${taskId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<Task | AssetTask>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Task | AssetTask, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetTask404Response]
      }

      throw err
    }

  }

  public async postTask(taskId: string, body: PostTaskBody): Promise<[Task | AssetTask | null, number, PostTask404Response | null]> {
    const url = `${this.basePath}/tasks/${taskId}`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Task | AssetTask>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Task | AssetTask, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostTask404Response]
      }

      throw err
    }

  }

  public async getTaskSubtasks(taskId: string, query?: GetTaskSubtasksQuery): Promise<[GetTaskSubtasks200Response | null, number, any]> {
    const url = `${this.basePath}/tasks/${taskId}/subtasks`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetTaskSubtasks200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetTaskSubtasks200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postTaskSubtasks(taskId: string, body: PostTaskSubtasksBody, query?: PostTaskSubtasksQuery): Promise<[ManualSubtask | null, number, PostTaskSubtasks400Response | PostTaskSubtasks404Response | null]> {
    const url = `${this.basePath}/tasks/${taskId}/subtasks`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<ManualSubtask>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as ManualSubtask, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostTaskSubtasks400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostTaskSubtasks404Response]
      }

      throw err
    }

  }

  public async getTaskComments(taskId: string, query?: GetTaskCommentsQuery): Promise<[GetTaskComments200Response | null, number, GetTaskComments404Response | null]> {
    const url = `${this.basePath}/tasks/${taskId}/comments`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetTaskComments200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetTaskComments200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetTaskComments404Response]
      }

      throw err
    }

  }

  public async postTaskComments(taskId: string, body: PostTaskCommentsBody): Promise<[Comment | AssetComment | OrderLineComment | TaskComment | null, number, any]> {
    const url = `${this.basePath}/tasks/${taskId}/comments`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Comment | AssetComment | OrderLineComment | TaskComment>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Comment | AssetComment | OrderLineComment | TaskComment, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postTaskState(taskId: string, body: PostTaskStateBody): Promise<[Task | AssetTask | null, number, PostTaskState404Response | null]> {
    const url = `${this.basePath}/tasks/${taskId}/state`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Task | AssetTask>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Task | AssetTask, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostTaskState404Response]
      }

      throw err
    }

  }

  public async getTaskAttachments(taskId: string, query?: GetTaskAttachmentsQuery): Promise<[GetTaskAttachments200Response | null, number, any]> {
    const url = `${this.basePath}/tasks/${taskId}/attachments`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetTaskAttachments200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetTaskAttachments200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postTaskAttachments(taskId: string, body: PostTaskAttachmentsBody, query?: PostTaskAttachmentsQuery): Promise<[PostTaskAttachments200Response | null, number, PostTaskAttachments404Response | null]> {
    const url = `${this.basePath}/tasks/${taskId}/attachments`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostTaskAttachments200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostTaskAttachments200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostTaskAttachments404Response]
      }

      throw err
    }

  }

  public async getTaskAttachmentDownload(taskId: string, taskAttachmentId: string): Promise<[GetTaskAttachmentDownload200Response | null, number, any]> {
    const url = `${this.basePath}/tasks/${taskId}/attachments/${taskAttachmentId}/download`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetTaskAttachmentDownload200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetTaskAttachmentDownload200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postTaskAttachmentDelete(taskId: string, attachmentId: string): Promise<[TaskAttachmentFile | null, number, PostTaskAttachmentDelete404Response | null]> {
    const url = `${this.basePath}/tasks/${taskId}/attachments/${attachmentId}/delete`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<TaskAttachmentFile>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as TaskAttachmentFile, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostTaskAttachmentDelete404Response]
      }

      throw err
    }

  }

  public async postTaskAttachmentFinished(taskId: string, taskAttachmentId: string): Promise<[TaskAttachmentFile | null, number, PostTaskAttachmentFinished404Response | null]> {
    const url = `${this.basePath}/tasks/${taskId}/attachments/${taskAttachmentId}/finished`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<TaskAttachmentFile>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as TaskAttachmentFile, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostTaskAttachmentFinished404Response]
      }

      throw err
    }

  }

  public async postTaskAttachmentError(taskId: string, taskAttachmentId: string): Promise<[TaskAttachmentFile | null, number, PostTaskAttachmentError404Response | null]> {
    const url = `${this.basePath}/tasks/${taskId}/attachments/${taskAttachmentId}/error`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<TaskAttachmentFile>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as TaskAttachmentFile, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostTaskAttachmentError404Response]
      }

      throw err
    }

  }

  public async postTaskSubtask(taskId: string, subtaskId: string, body: PostTaskSubtaskBody, query?: PostTaskSubtaskQuery): Promise<[Subtask | AssetSubtask | null, number, PostTaskSubtask400Response | PostTaskSubtask404Response | null]> {
    const url = `${this.basePath}/tasks/${taskId}/subtasks/${subtaskId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Subtask | AssetSubtask>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Subtask | AssetSubtask, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostTaskSubtask400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostTaskSubtask404Response]
      }

      throw err
    }

  }

}

@Injectable({
  providedIn: 'root'
})
export class AgencyApiService extends AbstractService {

  constructor(
    @Inject(BASE_PATH) private basePath: string,
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super()
  }


  public async getAgencies(query?: GetAgenciesQuery): Promise<[GetAgencies200Response | null, number, any]> {
    const url = `${this.basePath}/agencies`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAgencies200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAgencies200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postAgencies(body: PostAgenciesBody): Promise<[Order | null, number, PostAgencies400Response | null]> {
    const url = `${this.basePath}/agencies`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Order>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Order, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostAgencies400Response]
      }

      throw err
    }

  }

  public async getAgencyUsers(agencyId: string, query?: GetAgencyUsersQuery): Promise<[GetAgencyUsers200Response | null, number, GetAgencyUsers404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/users`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAgencyUsers200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAgencyUsers200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAgencyUsers404Response]
      }

      throw err
    }

  }

  public async getAgencyStatistic(agencyId: string): Promise<[AgencyStatistic | null, number, GetAgencyStatistic401Response | GetAgencyStatistic404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/statistic`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<AgencyStatistic>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as AgencyStatistic, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 401) return [null, response.status, response.error as unknown as GetAgencyStatistic401Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetAgencyStatistic404Response]
      }

      throw err
    }

  }

  public async getAgencyContracts(agencyId: string, query?: GetAgencyContractsQuery): Promise<[GetAgencyContracts200Response | null, number, GetAgencyContracts404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/contracts`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAgencyContracts200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAgencyContracts200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAgencyContracts404Response]
      }

      throw err
    }

  }

  public async getAgencyServiceUser(agencyId: string, userId: string): Promise<[ServiceUser | null, number, GetAgencyServiceUser404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/serviceusers/${userId}`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<ServiceUser>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as ServiceUser, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAgencyServiceUser404Response]
      }

      throw err
    }

  }

  public async postAgencyServiceUser(agencyId: string, userId: string, body: PostAgencyServiceUserBody): Promise<[ServiceUser | null, number, PostAgencyServiceUser404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/serviceusers/${userId}`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<ServiceUser>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as ServiceUser, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostAgencyServiceUser404Response]
      }

      throw err
    }

  }

  public async getAgencyServiceUserApiKeys(agencyId: string, userId: string): Promise<[GetAgencyServiceUserApiKeys200Response | null, number, GetAgencyServiceUserApiKeys404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/serviceusers/${userId}/apikeys`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAgencyServiceUserApiKeys200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAgencyServiceUserApiKeys200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAgencyServiceUserApiKeys404Response]
      }

      throw err
    }

  }

  public async postAgencyServiceUserApiKeys(agencyId: string, userId: string): Promise<[PostAgencyServiceUserApiKeys200Response | null, number, PostAgencyServiceUserApiKeys404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/serviceusers/${userId}/apikeys`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostAgencyServiceUserApiKeys200Response>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostAgencyServiceUserApiKeys200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostAgencyServiceUserApiKeys404Response]
      }

      throw err
    }

  }

  public async getAgencyServiceUsers(agencyId: string): Promise<[GetAgencyServiceUsers200Response | null, number, GetAgencyServiceUsers404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/serviceusers`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAgencyServiceUsers200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAgencyServiceUsers200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAgencyServiceUsers404Response]
      }

      throw err
    }

  }

  public async postAgencyServiceUsers(agencyId: string, body: PostAgencyServiceUsersBody): Promise<[ServiceUser | null, number, PostAgencyServiceUsers404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/serviceusers`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<ServiceUser>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as ServiceUser, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostAgencyServiceUsers404Response]
      }

      throw err
    }

  }

  public async getAgencyInvitations(agencyId: string, query?: GetAgencyInvitationsQuery): Promise<[GetAgencyInvitations200Response | null, number, GetAgencyInvitations403Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/invitations`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAgencyInvitations200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAgencyInvitations200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetAgencyInvitations403Response]
      }

      throw err
    }

  }

  public async postAgencyInvitations(agencyId: string, body: PostAgencyInvitationsBody): Promise<[Invitation | null, number, PostAgencyInvitations403Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/invitations`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Invitation>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Invitation, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAgencyInvitations403Response]
      }

      throw err
    }

  }

  public async getAgencyRoles(agencyId: string, query?: GetAgencyRolesQuery): Promise<[GetAgencyRoles200Response | null, number, GetAgencyRoles404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/roles`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAgencyRoles200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAgencyRoles200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAgencyRoles404Response]
      }

      throw err
    }

  }

  public async getAgencyTags(agencyId: string): Promise<[AgencyTags | null, number, any]> {
    const url = `${this.basePath}/${agencyId}/tags`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<AgencyTags>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as AgencyTags, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postAgencyInvitationDelete(agencyId: string, invitationId: string, query?: PostAgencyInvitationDeleteQuery): Promise<[Invitation | null, number, PostAgencyInvitationDelete403Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/invitations/${invitationId}/delete`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Invitation>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Invitation, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAgencyInvitationDelete403Response]
      }

      throw err
    }

  }

  public async postAgencyServiceUserApiKeyDelete(agencyId: string, userId: string, apikeymd5: string): Promise<[ServiceAuthUser | null, number, PostAgencyServiceUserApiKeyDelete404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/serviceusers/${userId}/apikeys/${apikeymd5}/delete`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<ServiceAuthUser>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as ServiceAuthUser, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostAgencyServiceUserApiKeyDelete404Response]
      }

      throw err
    }

  }

  public async postAgencyServiceUserDelete(agencyId: string, userId: string): Promise<[ServiceUser | null, number, PostAgencyServiceUserDelete404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/serviceusers/${userId}/delete`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<ServiceUser>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as ServiceUser, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostAgencyServiceUserDelete404Response]
      }

      throw err
    }

  }

  public async getAgencyGraphFile(agencyId: string, graphFileId: string, query?: GraphsGetGraphFileQuery): Promise<[GraphFile | null, number, any]> {
    const url = `${this.basePath}/agencies/${agencyId}/graphs/files/${graphFileId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GraphFile>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GraphFile, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getAgencyGraphFiles(agencyId: string, query?: GetAgencyGraphFilesQuery): Promise<[GetAgencyGraphFiles200Response | null, number, any]> {
    const url = `${this.basePath}/agencies/${agencyId}/graphs/files`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAgencyGraphFiles200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAgencyGraphFiles200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postAgencyGraphFiles(agencyId: string, body: PostAgencyGraphFilesBody, query?: GraphsPostGraphFilesQuery): Promise<[PostAgencyGraphFiles200Response | null, number, any]> {
    const url = `${this.basePath}/agencies/${agencyId}/files`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostAgencyGraphFiles200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostAgencyGraphFiles200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postAgencyGraphFileDelete(agencyId: string, graphFileId: string): Promise<[GraphFile | null, number, any]> {
    const url = `${this.basePath}/agencies/${agencyId}/files/${graphFileId}/delete`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<GraphFile>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GraphFile, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postAgencyGraphFileFinished(agencyId: string, graphFileId: string): Promise<[GraphFile | null, number, any]> {
    const url = `${this.basePath}/agencies/${agencyId}/files/${graphFileId}/finished`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<GraphFile>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GraphFile, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postAgencyGraphFileError(agencyId: string, graphFileId: string): Promise<[GraphFile | null, number, any]> {
    const url = `${this.basePath}/agencies/${agencyId}/files/${graphFileId}/error`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<GraphFile>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GraphFile, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getAgencyGraphFolder(agencyId: string, graphFolderId: string, query?: GraphsGetGraphFolderQuery): Promise<[GraphFolder | null, number, any]> {
    const url = `${this.basePath}/agencies/${agencyId}/graphs/folders/${graphFolderId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GraphFolder>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GraphFolder, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getAgencyGraphFolders(agencyId: string, query?: GetAgencyGraphFoldersQuery): Promise<[GetAgencyGraphFolders200Response | null, number, any]> {
    const url = `${this.basePath}/agencies/${agencyId}/graphs/folders`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAgencyGraphFolders200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAgencyGraphFolders200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postAgencyGraphFolders(agencyId: string, body: PostAgencyGraphFoldersBody): Promise<[GraphFolder | null, number, any]> {
    const url = `${this.basePath}/agencies/${agencyId}/folders`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<GraphFolder>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GraphFolder, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postAgencyGraphFolder(agencyId: string, graphFolderId: string, body: PostAgencyGraphFolderBody): Promise<[GraphFolder | null, number, any]> {
    const url = `${this.basePath}/agencies/${agencyId}/folders/${graphFolderId}`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<GraphFolder>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GraphFolder, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postAgencyGraphFolderDelete(agencyId: string, graphFolderId: string): Promise<[GraphFolder | null, number, any]> {
    const url = `${this.basePath}/agencies/${agencyId}/folders/${graphFolderId}/delete`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<GraphFolder>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GraphFolder, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getAgencyGraphsStepTemplates(agencyId: string, query?: GetAgencyGraphsStepTemplatesQuery): Promise<[GetAgencyGraphsStepTemplates200Response | null, number, any]> {
    const url = `${this.basePath}/agencies/${agencyId}/graphs/steptemplates`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAgencyGraphsStepTemplates200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAgencyGraphsStepTemplates200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getAgencyGraphsUnsaved(agencyId: string, query?: GetAgencyGraphsUnsavedQuery): Promise<[GetAgencyGraphsUnsaved200Response | null, number, any]> {
    const url = `${this.basePath}/agencies/${agencyId}/graphs/unsaved`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAgencyGraphsUnsaved200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAgencyGraphsUnsaved200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getAgencyGraphs(agencyId: string, query?: GetAgencyGraphsQuery): Promise<[GetAgencyGraphs200Response | null, number, any]> {
    const url = `${this.basePath}/agencies/${agencyId}/graphs`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAgencyGraphs200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAgencyGraphs200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postAgencyGraphs(agencyId: string, body: PostAgencyGraphsBody, query?: GraphsPostGraphsQuery): Promise<[Graph | null, number, any]> {
    const url = `${this.basePath}/agencies/${agencyId}/graphs`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Graph>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Graph, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getAgencyGraph(agencyId: string, graphId: string, query?: GetAgencyGraphQuery): Promise<[Graph | null, number, GetAgencyGraph404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/graphs/${graphId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<Graph>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Graph, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAgencyGraph404Response]
      }

      throw err
    }

  }

  public async postAgencyGraph(agencyId: string, graphId: string, body: PostAgencyGraphBody, query?: GraphsPostGraphQuery): Promise<[Graph | null, number, any]> {
    const url = `${this.basePath}/agencies/${agencyId}/graphs/${graphId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Graph>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Graph, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getAgencyGraphGraphVersionSamples(agencyId: string, graphId: string, graphVersionId: string, query?: GraphsGetGraphGraphVersionSamplesQuery): Promise<[GetAgencyGraphGraphVersionSamples200Response | null, number, GetAgencyGraphGraphVersionSamples404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/graphs/${graphId}/graphversions/${graphVersionId}/samples`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAgencyGraphGraphVersionSamples200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAgencyGraphGraphVersionSamples200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAgencyGraphGraphVersionSamples404Response]
      }

      throw err
    }

  }

  public async postAgencyGraphGraphVersionSamples(agencyId: string, graphId: string, graphVersionId: string, body: PostAgencyGraphGraphVersionSamplesBody, query?: GraphsPostGraphGraphVersionSamplesQuery): Promise<[Sample | PlainSample | OrderLineSample | null, number, any]> {
    const url = `${this.basePath}/agencies/${agencyId}/graphs/${graphId}/graphversions/${graphVersionId}/samples`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Sample | PlainSample | OrderLineSample>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Sample | PlainSample | OrderLineSample, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getAgencyGraphGraphVersions(agencyId: string, graphId: string, query?: GetAgencyGraphGraphVersionsQuery): Promise<[GetAgencyGraphGraphVersions200Response | null, number, GetAgencyGraphGraphVersions404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/graphs/${graphId}/graphversions`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAgencyGraphGraphVersions200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAgencyGraphGraphVersions200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAgencyGraphGraphVersions404Response]
      }

      throw err
    }

  }

  public async postAgencyGraphGraphVersions(agencyId: string, graphId: string, body: PostAgencyGraphGraphVersionsBody, query?: GraphsPostGraphGraphVersionsQuery): Promise<[GraphVersion | null, number, PostAgencyGraphGraphVersions404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/graphs/${graphId}/graphversions`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<GraphVersion>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GraphVersion, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostAgencyGraphGraphVersions404Response]
      }

      throw err
    }

  }

  public async getAgencyGraphGraphVersion(agencyId: string, graphId: string, graphVersionId: string, query?: GraphsGetGraphGraphVersionQuery): Promise<[GraphVersion | null, number, GetAgencyGraphGraphVersion404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/graphs/${graphId}/graphversions/${graphVersionId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GraphVersion>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GraphVersion, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAgencyGraphGraphVersion404Response]
      }

      throw err
    }

  }

  public async postAgencyGraphGraphVersion(agencyId: string, graphId: string, graphVersionId: string, body: PostAgencyGraphGraphVersionBody, query?: GraphsPostGraphGraphVersionQuery): Promise<[GraphVersion | null, number, PostAgencyGraphGraphVersion404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/graphs/${graphId}/graphversions/${graphVersionId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<GraphVersion>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GraphVersion, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostAgencyGraphGraphVersion404Response]
      }

      throw err
    }

  }

  public async postAgencyGraphGraphVersionSample(agencyId: string, graphId: string, graphVersionId: string, sampleId: string, body: PostAgencyGraphGraphVersionSampleBody, query?: GraphsPostGraphGraphVersionSampleQuery): Promise<[Sample | PlainSample | OrderLineSample | null, number, any]> {
    const url = `${this.basePath}/agencies/${agencyId}/graphs/${graphId}/graphversions/${graphVersionId}/samples/${sampleId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Sample | PlainSample | OrderLineSample>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Sample | PlainSample | OrderLineSample, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postAgencyGraphGraphVersionSampleDebug(agencyId: string, graphId: string, graphVersionId: string, sampleId: string, body: PostAgencyGraphGraphVersionSampleDebugBody): Promise<[PostAgencyGraphGraphVersionSampleDebug200Response | null, number, PostAgencyGraphGraphVersionSampleDebug400Response | PostAgencyGraphGraphVersionSampleDebug404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/graphs/${graphId}/graphversions/${graphVersionId}/samples/${sampleId}/debug`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostAgencyGraphGraphVersionSampleDebug200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostAgencyGraphGraphVersionSampleDebug200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostAgencyGraphGraphVersionSampleDebug400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostAgencyGraphGraphVersionSampleDebug404Response]
      }

      throw err
    }

  }

  public async postAgencyGraphGraphVersionSampleDelete(agencyId: string, graphId: string, graphVersionId: string, sampleId: string): Promise<[Sample | PlainSample | OrderLineSample | null, number, PostAgencyGraphGraphVersionSampleDelete404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/graphs/${graphId}/graphversions/${graphVersionId}/samples/${sampleId}/delete`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Sample | PlainSample | OrderLineSample>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Sample | PlainSample | OrderLineSample, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostAgencyGraphGraphVersionSampleDelete404Response]
      }

      throw err
    }

  }

  public async postAgencyGraphGraphVersionSave(agencyId: string, graphId: string, graphVersionId: string, body: PostAgencyGraphGraphVersionSaveBody, query?: GraphsPostGraphGraphVersionSaveQuery): Promise<[GraphVersion | null, number, PostAgencyGraphGraphVersionSave404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/graphs/${graphId}/graphversions/${graphVersionId}/save`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<GraphVersion>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GraphVersion, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostAgencyGraphGraphVersionSave404Response]
      }

      throw err
    }

  }

  public async postAgencyGraphGraphVersionValidate(agencyId: string, graphId: string, graphVersionId: string): Promise<[PostAgencyGraphGraphVersionValidate200Response | null, number, PostAgencyGraphGraphVersionValidate404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/graphs/${graphId}/graphversions/${graphVersionId}/validate`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostAgencyGraphGraphVersionValidate200Response>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostAgencyGraphGraphVersionValidate200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostAgencyGraphGraphVersionValidate404Response]
      }

      throw err
    }

  }

}

@Injectable({
  providedIn: 'root'
})
export class JobApiService extends AbstractService {

  constructor(
    @Inject(BASE_PATH) private basePath: string,
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super()
  }


  public async getJobs(query?: GetJobsQuery): Promise<[GetJobs200Response | null, number, GetJobs403Response | null]> {
    const url = `${this.basePath}/jobs`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetJobs200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetJobs200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetJobs403Response]
      }

      throw err
    }

  }

  public async getJobStepLogs(jobId: string, stepIndexOrHash: string, query?: GetJobStepLogsQuery): Promise<[GetJobStepLogs200Response | null, number, GetJobStepLogs403Response | null]> {
    const url = `${this.basePath}/jobs/${jobId}/steps/${stepIndexOrHash}/logs`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetJobStepLogs200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetJobStepLogs200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetJobStepLogs403Response]
      }

      throw err
    }

  }

  public async getJobDownload(jobId: string): Promise<[GetJobDownload200Response | null, number, GetJobDownload403Response | null]> {
    const url = `${this.basePath}/jobs/${jobId}/download`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetJobDownload200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetJobDownload200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetJobDownload403Response]
      }

      throw err
    }

  }

  public async getJobJobOutputStepAssembly(jobId: string, jobOutputId: string, stepId: string): Promise<[GetJobJobOutputStepAssembly200Response | null, number, GetJobJobOutputStepAssembly403Response | null]> {
    const url = `${this.basePath}/jobs/${jobId}/joboutputs/${jobOutputId}/steps/${stepId}/assembly`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetJobJobOutputStepAssembly200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetJobJobOutputStepAssembly200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetJobJobOutputStepAssembly403Response]
      }

      throw err
    }

  }

  public async getJobJobOutputStepOuputDownload(jobId: string, jobOutputId: string, stepId: string, output: string, query?: JobsGetJobJobOutputStepOuputDownloadQuery): Promise<[GetJobJobOutputStepOuputDownload200Response | null, number, GetJobJobOutputStepOuputDownload400Response | GetJobJobOutputStepOuputDownload403Response | GetJobJobOutputStepOuputDownload404Response | null]> {
    const url = `${this.basePath}/jobs/${jobId}/joboutputs/${jobOutputId}/steps/${stepId}/outputs/${output}/download`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetJobJobOutputStepOuputDownload200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetJobJobOutputStepOuputDownload200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as GetJobJobOutputStepOuputDownload400Response]
      if (response.status === 403) return [null, response.status, response.error as unknown as GetJobJobOutputStepOuputDownload403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetJobJobOutputStepOuputDownload404Response]
      }

      throw err
    }

  }

  public async getJobsCount(query?: GetJobsCountQuery): Promise<[GetJobsCount200Response | null, number, GetJobsCount403Response | null]> {
    const url = `${this.basePath}/jobs/count`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetJobsCount200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetJobsCount200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetJobsCount403Response]
      }

      throw err
    }

  }

  public async getJob(jobId: string, query?: JobsGetJobQuery): Promise<[Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob | null, number, GetJob403Response | null]> {
    const url = `${this.basePath}/jobs/${jobId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetJob403Response]
      }

      throw err
    }

  }

  public async postJob(jobId: string, body: PostJobBody): Promise<[Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob | null, number, PostJob404Response | null]> {
    const url = `${this.basePath}/jobs/${jobId}`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostJob404Response]
      }

      throw err
    }

  }

  public async postJobCancel(jobId: string): Promise<[Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob | null, number, PostJobCancel400Response | PostJobCancel404Response | null]> {
    const url = `${this.basePath}/jobs/${jobId}/cancel`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostJobCancel400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostJobCancel404Response]
      }

      throw err
    }

  }

  public async postJobPause(jobId: string, body: PostJobPauseBody): Promise<[Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob | null, number, PostJobPause400Response | PostJobPause404Response | null]> {
    const url = `${this.basePath}/jobs/${jobId}/pause`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostJobPause400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostJobPause404Response]
      }

      throw err
    }

  }

  public async postJobContinue(jobId: string, body: PostJobContinueBody): Promise<[Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob | null, number, PostJobContinue400Response | PostJobContinue404Response | null]> {
    const url = `${this.basePath}/jobs/${jobId}/continue`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostJobContinue400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostJobContinue404Response]
      }

      throw err
    }

  }

  public async postJobRestart(jobId: string, body: PostJobRestartBody): Promise<[Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob | null, number, PostJobRestart400Response | PostJobRestart404Response | null]> {
    const url = `${this.basePath}/jobs/${jobId}/restart`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostJobRestart400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostJobRestart404Response]
      }

      throw err
    }

  }

}

@Injectable({
  providedIn: 'root'
})
export class UserApiService extends AbstractService {

  constructor(
    @Inject(BASE_PATH) private basePath: string,
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super()
  }


  public async getUserHasRights(userId: string, query: GetUserHasRightsQuery): Promise<[GetUserHasRights200Response | null, number, GetUserHasRights403Response | null]> {
    const url = `${this.basePath}/users/${userId}/hasrights`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetUserHasRights200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetUserHasRights200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetUserHasRights403Response]
      }

      throw err
    }

  }

  public async getUserAgencies(userId: string): Promise<[GetUserAgencies200Response | null, number, any]> {
    const url = `${this.basePath}/users/${userId}/agencies`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetUserAgencies200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetUserAgencies200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getUserInvitations(userId: string): Promise<[GetUserInvitations200Response | null, number, GetUserInvitations403Response | null]> {
    const url = `${this.basePath}/users/${userId}/invitations`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetUserInvitations200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetUserInvitations200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetUserInvitations403Response]
      }

      throw err
    }

  }

  public async postUserInvitationAccept(userId: string, invitationId: string): Promise<[Invitation | null, number, PostUserInvitationAccept403Response | PostUserInvitationAccept404Response | null]> {
    const url = `${this.basePath}/${userId}/invitations/${invitationId}/accept`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Invitation>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Invitation, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostUserInvitationAccept403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostUserInvitationAccept404Response]
      }

      throw err
    }

  }

  public async postUserInvitationDecline(userId: string, invitationId: string): Promise<[Invitation | null, number, PostUserInvitationDecline403Response | PostUserInvitationDecline404Response | null]> {
    const url = `${this.basePath}/${userId}/invitations/${invitationId}/decline`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Invitation>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Invitation, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostUserInvitationDecline403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostUserInvitationDecline404Response]
      }

      throw err
    }

  }

}

@Injectable({
  providedIn: 'root'
})
export class AssetApiService extends AbstractService {

  constructor(
    @Inject(BASE_PATH) private basePath: string,
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super()
  }


  public async getAssetsPreviewTemplates(query?: GetAssetsPreviewTemplatesQuery): Promise<[GetAssetsPreviewTemplates200Response | null, number, any]> {
    const url = `${this.basePath}/assets/previewtemplates`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetsPreviewTemplates200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetsPreviewTemplates200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getAssetsPreviewTemplate(previewTemplateId: string, query?: AssetsGetAssetsPreviewTemplateQuery): Promise<[PreviewTemplate | null, number, GetAssetsPreviewTemplate404Response | null]> {
    const url = `${this.basePath}/assets/previewtemplates/${previewTemplateId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<PreviewTemplate>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PreviewTemplate, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetsPreviewTemplate404Response]
      }

      throw err
    }

  }

  public async getAssets(query?: GetAssetsQuery): Promise<[GetAssets200Response | null, number, GetAssets403Response | null]> {
    const url = `${this.basePath}/assets`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssets200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssets200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetAssets403Response]
      }

      throw err
    }

  }

  public async getAsset(assetId: string, query?: GetAssetQuery): Promise<[Asset | null, number, GetAsset404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<Asset>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Asset, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAsset404Response]
      }

      throw err
    }

  }

  public async postAsset(assetId: string, body: PostAssetBody): Promise<[Asset | null, number, PostAsset404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Asset>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Asset, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostAsset404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersionHistory(assetId: string, assetVersionId: string, query?: GetAssetAssetVersionHistoryQuery): Promise<[GetAssetAssetVersionHistory200Response | null, number, GetAssetAssetVersionHistory404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/history`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetAssetVersionHistory200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetAssetVersionHistory200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersionHistory404Response]
      }

      throw err
    }

  }

  public async getAssetHistory(assetId: string, query?: GetAssetHistoryQuery): Promise<[GetAssetHistory200Response | null, number, GetAssetHistory404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/history`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetHistory200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetHistory200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetHistory404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersions(assetId: string, query?: GetAssetAssetVersionsQuery): Promise<[GetAssetAssetVersions200Response | null, number, GetAssetAssetVersions400Response | GetAssetAssetVersions403Response | GetAssetAssetVersions404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetAssetVersions200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetAssetVersions200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as GetAssetAssetVersions400Response]
      if (response.status === 403) return [null, response.status, response.error as unknown as GetAssetAssetVersions403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersions404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersion(assetId: string, assetVersionId: string, query?: GetAssetAssetVersionQuery): Promise<[AssetVersion | ComfyAssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion | null, number, GetAssetAssetVersion404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<AssetVersion | ComfyAssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as AssetVersion | ComfyAssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersion404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersionDownload(assetId: string, assetVersionId: string): Promise<[GetAssetAssetVersionDownload200Response | null, number, GetAssetAssetVersionDownload400Response | GetAssetAssetVersionDownload404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/download`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetAssetVersionDownload200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetAssetVersionDownload200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as GetAssetAssetVersionDownload400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersionDownload404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersionDownloadKey(assetId: string, assetVersionId: string, downloadKey: string): Promise<[GetAssetAssetVersionDownloadKey200Response | null, number, GetAssetAssetVersionDownloadKey400Response | GetAssetAssetVersionDownloadKey404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/download/${downloadKey}`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetAssetVersionDownloadKey200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetAssetVersionDownloadKey200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as GetAssetAssetVersionDownloadKey400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersionDownloadKey404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersionVariantSets(assetId: string, assetVersionId: string, query?: GetAssetAssetVersionVariantSetsQuery): Promise<[GetAssetAssetVersionVariantSets200Response | null, number, GetAssetAssetVersionVariantSets404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/variantsets`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetAssetVersionVariantSets200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetAssetVersionVariantSets200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersionVariantSets404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersionPreviewSets(assetId: string, assetVersionId: string, query?: GetAssetAssetVersionPreviewSetsQuery): Promise<[GetAssetAssetVersionPreviewSets200Response | null, number, GetAssetAssetVersionPreviewSets403Response | GetAssetAssetVersionPreviewSets404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/previewsets`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetAssetVersionPreviewSets200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetAssetVersionPreviewSets200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetAssetAssetVersionPreviewSets403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersionPreviewSets404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionPreviewSets(assetId: string, assetVersionId: string): Promise<[PreviewSet | null, number, PostAssetAssetVersionPreviewSets403Response | PostAssetAssetVersionPreviewSets404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/previewsets`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PreviewSet>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PreviewSet, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSets403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSets404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersionBlockedVariantSelections(assetId: string, assetVersionId: string, query?: GetAssetAssetVersionBlockedVariantSelectionsQuery): Promise<[GetAssetAssetVersionBlockedVariantSelections200Response | null, number, GetAssetAssetVersionBlockedVariantSelections404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/blockedvariantselections`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetAssetVersionBlockedVariantSelections200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetAssetVersionBlockedVariantSelections200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersionBlockedVariantSelections404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionBlockedVariantSelections(assetId: string, assetVersionId: string, body: PostAssetAssetVersionBlockedVariantSelectionsBody): Promise<[PostAssetAssetVersionBlockedVariantSelections200Response | null, number, PostAssetAssetVersionBlockedVariantSelections404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/blockedvariantselections`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostAssetAssetVersionBlockedVariantSelections200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostAssetAssetVersionBlockedVariantSelections200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionBlockedVariantSelections404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersionFiles(assetId: string, assetVersionId: string, query?: GetAssetAssetVersionFilesQuery): Promise<[GetAssetAssetVersionFiles200Response | null, number, GetAssetAssetVersionFiles400Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/files`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetAssetVersionFiles200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetAssetVersionFiles200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as GetAssetAssetVersionFiles400Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersionFile(assetId: string, assetVersionId: string, fileId: string): Promise<[AssetVersionFile | null, number, GetAssetAssetVersionFile400Response | GetAssetAssetVersionFile404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/files/${fileId}`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<AssetVersionFile>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as AssetVersionFile, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as GetAssetAssetVersionFile400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersionFile404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersionFileDownload(assetId: string, assetVersionId: string, fileId: string): Promise<[GetAssetAssetVersionFileDownload200Response | null, number, GetAssetAssetVersionFileDownload400Response | GetAssetAssetVersionFileDownload404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/files/${fileId}/download`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetAssetVersionFileDownload200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetAssetVersionFileDownload200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as GetAssetAssetVersionFileDownload400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersionFileDownload404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersionFileHistory(assetId: string, assetVersionId: string, fileId: string): Promise<[GetAssetAssetVersionFileHistory200Response | null, number, GetAssetAssetVersionFileHistory400Response | GetAssetAssetVersionFileHistory404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/files/${fileId}/history`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetAssetVersionFileHistory200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetAssetVersionFileHistory200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as GetAssetAssetVersionFileHistory400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersionFileHistory404Response]
      }

      throw err
    }

  }

  public async getAssetComments(assetId: string, query?: GetAssetCommentsQuery): Promise<[GetAssetComments200Response | null, number, GetAssetComments404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/comments`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetComments200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetComments200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetComments404Response]
      }

      throw err
    }

  }

  public async postAssetComments(assetId: string, body: PostAssetCommentsBody): Promise<[AssetComment | null, number, any]> {
    const url = `${this.basePath}/assets/${assetId}/comments`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<AssetComment>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as AssetComment, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postAssetAssetVersionSubmit(assetId: string, assetVersionId: string): Promise<[AssetVersion | ComfyAssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion | null, number, PostAssetAssetVersionSubmit403Response | PostAssetAssetVersionSubmit404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/submit`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<AssetVersion | ComfyAssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as AssetVersion | ComfyAssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAssetAssetVersionSubmit403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionSubmit404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionPublish(assetId: string, assetVersionId: string): Promise<[AssetVersion | ComfyAssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion | null, number, PostAssetAssetVersionPublish403Response | PostAssetAssetVersionPublish404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/publish`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<AssetVersion | ComfyAssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as AssetVersion | ComfyAssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAssetAssetVersionPublish403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionPublish404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionReject(assetId: string, assetVersionId: string): Promise<[AssetVersion | ComfyAssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion | null, number, PostAssetAssetVersionReject403Response | PostAssetAssetVersionReject404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/reject`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<AssetVersion | ComfyAssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as AssetVersion | ComfyAssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAssetAssetVersionReject403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionReject404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionBlockedVariantSelectionsDelete(assetId: string, assetVersionId: string, body: PostAssetAssetVersionBlockedVariantSelectionsDeleteBody): Promise<[PostAssetAssetVersionBlockedVariantSelectionsDelete200Response | null, number, PostAssetAssetVersionBlockedVariantSelectionsDelete404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/blockedvariantselections/delete`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostAssetAssetVersionBlockedVariantSelectionsDelete200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostAssetAssetVersionBlockedVariantSelectionsDelete200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionBlockedVariantSelectionsDelete404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionPreviewSetPreviews(assetId: string, assetVersionId: string, previewSetId: string, body: PostAssetAssetVersionPreviewSetPreviewsBody): Promise<[GeneratedPreview | OrderLinePreview | UploadPreview | null, number, PostAssetAssetVersionPreviewSetPreviews403Response | PostAssetAssetVersionPreviewSetPreviews404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/previewsets/${previewSetId}/previews`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<GeneratedPreview | OrderLinePreview | UploadPreview>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GeneratedPreview | OrderLinePreview | UploadPreview, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetPreviews403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetPreviews404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionPreviewSetPreviewError(assetId: string, assetVersionId: string, previewSetId: string, previewId: string): Promise<[UploadPreview | null, number, PostAssetAssetVersionPreviewSetPreviewError403Response | PostAssetAssetVersionPreviewSetPreviewError404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/previewsets/${previewSetId}/preview/${previewId}/error`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<UploadPreview>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as UploadPreview, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetPreviewError403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetPreviewError404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionPreviewSetPreviewFinished(assetId: string, assetVersionId: string, previewSetId: string, previewId: string): Promise<[UploadPreview | null, number, PostAssetAssetVersionPreviewSetPreviewFinished403Response | PostAssetAssetVersionPreviewSetPreviewFinished404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/previewsets/${previewSetId}/preview/${previewId}/finished`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<UploadPreview>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as UploadPreview, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetPreviewFinished403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetPreviewFinished404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionPreviewSetPreviewDelete(assetId: string, assetVersionId: string, previewSetId: string, previewId: string): Promise<[GeneratedPreview | OrderLinePreview | UploadPreview | null, number, PostAssetAssetVersionPreviewSetPreviewDelete403Response | PostAssetAssetVersionPreviewSetPreviewDelete404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/previewsets/${previewSetId}/preview/${previewId}/delete`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<GeneratedPreview | OrderLinePreview | UploadPreview>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GeneratedPreview | OrderLinePreview | UploadPreview, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetPreviewDelete403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetPreviewDelete404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionPreviewSetProcess(assetId: string, assetVersionId: string, previewSetId: string): Promise<[PreviewSet | null, number, PostAssetAssetVersionPreviewSetProcess403Response | PostAssetAssetVersionPreviewSetProcess404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/previewsets/${previewSetId}/process`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PreviewSet>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PreviewSet, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetProcess403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetProcess404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionPreviewSetPublish(assetId: string, assetVersionId: string, previewSetId: string): Promise<[PreviewSet | null, number, PostAssetAssetVersionPreviewSetPublish403Response | PostAssetAssetVersionPreviewSetPublish404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/previewsets/${previewSetId}/publish`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PreviewSet>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PreviewSet, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetPublish403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetPublish404Response]
      }

      throw err
    }

  }

  public async postAssetDelete(assetId: string): Promise<[Asset | null, number, any]> {
    const url = `${this.basePath}/assets/${assetId}/delete`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Asset>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Asset, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

}

@Injectable({
  providedIn: 'root'
})
export class OrderApiService extends AbstractService {

  constructor(
    @Inject(BASE_PATH) private basePath: string,
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super()
  }


  public async getOrderParsers(query?: GetOrderParsersQuery): Promise<[GetOrderParsers200Response | null, number, GetOrderParsers403Response | GetOrderParsers404Response | null]> {
    const url = `${this.basePath}/orders/orderparsers`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderParsers200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderParsers200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderParsers403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderParsers404Response]
      }

      throw err
    }

  }

  public async postOrderParsers(body: PostOrderParsersBody): Promise<[OrderParser | null, number, any]> {
    const url = `${this.basePath}/orders/orderparsers`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderParser>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderParser, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getOrder(orderId: string, query?: GetOrderQuery): Promise<[Order | null, number, GetOrder403Response | GetOrder404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<Order>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Order, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrder403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrder404Response]
      }

      throw err
    }

  }

  public async postOrder(orderId: string, body: PostOrderBody): Promise<[Order | null, number, PostOrder404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Order>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Order, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostOrder404Response]
      }

      throw err
    }

  }

  public async getOrders(query?: GetOrdersQuery): Promise<[GetOrders200Response | null, number, any]> {
    const url = `${this.basePath}/orders`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrders200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrders200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postOrders(body: PostOrdersBody): Promise<[Order | null, number, PostOrders400Response | PostOrders403Response | null]> {
    const url = `${this.basePath}/orders`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Order>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Order, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostOrders400Response]
      if (response.status === 403) return [null, response.status, response.error as unknown as PostOrders403Response]
      }

      throw err
    }

  }

  public async getOrderOrderLinesLocks(orderId: string): Promise<[GetOrderOrderLinesLocks200Response | null, number, GetOrderOrderLinesLocks403Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/locks`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderOrderLinesLocks200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderOrderLinesLocks200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderOrderLinesLocks403Response]
      }

      throw err
    }

  }

  public async getOrderOrderLinesDownloadKey(orderId: string, downloadKey: string): Promise<[GetOrderOrderLinesDownloadKey200Response | null, number, any]> {
    const url = `${this.basePath}/${orderId}/orderlines/download/${downloadKey}`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderOrderLinesDownloadKey200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderOrderLinesDownloadKey200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getOrderOrderLinesAggregation(orderId: string, query?: GetOrderOrderLinesAggregationQuery): Promise<[GetOrderOrderLinesAggregation200Response | null, number, GetOrderOrderLinesAggregation403Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/aggregation`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderOrderLinesAggregation200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderOrderLinesAggregation200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderOrderLinesAggregation403Response]
      }

      throw err
    }

  }

  public async getOrderOrderLines(orderId: string, query?: GetOrderOrderLinesQuery): Promise<[GetOrderOrderLines200Response | null, number, GetOrderOrderLines403Response | GetOrderOrderLines404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderOrderLines200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderOrderLines200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderOrderLines403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderOrderLines404Response]
      }

      throw err
    }

  }

  public async getOrderSubtasks(orderId: string, query?: GetOrderSubtasksQuery): Promise<[GetOrderSubtasks200Response | null, number, GetOrderSubtasks403Response | GetOrderSubtasks404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/subtasks`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderSubtasks200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderSubtasks200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderSubtasks403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderSubtasks404Response]
      }

      throw err
    }

  }

  public async getOrderOrderLine(orderId: string, orderLineId: string, query?: GetOrderOrderLineQuery): Promise<[OrderLine | null, number, GetOrderOrderLine403Response | GetOrderOrderLine404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/${orderLineId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<OrderLine>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderLine, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderOrderLine403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderOrderLine404Response]
      }

      throw err
    }

  }

  public async getOrderOrderLineJobs(orderId: string, orderLineId: string, query?: GetOrderOrderLineJobsQuery): Promise<[GetOrderOrderLineJobs200Response | null, number, GetOrderOrderLineJobs403Response | GetOrderOrderLineJobs404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/${orderLineId}/jobs`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderOrderLineJobs200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderOrderLineJobs200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderOrderLineJobs403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderOrderLineJobs404Response]
      }

      throw err
    }

  }

  public async getOrderOrderUpload(orderId: string, orderuploadId: string, query?: GetOrderOrderUploadQuery): Promise<[OrderUpload | null, number, GetOrderOrderUpload403Response | GetOrderOrderUpload404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderuploads/${orderuploadId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<OrderUpload>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderUpload, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderOrderUpload403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderOrderUpload404Response]
      }

      throw err
    }

  }

  public async getOrderOrderUploads(orderId: string, query?: GetOrderOrderUploadsQuery): Promise<[GetOrderOrderUploads200Response | null, number, GetOrderOrderUploads403Response | GetOrderOrderUploads404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderuploads`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderOrderUploads200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderOrderUploads200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderOrderUploads403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderOrderUploads404Response]
      }

      throw err
    }

  }

  public async postOrderOrderUploads(orderId: string, body: PostOrderOrderUploadsBody): Promise<[PostOrderOrderUploads200Response | null, number, PostOrderOrderUploads400Response | PostOrderOrderUploads403Response | PostOrderOrderUploads404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderuploads`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostOrderOrderUploads200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostOrderOrderUploads200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostOrderOrderUploads400Response]
      if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderOrderUploads403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderUploads404Response]
      }

      throw err
    }

  }

  public async getOrderParser(orderparserId: string, query?: GetOrderParserQuery): Promise<[OrderParser | null, number, GetOrderParser403Response | GetOrderParser404Response | null]> {
    const url = `${this.basePath}/orders/orderparsers/${orderparserId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<OrderParser>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderParser, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderParser403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderParser404Response]
      }

      throw err
    }

  }

  public async getOrderOrderUploadOrderParsings(orderId: string, orderUploadId: string, query?: GetOrderOrderUploadOrderParsingsQuery): Promise<[GetOrderOrderUploadOrderParsings200Response | null, number, GetOrderOrderUploadOrderParsings403Response | GetOrderOrderUploadOrderParsings404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderuploads/${orderUploadId}/orderparsings`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderOrderUploadOrderParsings200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderOrderUploadOrderParsings200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderOrderUploadOrderParsings403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderOrderUploadOrderParsings404Response]
      }

      throw err
    }

  }

  public async getOrderOrderUploadOrderParsing(orderId: string, orderUploadId: string, orderParsingId: string, query?: GetOrderOrderUploadOrderParsingQuery): Promise<[OrderParsing | null, number, GetOrderOrderUploadOrderParsing403Response | GetOrderOrderUploadOrderParsing404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderuploads/${orderUploadId}/orderparsings/${orderParsingId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<OrderParsing>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderParsing, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderOrderUploadOrderParsing403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderOrderUploadOrderParsing404Response]
      }

      throw err
    }

  }

  public async getOrderOrderLineComments(orderId: string, orderLineId: string, query?: GetOrderOrderLineCommentsQuery): Promise<[GetOrderOrderLineComments200Response | null, number, GetOrderOrderLineComments404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/${orderLineId}/comments`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderOrderLineComments200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderOrderLineComments200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderOrderLineComments404Response]
      }

      throw err
    }

  }

  public async postOrderOrderLineComments(orderId: string, orderLineId: string, body: PostOrderOrderLineCommentsBody): Promise<[Comment | AssetComment | OrderLineComment | TaskComment | null, number, PostOrderOrderLineComments403Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/${orderLineId}/comments`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Comment | AssetComment | OrderLineComment | TaskComment>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Comment | AssetComment | OrderLineComment | TaskComment, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderOrderLineComments403Response]
      }

      throw err
    }

  }

  public async getOrderOrderLineHistory(orderId: string, orderLineId: string): Promise<[GetOrderOrderLineHistory200Response | null, number, GetOrderOrderLineHistory404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/${orderLineId}/history`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderOrderLineHistory200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderOrderLineHistory200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderOrderLineHistory404Response]
      }

      throw err
    }

  }

  public async postOrderOrderLinesDownload(orderId: string, body: PostOrderOrderLinesDownloadBody): Promise<[PostOrderOrderLinesDownload200Response | null, number, PostOrderOrderLinesDownload404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/download`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostOrderOrderLinesDownload200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostOrderOrderLinesDownload200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderLinesDownload404Response]
      }

      throw err
    }

  }

  public async postOrderOrderLinesCompare(orderId: string, body: PostOrderOrderLinesCompareBody): Promise<[PostOrderOrderLinesCompare200Response | null, number, PostOrderOrderLinesCompare404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/compare`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostOrderOrderLinesCompare200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostOrderOrderLinesCompare200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderLinesCompare404Response]
      }

      throw err
    }

  }

  public async postOrderOrderLinesLock(orderId: string, body: PostOrderOrderLinesLockBody): Promise<[OrderLock | null, number, PostOrderOrderLinesLock404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/lock`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderLock>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderLock, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderLinesLock404Response]
      }

      throw err
    }

  }

  public async postOrderOrderUploadValidate(orderId: string, orderUploadId: string, body: PostOrderOrderUploadValidateBody): Promise<[PostOrderOrderUploadValidate200Response | null, number, PostOrderOrderUploadValidate404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderuploads/${orderUploadId}/validate`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostOrderOrderUploadValidate200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostOrderOrderUploadValidate200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderUploadValidate404Response]
      }

      throw err
    }

  }

  public async postOrderOrderUploadPreview(orderId: string, orderUploadId: string, body: PostOrderOrderUploadPreviewBody): Promise<[PostOrderOrderUploadPreview200Response | null, number, PostOrderOrderUploadPreview403Response | PostOrderOrderUploadPreview404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderuploads/${orderUploadId}/preview`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostOrderOrderUploadPreview200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostOrderOrderUploadPreview200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderOrderUploadPreview403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderUploadPreview404Response]
      }

      throw err
    }

  }

  public async postOrderDelete(orderId: string): Promise<[Order | null, number, PostOrderDelete400Response | PostOrderDelete403Response | PostOrderDelete404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/delete`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Order>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Order, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostOrderDelete400Response]
      if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderDelete403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderDelete404Response]
      }

      throw err
    }

  }

  public async postOrderOrderUploadError(orderId: string, orderUploadId: string): Promise<[OrderUpload | null, number, PostOrderOrderUploadError400Response | PostOrderOrderUploadError403Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderuploads/${orderUploadId}/error`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderUpload>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderUpload, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostOrderOrderUploadError400Response]
      if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderOrderUploadError403Response]
      }

      throw err
    }

  }

  public async postOrderOrderUploadFinished(orderId: string, orderUploadId: string): Promise<[OrderUpload | null, number, PostOrderOrderUploadFinished400Response | PostOrderOrderUploadFinished403Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderuploads/${orderUploadId}/finished`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderUpload>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderUpload, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostOrderOrderUploadFinished400Response]
      if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderOrderUploadFinished403Response]
      }

      throw err
    }

  }

  public async postOrderOrderUploadParse(orderId: string, orderUploadId: string, body: PostOrderOrderUploadParseBody): Promise<[OrderParsing | null, number, PostOrderOrderUploadParse403Response | PostOrderOrderUploadParse404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderuploads/${orderUploadId}/parse`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderParsing>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderParsing, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderOrderUploadParse403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderUploadParse404Response]
      }

      throw err
    }

  }

  public async postOrderProcess(orderId: string, body: PostOrderProcessBody): Promise<[PostOrderProcess200Response | null, number, PostOrderProcess403Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/process`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostOrderProcess200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostOrderProcess200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderProcess403Response]
      }

      throw err
    }

  }

  public async postOrderAccept(orderId: string, body: PostOrderAcceptBody): Promise<[PostOrderAccept200Response | null, number, PostOrderAccept403Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/accept`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostOrderAccept200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostOrderAccept200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderAccept403Response]
      }

      throw err
    }

  }

  public async postOrderReject(orderId: string, body: PostOrderRejectBody): Promise<[PostOrderReject200Response | null, number, PostOrderReject403Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/reject`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostOrderReject200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostOrderReject200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderReject403Response]
      }

      throw err
    }

  }

  public async postOrderReview(orderId: string, body: PostOrderReviewBody): Promise<[PostOrderReview200Response | null, number, PostOrderReview403Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/review`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostOrderReview200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostOrderReview200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderReview403Response]
      }

      throw err
    }

  }

  public async postOrderLocksDelete(orderId: string, body: PostOrderLocksDeleteBody): Promise<[OrderLock | null, number, PostOrderLocksDelete404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/locks/delete`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderLock>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderLock, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderLocksDelete404Response]
      }

      throw err
    }

  }

  public async postOrderOrderLineAccept(orderId: string, orderLineId: string, body: PostOrderOrderLineAcceptBody, query?: PostOrderOrderLineAcceptQuery): Promise<[OrderLine | null, number, PostOrderOrderLineAccept400Response | PostOrderOrderLineAccept404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/${orderLineId}/accept`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderLine>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderLine, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostOrderOrderLineAccept400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderLineAccept404Response]
      }

      throw err
    }

  }

  public async postOrderOrderLineReject(orderId: string, orderLineId: string, body: PostOrderOrderLineRejectBody, query?: PostOrderOrderLineRejectQuery): Promise<[OrderLine | null, number, PostOrderOrderLineReject400Response | PostOrderOrderLineReject404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/${orderLineId}/reject`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderLine>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderLine, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostOrderOrderLineReject400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderLineReject404Response]
      }

      throw err
    }

  }

  public async postOrderOrderLineReview(orderId: string, orderLineId: string, query?: PostOrderOrderLineReviewQuery): Promise<[OrderLine | null, number, PostOrderOrderLineReview400Response | PostOrderOrderLineReview404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/${orderLineId}/review`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderLine>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderLine, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostOrderOrderLineReview400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderLineReview404Response]
      }

      throw err
    }

  }

  public async postOrderOrderLineProcess(orderId: string, orderLineId: string, query?: PostOrderOrderLineProcessQuery): Promise<[OrderLine | null, number, PostOrderOrderLineProcess400Response | PostOrderOrderLineProcess404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/${orderLineId}/process`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderLine>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderLine, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostOrderOrderLineProcess400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderLineProcess404Response]
      }

      throw err
    }

  }

  public async postOrderOrderLineDownloaded(orderId: string, orderLineId: string): Promise<[OrderLine | null, number, PostOrderOrderLineDownloaded403Response | PostOrderOrderLineDownloaded404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/${orderLineId}/downloaded`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderLine>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderLine, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderOrderLineDownloaded403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderLineDownloaded404Response]
      }

      throw err
    }

  }

}