import { Injectable } from '@angular/core';
import { Assembling, GetJobStepLogsQuery, GetJobsQuery, Job, JobApiService, JobDownload, JobDownloadFile, JobDownloadFileBundle, JobOutput, JobProgress, JobsGetJobJobOutputStepOuputDownloadQuery, JobsGetJobQuery, LogEntry, OrderLineJob, PreviewJob, StepHash } from 'projects/api/src/api';
import { AgencyService } from './agency.service';
import { Observable } from 'rxjs';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  readonly jobStates = ['Queued', 'Downloading', 'Running', 'Success', 'Error', 'Canceled'] as const;

  constructor(
    private jobApiService: JobApiService,
    private agencyService: AgencyService,
    private websocketService: WebsocketService,
  ) { }

  public getJob(job: string, query?: JobsGetJobQuery) {
    return this.jobApiService.getJob(job, query);
  }

  public getJobs(query: Omit<GetJobsQuery, 'agency'>) {
    return this.jobApiService.getJobs({
      agency: this.agencyService.currentSelectedAgencyId!,
      ...query
    })
  }

  public getJobsCount() {
    return this.jobApiService.getJobsCount({
      agency: this.agencyService.currentSelectedAgencyId!
    })
  }

  public getJobStepLogs(jobId: string, stepIndexOrHash: string, query?: GetJobStepLogsQuery) {
    return this.jobApiService.getJobStepLogs(jobId, stepIndexOrHash, query);
  }

  public getJobJobOutputStepAssembly(jobId: string, jobOutputId: string, stepId: string) {
    return this.jobApiService.getJobJobOutputStepAssembly(jobId, jobOutputId, stepId);
  }

  public generateDownload(jobId: string) {
    return this.jobApiService.getJobDownload(jobId);
  }

  public progress(job: string): Observable<JobProgress> {
    return this.websocketService.filtered<JobProgress>('job', job, 'job:progress', (j) => j.job === job)
  }

  public register(job: string): Observable<Job> {
    return this.websocketService.filtered<Job>('job', job, 'job:update', (j) => j._id === job)
  }

  public logs(job: string, stepHash: string): Observable<LogEntry> {
    return this.websocketService.filtered<LogEntry>('job', job, 'job:logentry', (j) => j.job === job && j.step === stepHash)
  }

  public isOrderLineJob(job: Job): job is OrderLineJob {
    return job.type === 'OrderLine'
  }

  public isPreviewJob(job: Job): job is PreviewJob {
    return job.type === 'Preview'
  }

  public cancel(jobId: string) {
    return this.jobApiService.postJobCancel(jobId)
  }

  public pause(jobId: string, stepHash?: StepHash) {
    return this.jobApiService.postJobPause(jobId, {stepHash})
  }

  public restart(jobId: string, body: {assemblings: Assembling[], clearCache?: boolean, pauseAt?: StepHash}) {
    return this.jobApiService.postJobRestart(jobId, body)
  }

  public continue(jobId: string, pauseAt?: StepHash) {
    return this.jobApiService.postJobContinue(jobId, {pauseAt})
  }

  public downloadOutput(jobId: string, jobOutputId: string, stepId: string, output: string, query?: JobsGetJobJobOutputStepOuputDownloadQuery) {
    return this.jobApiService.getJobJobOutputStepOuputDownload(jobId, jobOutputId, stepId, output, query)
  }

  public isJobDownloadFile(jobDownload?: JobDownload | null): jobDownload is JobDownloadFile {
    return jobDownload?.type === 'File'
  }

  public isJobDownloadFileBundle(jobDownload?: JobDownload | null): jobDownload is JobDownloadFileBundle {
    return jobDownload?.type === 'FileBundle'
  }

  public getAssembling(job?: Job | null, jobOutput?: JobOutput | null): Assembling | null {
    return job?.assemblings.find(a => a.hash === jobOutput?.assembling) || null
  }

}
