import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Agency, AgencyStatistic, User } from 'projects/api/src/api';
import { Environment } from 'projects/web/src/environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { AgencyService } from '../../services/agency.service';
import { Upload, UploadService } from '../../services/upload.service';

@Component({
  selector: 'c-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit{

  authUser: Observable<User | null>
  userAgency: Observable<Agency | null>
  userAgencyStatistic: Observable<AgencyStatistic | null>
  rootPage: boolean = false
  items: MenuItem[] = [];
  uploads: Observable<Upload[]>;
  uploadOverallProgress: Observable<number | null>

  constructor(
    private authService: AuthService,
    private agencyService: AgencyService,
    private router: Router,
    private environment: Environment,
    private uploadService: UploadService,
  ) {
    this.authUser = this.authService.authUser
    this.userAgency = this.agencyService.userAgency
    this.userAgencyStatistic = this.agencyService.selectedAgencyStatistic

    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd && event.url) {
        this.rootPage = event.url === '/' || event.url.startsWith('/?')
      }
    });

    this.uploadOverallProgress = this.uploadService.overallProgress()
    this.uploads = this.uploadService.uploads
  }

  ngOnInit() {
    this.initMenuItems()
  }

  login() {
    this.authService.login('/dashboard')
  }

  register() {
    window.location.href = `${this.environment.authUrl}/registration`
  }

  initMenuItems() {
    this.items = this.agencyService.currentUserAgencies.map(userAgency => ({
      label: userAgency.name,
      icon: localStorage.getItem('activeAgencyId')! === userAgency._id ? 'pi pi-check' : 'pi',
      disabled: localStorage.getItem('activeAgencyId')! === userAgency._id,
      command: () => {
        this.chooseAgency(userAgency._id);
      }
    }))
    this.items.push({
      separator: true
    })
    this.items.push({
      label: 'Manage agencies',
      command: () => {
        this.router.navigateByUrl('/manage/agencies')
      }
    })
  }

  chooseAgency(agencyId: string) {
    this.agencyService.setActiveUserAgency(agencyId)
    this.initMenuItems()
    
    if (window.location.href === '/dashboard') {
      window.location.reload()
    } else {
      window.location.href = '/dashboard'
    }
  }

  async initNotificationOverlay() {
    // this.uploads = this.uploadService.uploads

  }

  openUpload(upload: Upload) {
    this.router.navigate(upload.information.routerLink)
  }
}
