import { Injectable } from '@angular/core';
import uniqolor from 'uniqolor';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  colorToString(r: number, g: number, b: number, a?: number): string;
  colorToString(color: {r: number, g: number, b: number, a?: number}): string;
  colorToString(arg1: {r: number, g: number, b: number, a?: number} | number, arg2?: number, arg3?: number, arg4?: number): string {
    if (typeof arg1 === 'object') {
      if (arg1.a) return `rgba(${arg1.r}, ${arg1.g}, ${arg1.b}, ${arg1.a})`
      return `rgb(${arg1.r}, ${arg1.g}, ${arg1.b})`
    }

    if (arg4) {
      return `rgba(${arg1}, ${arg2}, ${arg3}, ${arg4})`
    }

    return `rgb(${arg1}, ${arg2}, ${arg3})`
  }

  stringToColor(colorString: string): {r: number, g: number, b: number, a?: number} {
    if (colorString.startsWith('#')) {
      const hex = colorString.replace(/^#/, '').toUpperCase();
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
  
      return {r, g, b};
    }
    
    const match = colorString.match(/rgb\(\s*(\d+),\s*(\d+),\s*(\d+)\s*\)/);

    if (match) {
      const r = parseInt(match[1]);
      const g = parseInt(match[2]);
      const b = parseInt(match[3]);
      return {r, g, b}
    }

    const alphaMatch = colorString.match(/rgb\(\s*(\d+),\s*(\d+),\s*(\d+)\s*,\s*(\d+(\.\d+))\s*\)/);

    if (alphaMatch) {
      const r = parseInt(alphaMatch[1]);
      const g = parseInt(alphaMatch[2]);
      const b = parseInt(alphaMatch[3]);
      const a = parseFloat(alphaMatch[4]);
      return {r, g, b, a}
    }

    throw new Error(`Invalid color string: ${colorString}`)
  }

  pastelColor(inputString: string, output: 'string', seeds?: {r?: number, g?: number, b?: number}): string
  pastelColor(inputString: string, output: 'object', seeds?: {r?: number, g?: number, b?: number}): {red: number, green: number, blue: number}
  pastelColor(inputString: string, output: 'object' | 'string', seeds?: {r?: number, g?: number, b?: number}): {red: number, green: number, blue: number} | string {

    //TODO: adjust base colour values below based on theme
    const baseRed = 128;
    const baseGreen = 128;
    const baseBlue = 128;

    //lazy seeded random hack to get values from 0 - 256
    //for seed just take bitwise XOR of first two chars
    let seed = inputString.charCodeAt(0) ^ inputString.charCodeAt(1);
    const rand_1 = Math.abs((Math.sin(seed++) * 10000)) % (seeds?.r || 256);
    const rand_2 = Math.abs((Math.sin(seed++) * 10000)) % (seeds?.g || 256);
    const rand_3 = Math.abs((Math.sin(seed++) * 10000)) % (seeds?.b || 256);

    //build colour
    const red = Math.round((rand_1 + baseRed) / 2);
    const green = Math.round((rand_2 + baseGreen) / 2);
    const blue = Math.round((rand_3 + baseBlue) / 2);

    if (output === 'object') {
      return { red: red, green: green, blue: blue };
    } else {
      return `rgb(${red}, ${green}, ${blue})`
    }
  }

  neoColor(inputString: string) {
    return uniqolor(inputString, {
      lightness: 60
    })
  }

  darkenColor(rgbColor: string, percentage: number) {
    const {r, g, b, a} = this.stringToColor(rgbColor)
  
    // Calculate the new color values
    const darkenFactor = 1 - percentage;
    const newR = Math.max(0, Math.floor(r * darkenFactor));
    const newG = Math.max(0, Math.floor(g * darkenFactor));
    const newB = Math.max(0, Math.floor(b * darkenFactor));
  
    if (a) {
      return `rgb(${newR}, ${newG}, ${newB}, ${a})`;
    }

    return `rgb(${newR}, ${newG}, ${newB})`;
  }

  lightenColor(rgbColor: string, percentage: number) {
    const {r, g, b, a} = this.stringToColor(rgbColor)
  
    // Calculate the new color values
    const lightenFactor = 1 + percentage;
    const newR = Math.min(255, Math.floor(r * lightenFactor));
    const newG = Math.min(255, Math.floor(g * lightenFactor));
    const newB = Math.min(255, Math.floor(b * lightenFactor));
  
    if (a) {
      return `rgb(${newR}, ${newG}, ${newB}, ${a})`;
    }

    return `rgb(${newR}, ${newG}, ${newB})`;
  }

  alphaColor(rgbColor: string, alpha: number) {
    const {r, g, b} = this.stringToColor(rgbColor)
  
    // Return the new RGB color string
    return `rgb(${r}, ${g}, ${b}, ${alpha})`;
  }

  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  lowerFirstLetter(str: string) {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  wait(ms: number): Promise<void> {
    return new Promise<void>((resolve: () => void) => {
      setTimeout(resolve, ms)
    })
  }

  isMac(): boolean {
    return ((navigator as any).userAgentData?.platform || navigator.platform || '').toLowerCase().includes('mac')
  }
}
