import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Task } from 'projects/api/src/api';
import { BehaviorSubject, Observable, combineLatest, map, mergeAll } from 'rxjs';
import * as uuid from 'uuid';

export type UploadInformation = {
  type: string,
  fileName: string,
  size: number,
  description: string,
  routerLink: string[],
}

export interface TaskAttachmentUploadInformation extends UploadInformation {
  type: 'TaskAttachment',
  task: string,
  taskAttachment: string,
}

export interface GraphFileUploadInformation extends UploadInformation {
  type: 'GraphFile',
  graphFolder: string | null,
  graphFile: string,
}

export interface OrderUploadUploadInformation extends UploadInformation {
  type: 'OrderUpload',
  order: string,
  orderUpload: string,
}

export interface PreviewUploadInformation extends UploadInformation {
  type: 'Preview',
  asset: string,
  assetVersion: string,
  previewSet: string,
  preview: string,
}

export type Upload = {
  id: string,
  information: UploadInformation,
  progressObservable: Observable<number | 'Finished' | 'Error'>,
  progress: number | 'Finished' | 'Error',
  createdAt: Date,
}

@Injectable({
  providedIn: 'root'
})
export class UploadService  {

  private _uploadProgress: {[id: string]: Observable<number | 'Finished' | 'Error'>} = {}
  private _uploads: BehaviorSubject<Upload[]> = new BehaviorSubject<Upload[]>([])
  private _uploading = false

  public get uploads() {
    return this._uploads.asObservable()
  }

  public get currentUploads() {
    return this._uploads.getValue()
  }

  public get uploading() {
    return this._uploading
  }

  constructor(private http: HttpClient) {
    this.overallProgress().subscribe(p => {
      this._uploading = typeof p === 'number'
    })
    this.loadRecent()
  }

  public upload(signedURL: string, file: File, uploadInformation: TaskAttachmentUploadInformation | OrderUploadUploadInformation | PreviewUploadInformation | GraphFileUploadInformation): Observable<number | 'Finished' | 'Error'> {
    const id = uuid.v4()

    const progressSubject: BehaviorSubject<number | 'Finished' | 'Error'> = new BehaviorSubject<number | 'Finished' | 'Error'>(0)

    const progressObservable = this.http.put(signedURL, file, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((response) => {
      if (response.type === HttpEventType.Response) {
        return response.status >= 200 && response.status < 300 ? 'Finished' : 'Error'
      } else if (response.type === HttpEventType.UploadProgress) {
        return response.loaded / response.total!
      } else {
        return 0
      }
    }))

    this._uploadProgress[id] = progressSubject.asObservable()

    const upload: Upload = {
      id: id,
      information: uploadInformation,
      progressObservable: progressSubject.asObservable(),
      progress: 0,
      createdAt: new Date(),
    }

    const subscription = progressObservable.subscribe(p => {
      upload.progress = p
      progressSubject.next(p)
      if (p === 'Error' || p === 'Finished') {
        subscription.unsubscribe()
        this.saveRecent()
      }
    })

    // add first, so that it is on top
    const uploads = this._uploads.getValue()
    uploads.unshift(upload)
    this._uploads.next(uploads)

    this.saveRecent()

    return this._uploadProgress[id]
  }

  overallProgress(): Observable<number | null> {
    return this.uploads.pipe(
      map(p => p.map(a => a.progressObservable)),
      map(p => combineLatest(p)),
      mergeAll(),
      map(p => {
        const values = p.filter(v => typeof v === 'number') as number[]
        if (values.length === 0) return null
        return values.reduce((a, b) => a + b, 0) / values.length
      })
    )
  }

  isTaskAttachmentUploadInformation(uploadInformation: UploadInformation): uploadInformation is TaskAttachmentUploadInformation {
    return uploadInformation.type === 'TaskAttachment'
  }
  
  isOrderUploadUploadInformation(uploadInformation: UploadInformation): uploadInformation is OrderUploadUploadInformation {
    return uploadInformation.type === 'OrderUpload'
  }
  
  isPreviewUploadInformation(uploadInformation: UploadInformation): uploadInformation is PreviewUploadInformation {
    return uploadInformation.type === 'Preview'
  }

  loadRecent() {
    const uploadsString = sessionStorage.getItem("uploads") || "[]"
    const sessionUploads = JSON.parse(uploadsString) as Upload[]

    const uploads = this._uploads.getValue()

    for (const sessionUpload of sessionUploads) {
      const progress = typeof sessionUpload.progress === 'number' ? 'Error' : sessionUpload.progress
      uploads.push({
        ...sessionUpload,
        progress: progress,
        progressObservable: new BehaviorSubject(progress)
      })
    }

    this._uploads.next(uploads)
  }

  saveRecent() {
    const uploads = this._uploads.getValue().slice(0, 10)

    sessionStorage.setItem("uploads", JSON.stringify(uploads.map(u => ({
      ...u,
      progressObservable: undefined
    }))))
  }

}
