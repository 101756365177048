import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { AgencyService } from './services/agency.service';
import { UploadService } from './services/upload.service';
import mermaid from 'mermaid';
import { filter, map, switchMap } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  loading = true
  boxShadow = ''
  backgroundColor = ''
  rootPage = true
  noAgency = true
  fullScreen = false

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler() {
    return !this.uploadService.uploading
  }

  get contentClass(): string {
    if (this.fullScreen) return 'fullscreen'
    return this.rootPage || this.noAgency ? '' : 'padding'
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private agencyService: AgencyService,
    private uploadService: UploadService
  ) {
    this.calculateStyle()
    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd && event.url) {
        this.rootPage = event.url === '/' || event.url.startsWith('/?') || event.url.startsWith('/#')
      }
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => !!route),
      switchMap(route => route!.data),
    ).subscribe((data) => {
      const fullScreen = data['fullScreen'] || false

      if (this.fullScreen !== fullScreen) {
        this.fullScreen = fullScreen
        this.calculateStyle()
      }
    })
  }

  async ngOnInit() {
    mermaid.initialize({
      securityLevel: 'loose',
      theme: 'dark'
    });

    this.authService.authUser.subscribe(async (authUser) => {
      this.loading = true

      if (authUser) {
        await this.agencyService.initUserAgencies(authUser._id)

        this.noAgency = !this.agencyService.currentSelectedAgency

        if (this.noAgency) {
          this.router.navigate(['/manage/agencies'])
        } else {
          await this.authService.navigateByState()
        }
      } else {
        this.noAgency = false
        await this.agencyService.resetUserAgencies()
      }

      setTimeout(() => {
        this.loading = false
      }, 1000)
    })

  }

  @HostListener('window:scroll') // for window scroll events
  calculateStyle() {
    const val = this.fullScreen ? 1 : Math.min(1, window.pageYOffset / 75);

    this.boxShadow = `0px 0px ${val * 15}px 10px rgba(0,0,0,${0.75 * val})`
    this.backgroundColor = `rgba(26, 38, 54, ${val})`
  }
}
