<c-header header="Tasks" [breadcrumbs]="breadcrumbs">
  <div actions>
  </div>
</c-header>

<div class="subtasks-link">
  <a [routerLink]="['./subtasks']">Show all Subtasks</a>
</div>

<c-table header="Tasks" id="tasks" [options]="tableOptions" (query)="query($event)"></c-table>

<!-- <header>
  <c-topbar></c-topbar>
</header>

<div *ngFor="let task of tasks" class="content">
  <div class="shopping-item" (click)="navigateToUrl(task._id)">
    <img src="../../../../assets/images/exampleAssetPictures/2be176a9-b60b-402a-9126-749354df0bf5.jpeg" alt="Product" />
    <div class="metadata">
      <h3>{{task.state}}</h3>
      <p>{{task.state}}</p>
    </div>
  </div>
</div> -->