import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DownloadService  {

  constructor(
    private httpClient: HttpClient,
  ) { }

  public download(signedURL: string, name?: string) {
    const link = document.createElement('a');

    link.href = signedURL;
    if (name && name.length > 0) {
      link.download = name;
    }
    link.click();
    link.remove(); 
  }

  public async asString(signedURL: string) {
    const response = await lastValueFrom(this.httpClient.get(signedURL, {
      observe: 'response',
      responseType: 'text',
      headers: {
      }
    }))

    if (response.status >= 200 && response.status < 300 && typeof response.body === 'string') {
      return response.body
    }

    return null
  }

}
