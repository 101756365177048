import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onceTrue'
})
export class OnceTruePipe implements PipeTransform {

  onceTrue = false
  
  transform(condition: boolean): boolean {
    this.onceTrue = this.onceTrue || condition
    return this.onceTrue
  }

}
