import { TopbarComponent } from './topbar.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { UploadProgressModule } from '../upload-progress/upload-progress.module';
import { TabViewModule } from 'primeng/tabview';
import { ProgressBarModule } from 'primeng/progressbar';


@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    TopbarComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    MenuModule,
    OverlayPanelModule,
    UploadProgressModule,
    TabViewModule,
    ProgressBarModule,
  ],
  exports: [
    TopbarComponent,
  ],
  bootstrap: [TopbarComponent],
})
export class TopbarModule { }
