import { Inject, Injectable } from '@angular/core';
import { BASE_PATH, GetOrderOrderLineCommentsQuery, GetOrderOrderLineQuery, GetOrderOrderLinesAggregationQuery, GetOrderOrderLinesQuery, GetOrderOrderUploadOrderParsingQuery, GetOrderOrderUploadOrderParsingsQuery, GetOrderOrderUploadQuery, GetOrderOrderUploadsQuery, GetOrderParserQuery, GetOrderParsersQuery, GetOrderQuery, GetOrderSubtasksQuery, GetOrdersQuery, Order, OrderApiService, OrderUploadFormat, OrderUploadFormatCSV, PostOrderBody, PostOrderLocksDeleteBody, PostOrderOrderLineAcceptQuery, PostOrderOrderLineCommentsBody, PostOrderOrderLineProcessQuery, PostOrderOrderLineRejectQuery, PostOrderOrderLineReviewQuery, PostOrderOrderLinesLockBody, PostOrderOrderUploadParseBody, PostOrderOrderUploadValidateBody, PostOrderOrderUploadsBody, PostOrderParsersBody, PostOrderProcessBody, PostOrderReviewBody, PostOrdersBody } from 'projects/api/src/api';
import { Observable } from 'rxjs';
import { AgencyService } from './agency.service';
import { UploadService } from './upload.service';


@Injectable({
  providedIn: 'root'
})
export class OrderService {

  activeOrderUploads: {[id: string]: Observable<number | 'Finished' | 'Error'>} = {}

  constructor(
    @Inject(BASE_PATH) private basePath: string,
    private orderApiService: OrderApiService,
    private agencyService: AgencyService,
    private uploadService: UploadService,
  ) { }

  public getOrders(query?: Omit<GetOrdersQuery, 'agency'>) {
    return this.orderApiService.getOrders({
      agency: this.agencyService.currentSelectedAgencyId!,
      ...query,
    })
  }

  public getOrder(orderId: string, query?: GetOrderQuery) {
    return this.orderApiService.getOrder(orderId, query)
  }

  public getOrderLocks(orderId: string) {
    return this.orderApiService.getOrderOrderLinesLocks(orderId);
  }

  public getOrderLinesAggregation(orderId: string, query?: GetOrderOrderLinesAggregationQuery) {
    return this.orderApiService.getOrderOrderLinesAggregation(orderId, query);
  }

  public getOrderLines(orderId: string, query?: GetOrderOrderLinesQuery) {
    return this.orderApiService.getOrderOrderLines(orderId, query)
  }

  public getOrderLine(orderId: string, orderlineId: string, query?: GetOrderOrderLineQuery) {
    return this.orderApiService.getOrderOrderLine(orderId, orderlineId, query)
  }

  public getOrderLineJobs(orderId: string, orderlineId: string, query?: GetOrderOrderLineQuery) {
    return this.orderApiService.getOrderOrderLineJobs(orderId, orderlineId, query)
  }

  public getOrderOrderLineComments(orderId: string, orderLineId: string, query: GetOrderOrderLineCommentsQuery) {
    return this.orderApiService.getOrderOrderLineComments(orderId, orderLineId, query)
  }

  public getOrderOrderLineHistory(orderId: string, orderLineId: string) {
    return this.orderApiService.getOrderOrderLineHistory(orderId, orderLineId)
  }

  public getOrderSubtasks(orderId: string, query: GetOrderSubtasksQuery) {
    return this.orderApiService.getOrderSubtasks(orderId, query);
  }

  public getOrderParsers(query?: GetOrderParsersQuery) {
    return this.orderApiService.getOrderParsers({
      ...query,
      agency: this.agencyService.currentSelectedAgencyId!
    })
  }

  public getOrderParser(orderParserId: string, query?: GetOrderParserQuery) {
    return this.orderApiService.getOrderParser(orderParserId, query)
  }

  public createOrder(body: Omit<PostOrdersBody, 'agency'>) {
    return this.orderApiService.postOrders({
      agency: this.agencyService.currentSelectedAgencyId!,
      ...body
    })
  }

  public async createOrderUpload(order: Order, body: PostOrderOrderUploadsBody, file: File) {
    const [ orderUpload ] = await this.orderApiService.postOrderOrderUploads(order._id, body)

    if (orderUpload) {
      this.activeOrderUploads[orderUpload.orderUploadId] = this.uploadService.upload(orderUpload.signedURL, file, {
        type: 'OrderUpload',
        size: file.size,
        routerLink: ['/orders', order._id, 'orderuploads'],
        description: `For order ${order.name}`,
        order: order._id,
        orderUpload: orderUpload.orderUploadId,
        fileName: file.name,
      })

      const subscription = this.activeOrderUploads[orderUpload.orderUploadId].subscribe(async (status) => {
        if (status === 'Finished') {
          await this.emitOrderUploadFinished(order._id, orderUpload.orderUploadId)
          delete this.activeOrderUploads[orderUpload.orderUploadId]
          subscription.unsubscribe()
        }
        if (status === 'Error') {
          await this.emitOrderUploadError(order._id, orderUpload.orderUploadId)
          delete this.activeOrderUploads[orderUpload.orderUploadId]
          subscription.unsubscribe()
        }
      })
    }
  }

  public activeOrderUpload(orderUploadId: string): Observable<number | 'Finished' | 'Error'> | null {
    if (this.activeOrderUploads) {
      return this.activeOrderUploads[orderUploadId]
    }
    return null
  }

  public getOrderUploads(orderId: string, query?: GetOrderOrderUploadsQuery) {
    return this.orderApiService.getOrderOrderUploads(orderId, query)
  }

  public getOrderUpload(orderId: string, orderUploadId: string, query?: GetOrderOrderUploadQuery) {
    return this.orderApiService.getOrderOrderUpload(orderId, orderUploadId, query)
  }

  public createOrderParser(body: PostOrderParsersBody) {
    return this.orderApiService.postOrderParsers(body)
  }

  public generatePreview(orderId: string, orderUploadId: string, format: OrderUploadFormat) {
    return this.orderApiService.postOrderOrderUploadPreview(orderId, orderUploadId, {format})
  }

  private emitOrderUploadError(orderid: string, orderUploadId: string) {
    return this.orderApiService.postOrderOrderUploadError(orderid, orderUploadId)
  }

  private emitOrderUploadFinished(orderid: string, orderUploadId: string) {
    return this.orderApiService.postOrderOrderUploadFinished(orderid, orderUploadId)
  }

  public getOrderParsings(orderid: string, orderUploadId: string, query?: GetOrderOrderUploadOrderParsingsQuery) {
    return this.orderApiService.getOrderOrderUploadOrderParsings(orderid, orderUploadId, query)
  }

  public getOrderParsing(orderid: string, orderUploadId: string, orderParsingId: string, query?: GetOrderOrderUploadOrderParsingQuery) {
    return this.orderApiService.getOrderOrderUploadOrderParsing(orderid, orderUploadId, orderParsingId, query)
  }

  public parseOrderUpload(orderId: string, orderUploadId: string, body: PostOrderOrderUploadParseBody) {
    return this.orderApiService.postOrderOrderUploadParse(orderId, orderUploadId, body)
  }

  public deleteOrder(orderId: string) {
    return this.orderApiService.postOrderDelete(orderId)
  }

  public createOrderLineComment(orderId: string, orderLineId: string, body: PostOrderOrderLineCommentsBody) {
    return this.orderApiService.postOrderOrderLineComments(orderId, orderLineId, body)
  }

  public createOrderUploadFormatCSV(): OrderUploadFormatCSV {
    return {
      type: 'CSV',
      delimiter: '',
      skipRows: 0,
      textQualifier: '',
    }
  }

  public processOrder(orderId: string, body: PostOrderProcessBody) {
    return this.orderApiService.postOrderProcess(orderId, body)
  }

  public lockOrderLines(orderId: string, body: PostOrderOrderLinesLockBody) {
    return this.orderApiService.postOrderOrderLinesLock(orderId, body)
  }

  public deleteLocks(orderId: string, body: PostOrderLocksDeleteBody) {
    return this.orderApiService.postOrderLocksDelete(orderId, body);
  }

  /**
   *
   * @param orderId
   * @param orderLineId
   * @param next Return next orderLine to accept/reject instead of the accepted orderLine
   * @returns
   */
  public acceptOrderLine(orderId: string, orderLineId: string, next?: boolean, query?: PostOrderOrderLineAcceptQuery) {
    return this.orderApiService.postOrderOrderLineAccept(orderId, orderLineId, {
      next: !!next
    }, query)
  }

  /**
   *
   * @param orderId
   * @param orderLineId
   * @param next Return next orderLine to accept/reject instead of the rejected orderLine
   * @returns
   */
  public rejectOrderLine(orderId: string, orderLineId: string, next?: boolean, query?: PostOrderOrderLineRejectQuery) {
    return this.orderApiService.postOrderOrderLineReject(orderId, orderLineId, {
      next: !!next
    }, query)
  }

  public reviewOrderLine(orderId: string, orderLineId: string, query?: PostOrderOrderLineReviewQuery) {
    return this.orderApiService.postOrderOrderLineReview(orderId, orderLineId, query)
  }

  public processOrderLine(orderId: string, orderLineId: string, query?: PostOrderOrderLineProcessQuery) {
    return this.orderApiService.postOrderOrderLineProcess(orderId, orderLineId, query)
  }

  public updateOrder(order: string, body: PostOrderBody) {
    return this.orderApiService.postOrder(order, body)
  }

  public reviewOrder(orderId: string, body: PostOrderReviewBody) {
    return this.orderApiService.postOrderReview(orderId, body);
  }

  public acceptOrder(orderId: string, body: PostOrderReviewBody) {
    return this.orderApiService.postOrderAccept(orderId, body);
  }

  public rejectOrder(orderId: string, body: PostOrderReviewBody) {
    return this.orderApiService.postOrderReject(orderId, body);
  }
  
  public async downloadOrderLines(orderId: string, orderLines: string[]): Promise<string> {
    const [result] = await this.orderApiService.postOrderOrderLinesDownload(orderId, {
      orderLines: orderLines
    })

    if (result) {
      return `${this.basePath}/${orderId}/orderlines/download/${result.downloadKey}`
    }

    throw new Error("Could not create download")
  }

  public compareOrderLines(orderId: string, orderLines: string[]) {
    return this.orderApiService.postOrderOrderLinesCompare(orderId, {
      orderLines: orderLines
    })
  }

  public validate(orderId: string, orderUploadId: string, body: PostOrderOrderUploadValidateBody) {
    return this.orderApiService.postOrderOrderUploadValidate(orderId, orderUploadId, body)
  }
}
